import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg, useIntl } from 'react-intl'

import check from '../../../../assets/images/check.svg'
import blueArrow from '../../../../assets/images/blueArrow.svg'
import { trackEvent } from '../../../../matomo'

const OurServicesCard = ({ card, image, href, extraClass2 = '' }) => {
  const intl = useIntl()
  return (
    <div className='bg-secondary rounded-[23px] howwework__card w-[291px] h-auto  sm:h-[504px] sm:w-[542px] relative mx-auto mt-[40px] xl:mt-[0px]'>
      <div className='bg-blue rounded-full w-[80px] sm:w-[109px] h-[80px] sm:h-[109px] absolute left-[105px] -top-10 sm:-left-14 sm:-top-14 border-[6px] sm:border-[10px] border-white text-white flex justify-center items-center'>
        <div className={`${extraClass2}`}>
          <img
            src={image}
            alt='Our Services card icons'
            title='Our Services card icons'
            loading='lazy'
            className='w-full h-full'
          />
        </div>
      </div>
      <h3 className='max-w-full pl-[21px] sm:pl-[63px] pt-[63px] sm:pt-[50px] pb-[10px] text-[20px] sm:text-[36px] leading-[27px] sm:leading-[54px] text-blue  w-full  sm:block'>
        <Msg id={`socialMediaRecruiting.ourServices.${card}.title`} />
      </h3>
      <div className='w-full'>
        <div className='pl-[21px] sm:pl-[63px] pt-[0px]'>
          <p className={'font-normal leading-[25.2px] text-[16px] lg:w-[479px] pr-5'}>
            <Msg id={`socialMediaRecruiting.ourServices.${card}.description`} />
          </p>
          <div className='flex items-center h-[38px] mt-[28px]'>
            <div className='h-[32px] sm:h-[48px] w-[32px]  sm:w-[55px]'>
              <img
                src={check}
                alt='Our Services check'
                title='Our Services check'
                loading='lazy'
                className='w-[30px] h-[30px]'
              />
            </div>
            <p className='text-[15px] sm:text-[16px] place-self-center sm:place-self-start font-semibold pl-[25px] sm:pl-[0px] leading-[21px] sm:leading[27px] w-full'>
              <Msg id={`socialMediaRecruiting.ourServices.${card}.check1`} />
            </p>
          </div>
          <div className='flex flex-row items-center h-[42px] sm:h-[38px] mt-[14px] sm:mt-[2px]'>
            <div className='h-[32px] sm:h-[48px] sm:w-[55px]'>
              <img
                src={check}
                alt='Our Services check'
                title='Our Services check'
                loading='lazy'
                className='w-[30px] h-[30px]'
              />
            </div>
            <p className='text-[15px] sm:text-[16px] place-self-center sm:place-self-start font-semibold pl-[25px] sm:pl-[0px] leading-[21px] sm:leading[27px] w-full'>
              <Msg id={`socialMediaRecruiting.ourServices.${card}.check2`} />
            </p>
          </div>
          <div className='flex items-center h-[42px] sm:h-[38px] mt-[14px] sm:mt-[2px]'>
            <div className='h-[32px] sm:h-[48px] w-[32px] sm:w-[55px]'>
              <img
                src={check}
                alt='Our Services check'
                title='Our Services check'
                loading='lazy'
                className='w-[30px] h-[30px]'
              />
            </div>
            <p className='text-[15px] sm:text-[16px] place-self-center sm:place-self-start font-semibold pl-[25px] sm:pl-[0px]  leading-[21px] sm:leading[27px] w-full'>
              <Msg id={`socialMediaRecruiting.ourServices.${card}.check3`} />
            </p>
          </div>
          <div className='flex items-center h-[42px] sm:h-[38px] mt-[14px] sm:mt-[2px] mb-[33px] sm:mb-[26px]'>
            <div className='h-[32px] sm:h-[48px] w-[32px]  sm:w-[55px]'>
              <img
                src={check}
                alt='Our Services check'
                title='Our Services check'
                loading='lazy'
                className='w-[30px] h-[30px]'
              />
            </div>
            <p className='text-[15px] sm:text-[16px] place-self-center sm:place-self-start font-semibold pl-[25px] sm:pl-[0px] leading-[21px] sm:leading[27px] w-full'>
              <Msg id={`socialMediaRecruiting.ourServices.${card}.check4`} />
            </p>
          </div>
          {href && (
            <Link
              to={href}
              onClick={() => {
                trackEvent('Home Page', 'Navigate', `home_services_btn_click_${href}_card`)
              }}
              className='pb-12 sm:pb-0 flex flex-row text-[18px] leading-[24px] si:text-[18px] font-semibold text-blueLight border-none pl-[5px] sm:pl-[0px] '
              title={intl.formatMessage({
                id: 'common.button.ourServices',
                defaultMessage: 'Our services ',
              })}
            >
              <Msg id='common.button.ourServices' />
              <img
                src={blueArrow}
                alt='Our Services'
                title='Our Services'
                loading='lazy'
                className='h-[24px] w-[24px]'
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

OurServicesCard.propTypes = {
  card: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  extraClass2: PropTypes.string,
  href: PropTypes.string,
}
export default OurServicesCard
