import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'

const Quote = () => {
  return (
    <div className='bg-blue h-[161px] sm:h-[167px] py-5 px-14 sm:py-7 text-white text-center italic font-medium text-xl grid content-center sm:text-4xl'>
      <div className='mb-4'>
        <Msg
          id='socialMediaRecruiting.aboutUs.quote'
          defaultMessage='„Nothing is as constant as change.“'
        />
      </div>
      <span className='text-lighterWhite text-base sm:text-[26px]'>
        (
        <Msg id='socialMediaRecruiting.aboutUs.author' defaultMessage='Heraklit' />)
      </span>
    </div>
  )
}

export default Quote
