import { useEffect } from 'react'

const useMatomo = () => {
  useEffect(() => {
    const initMatomo = () => {
      var _paq = (window._paq = window._paq || [])
      _paq.push(['setExcludedReferrers', ['.netlify.app', 'localhost']])
      _paq.push(['trackPageView'])
      _paq.push(['enableLinkTracking'])

      var u = process.env.REACT_APP_TRACKING_URL
      _paq.push(['setTrackerUrl', u + 'matomo.php'])
      _paq.push(['setSiteId', '1'])

      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0]
      g.async = true
      g.src = u + 'matomo.js'
      s.parentNode.insertBefore(g, s)
    }

    if (!window._paq && process.env.REACT_APP_TRACKING_DISABLED === 'false') {
      initMatomo()
    }
  }, [])
}

export default useMatomo
