import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Navbar from '../../components/common/sections/Navbar/Navbar'
import MetaData from '../MetaData/MetaData'
import { navItemsMain } from '../navItems'
import rootLogo from '../../assets/images/welitzki-it-logo-long.svg'
import Hero from '../../components/BlogPost/Hero/Hero'
import AllPosts from '../../components/BlogPost/AllPosts/AllPosts'
import NewsletterCTA from '../../components/common/sections/NewsletterCTA/NewsletterCTA'
import Footer from '../../components/common/sections/Footer/Footer'
import ModalContactus from '../../components/common/display/ModalContactUs/ModalContactUs'

const Blog = ({ setShowCookieConsent, bannerAccepted }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <div className='page-wrapper'>
      <MetaData
        title='Alle Blogeinträge von Nicolas WelitzkiIT-Consulting'
        description='Finden Sie bei uns spannende und interessante Blogeinträge unseres Teams. Wir kreieren Blogs zu Allgemeinen Themen aus der IT, zudem Webdesign, Web-Applikationen und SEO. Regelmäßig posten wir neue Blogeinträge, verpassen Sie nichts!'
        keywords='Webdesign, Web Development, Web Agency, Webdesign, Webentwicklung, Werbeagentur, Webdesign Berlin, Webentwicklung Berlin, Webagentur Berlin'
      />
      <Navbar navItems={navItemsMain} secondVarient={false} logo={rootLogo} />
      <Hero />
      <AllPosts />
      <NewsletterCTA blog={true} />
      <Footer
        theme={'light'}
        setShowModal={setShowModal}
        setShowCookieConsent={setShowCookieConsent}
        bannerAccepted={bannerAccepted}
      />
      <ModalContactus showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

Blog.propTypes = {
  bannerAccepted: PropTypes.bool,
  setShowCookieConsent: PropTypes.func,
}

export default Blog
