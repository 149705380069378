export function trackPageView() {
  if (typeof window._paq !== 'undefined') {
    window._paq.push(['setCustomUrl', window.location.href])
    window._paq.push(['setDocumentTitle', document.title])
    window._paq.push(['trackPageView'])
  }
}

export function trackEvent(category, action, name, value) {
  if (typeof window._paq !== 'undefined') {
    window._paq.push(['trackEvent', category, action, name, value])
  }
}
