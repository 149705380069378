import React from 'react'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'

import AppRouter from './AppRouter'
import { getLocale, getMessages } from './domain/intl'
import { enUS } from './domain/intl/locales'
import { ScrollToView } from './scrollToView'
import './styles/globals.css'

const App = () => (
  <IntlProvider messages={getMessages(getLocale())} locale={getLocale()} defaultLocate={enUS}>
    <BrowserRouter>
      <ScrollToView />
      <AppRouter />
    </BrowserRouter>
  </IntlProvider>
)

export default App
