export const messages = {
  message: {
    Error: 'Error',
    routeError: {
      messageOne: 'The page you are looking for does not exist.',
      messageTwo: 'But dont worry, you can find plenty of other things on our homepage.',
    },
  },
  common: {
    button: {
      takeToHome: 'Take me to the home page',
      add: 'Add',
      cancel: 'Cancel',
      new: 'New',
      delete: 'Delete',
      edit: 'Edit',
      goBack: 'go back',
      toMain: 'to Start',
      consultation: 'Work with us',
      imprint: 'Imprint',
      privacy: 'Privacy',
      howwework: 'Work with us',
      send: 'Submit',
      tryAgain: 'Try again',
      newResponse: 'Submit again',
      loading: 'Loading ...',
      ourServices: 'Checkout & Learn more',
      product1: 'Start now',
      product2: 'Check now',
      product3: 'Secure now',
      signUp: 'get updates',
      inquiry: 'Inquire now',
      viewAllBlog: 'More blog articles',
      webBoost: {
        card1: 'Start now',
        card2: 'Start now',
        card3: 'Start now',
        card4: 'Start now',
      },
    },
    slogan: {
      text: 'Loaded faster - Found better - \n Simply more customers',
    },
  },
  socialMediaRecruiting: {
    navbar: {
      start: 'Start',
      strategy: 'Strategy',
      procedure: 'Procedure',
      references: 'References',
      aboutUs: 'About Us',
    },
    hero: {
      firstText: 'Revolutionize your personnel recruiting and gain ',
      secondText: 'qualified professionals',
      lastText: ' for yourself.',
    },
    callToAction: {
      heading1: 'Ready to find new professionals?',
      heading2: 'Make an appointment now',
    },
    aboutUs: {
      title: 'About Us',
      position: '(Managing Director)',
      test1:
        'has worked as a business intelligence specialist on various projects in the e-commerce, finance and automotive sectors since 2011.',
      test2:
        'In the process, he was able to acquire a wealth of experience with regard to the creation of specialist concepts, which he now uses to fundamentally optimize the personnel recruiting of craft and technology companies.',
      test3:
        // eslint-disable-next-line prettier/prettier, quotes
        "He will work with you to identify your company's unique selling points and use them in social media ads to target your potential employees.",
      quote: '„Nothing is as constant as change.“',
      author: 'Heraclitus of Ephesus, 535-475 B.C.',
    },
    contactUs: {
      title: 'Contact Us',
      details: 'Learn more about social recruiting in a short conversation with me',
      acceptMessage:
        'We use cookies to analyze the use of our websites, including search and browsing history, search terms and to offer you information tailored to your usage behavior.',
      essentialCookie: 'Essential',
      marketingCookie: 'Marketing',
      statisticsCookie: 'Statistics',
      functionalCookie: 'Functional',
      acceptButton: 'Accept all',
      openPreferencesButton: 'Cookies Preferences',
      saveCookieSettingsButton: 'Save on Computer',
    },
    imprint: {
      title: 'Imprint',
      heading1: 'According to § 5 TMG:',
      heading2: 'Contact',
      heading3: 'Tax ID',
      heading4: 'Editorial responsibility',
      heading5: 'Consumer dispute resolution/universal arbitration board',
      content1: 'Phone: +49 151 23246715',
      content2: 'Sales tax identification number according to § 27 a sales tax law:',
      content3:
        'We are not willing or obliged to participate in dispute settlement procedures before a consumer arbitration board.',
      seo: {
        title: 'Imprint -Legal information & contact details',
        description:
          'Discover the essential legal information, contact details, and responsible parties for Nicolas Welitzki IT Consulting. Located in Germany, we are committed to transparency and compliance with German regulations.',
        keywords:
          'imprint, legal information, contact details, German regulations, responsible parties, Impressum, liability, website owner, disclosure',
      },
    },
    privacy: {
      title: 'Privacy',
      one: {
        mainHeading: '1. An overview of data protection',
        heading1: 'General information',
        para1:
          'The following information will provide you with an easy to navigate overview of what will happen with your personal data when you visit this website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.',
        heading2: 'Data recording on this website',
        subHeading1:
          'Who is the responsible party for the recording of data on this website (i.e., the “controller”)?',
        para2:
          'The data on this website is processed by the operator of the website, whose contact information is available\n' +
          'under section “Information about the responsible party (referred to as the “controller” in the GDPR)” in this\n' +
          'Privacy Policy.',
        subHeading2: 'How do we record your data?',
        para3:
          'We collect your data as a result of your sharing of your data with us. This may, for instance be information\n' +
          'you enter into our contact form.',
        para4:
          'Other data shall be recorded by our IT systems automatically or after you consent to its recording during\n' +
          'your website visit. This data comprises primarily technical information (e.g., web browser, operating system,\n' +
          'or time the site was accessed). This information is recorded automatically when you access this website.',
        subHeading3: 'What are the purposes we use your data for?',
        para5:
          'A portion of the information is generated to guarantee the error free provision of the website. Other data\n' +
          'may be used to analyze your user patterns.',
        subHeading4: 'What rights do you have as far as your information is concerned?',
        para6:
          'You have the right to receive information about the source, recipients, and purposes of your archived\n' +
          'personal data at any time without having to pay a fee for such disclosures. You also have the right to demand\n' +
          'that your data are rectified or eradicated. If you have consented to data processing, you have the option to\n' +
          'revoke this consent at any time, which shall affect all future data processing. Moreover, you have the right to\n' +
          'demand that the processing of your data be restricted under certain circumstances. Furthermore, you have\n' +
          'the right to log a complaint with the competent supervising agency.',
        para7:
          'Please do not hesitate to contact us at any time if you have questions about this or any other data protection\n' +
          'related issues.',
        heading3: 'Analysis tools and tools provided by third parties',
        para8:
          'There is a possibility that your browsing patterns will be statistically analyzed when your visit this website Such analyses are performed primarily with what we refer to as analysis programs.',
        para9:
          'For detailed information about these analysis programs please consult our Data Protection Declaration below.',
      },
      two: {
        mainHeading: '2. Hosting',
        para1: 'We are hosting the content of our website at the following provider:',
        heading1: 'External Hosting',
        para2:
          'This website is hosted externally. Personal data collected on this website are stored on the servers of the\n' +
          'host. These may include, but are not limited to, IP addresses, contact requests, metadata and\n' +
          'communications, contract information, contact information, names, web page access, and other data\n' +
          'generated through a web site.',
        para3:
          'The external hosting serves the purpose of fulfilling the contract with our potential and existing customers\n' +
          '(Art. 6(1)(b) GDPR) and in the interest of secure, fast, and efficient provision of our online services by a\n' +
          'professional provider (Art. 6(1)(f) GDPR). If appropriate consent has been obtained, the processing is carried\n' +
          'out exclusively on the basis of Art. 6 (1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the\n' +
          'storage of cookies or the access to information in the users end device (e.g., device fingerprinting) within the\n' +
          'meaning of the TTDSG. This consent can be revoked at any time.',
        para4:
          'Our host(s) will only process your data to the extent necessary to fulfil its performance obligations and to\n' +
          'follow our instructions with respect to such data.',
        para5: 'We are using the following host(s):',
        line1: 'united-domains AG',
        line2: 'Gautinger Straße 10',
        line3: '82319 Starnberg',
        line4: 'Deutschland',
      },
      three: {
        mainHeading: '3. General information and mandatory information',
        heading1: 'Data protection',
        para1:
          'The operators of this website and its pages take the protection of your personal data very seriously. Hence,\n' +
          'we handle your personal data as confidential information and in compliance with the statutory data\n' +
          'protection regulations and this Data Protection Declaration.',
        para2:
          'The operators of this website and its pages take the protection of your personal data very seriously. Hence,\n' +
          'we handle your personal data as confidential information and in compliance with the statutory data\n' +
          'protection regulations and this Data Protection Declaration.',
        para3:
          'We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications)\n' +
          'may be prone to security gaps. It is not possible to completely protect data against third-party access.',
        heading2:
          'Information about the responsible party (referred to as the “controller” in the GDPR)',
        para4: 'The data processing controller on this website is:  ',
        para5:
          'The controller is the natural person or legal entity that single-handedly or jointly with others makes\n' +
          'decisions as to the purposes of and resources for the processing of personal data (e.g., names, e-mail\n' +
          'addresses, etc.).',
        heading3: 'Storage duration',
        para6:
          'Unless a more specific storage period has been specified in this privacy policy, your personal data will remain\n' +
          'with us until the purpose for which it was collected no longer applies. If you assert a justified request for\n' +
          'deletion or revoke your consent to data processing, your data will be deleted, unless we have other legally\n' +
          'permissible reasons for storing your personal data (e.g., tax or commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply.',
        heading4: 'General information on the legal basis for the data processing on this website',
        para7:
          'If you have consented to data processing, we process your personal data on the basis of Art. 6(1)(a) GDPR or\n' +
          'Art. 9 (2)(a) GDPR, if special categories of data are processed according to Art. 9 (1) DSGVO. In the case of\n' +
          'explicit consent to the transfer of personal data to third countries, the data processing is also based on Art.\n' +
          '49 (1)(a) GDPR. If you have consented to the storage of cookies or to the access to information in your end\n' +
          'device (e.g., via device fingerprinting), the data processing is additionally based on § 25 (1) TTDSG. The\n' +
          'consent can be revoked at any time. If your data is required for the fulfillment of a contract or for the\n' +
          'implementation of pre-contractual measures, we process your data on the basis of Art. 6(1)(b) GDPR.\n' +
          'Furthermore, if your data is required for the fulfillment of a legal obligation, we process it on the basis of Art.\n' +
          '6(1)(c) GDPR. Furthermore, the data processing may be carried out on the basis of our legitimate interest\n' +
          'according to Art. 6(1)(f) GDPR. Information on the relevant legal basis in each individual case is provided in\n' +
          'the following paragraphs of this privacy policy.',
        heading5: 'Information on data transfer to the USA and other non-EU countries',
        para8:
          'Among other things, we use tools of companies domiciled in the United States or other from a data\n' +
          'protection perspective non-secure non-EU countries. If these tools are active, your personal data may\n' +
          'potentially be transferred to these non-EU countries and may be processed there. We must point out that in\n' +
          'these countries, a data protection level that is comparable to that in the EU cannot be guaranteed. For\n' +
          'instance, U.S. enterprises are under a mandate to release personal data to the security agencies and you as\n' +
          'the data subject do not have any litigation options to defend yourself in court. Hence, it cannot be ruled out\n' +
          'that U.S. agencies (e.g., the Secret Service) may process, analyze, and permanently archive your personal\n' +
          'data for surveillance purposes. We have no control over these processing activities.',
        heading6: 'Revocation of your consent to the processing of data',
        para9:
          'A wide range of data processing transactions are possible only subject to your express consent. You can also\n' +
          'revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness\n' +
          'of any data collection that occurred prior to your revocation.',
        heading7:
          'Right to object to the collection of data in special cases; right to object to direct\n' +
          'advertising (Art. 21 GDPR)',
        para10:
          'IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE\n' +
          'THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON\n' +
          'GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED\n' +
          'ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS\n' +
          'BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE\n' +
          'WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO\n' +
          'PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA,\n' +
          'THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE\n' +
          'PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION\n' +
          'PURSUANT TO ART. 21(1) GDPR).',
        para11:
          'IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING,\n' +
          'YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR\n' +
          'THE PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE\n' +
          'EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL\n' +
          'DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES\n' +
          '(OBJECTION PURSUANT TO ART. 21(2) GDPR).',
        heading8: 'Right to log a complaint with the competent supervisory agency',
        para12:
          'In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory\n' +
          'agency, in particular in the member state where they usually maintain their domicile, place of work or at the\n' +
          'place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other\n' +
          'administrative or court proceedings available as legal recourses.',
        heading9: 'Right to data portability',
        para13:
          'You have the right to demand that we hand over any data we automatically process on the basis of your\n' +
          'consent or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine\n' +
          'readable format. If you should demand the direct transfer of the data to another controller, this will be done\n' +
          'only if it is technically feasible.',
        heading10: 'Information about, rectification and eradication of data',
        para14:
          'Within the scope of the applicable statutory provisions, you have the right to at any time demand\n' +
          'information about your archived personal data, their source and recipients as well as the purpose of the\n' +
          'processing of your data. You may also have a right to have your data rectified or eradicated. If you have\n' +
          'questions about this subject matter or any other questions about personal data, please do not hesitate to\n' +
          'contact us at any time.',
        heading11: 'Right to demand processing restrictions',
        para15:
          'You have the right to demand the imposition of restrictions as far as the processing of your personal data is\n' +
          'concerned. To do so, you may contact us at any time. The right to demand restriction of processing applies in\n' +
          'the following cases:',
        line1:
          'In the event that you should dispute the correctness of your data archived by us, we will usually need\n' +
          'some time to verify this claim. During the time that this investigation is ongoing, you have the right to\n' +
          'demand that we restrict the processing of your personal data.',
        line2:
          'If the processing of your personal data was/is conducted in an unlawful manner, you have the option to\n' +
          'demand the restriction of the processing of your data in lieu of demanding the eradication of this data.',
        line3:
          'If we do not need your personal data any longer and you need it to exercise, defend or claim legal\n' +
          'entitlements, you have the right to demand the restriction of the processing of your personal data instead\n' +
          'of its eradication',
        line4:
          'If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be\n' +
          'weighed against each other. As long as it has not been determined whose interests prevail, you have the\n' +
          'right to demand a restriction of the processing of your personal data.',
        para16:
          'If you have restricted the processing of your personal data, these data – with the exception of their archiving –\n' +
          'may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to\n' +
          'protect the rights of other natural persons or legal entities or for important public interest reasons cited by\n' +
          'the European Union or a member state of the EU.',
        heading12: 'SSL and/or TLS encryption',
        para17:
          'For security reasons and to protect the transmission of confidential content, such as purchase orders or\n' +
          'inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption\n' +
          'program. You can recognize an encrypted connection by checking whether the address line of the browser\n' +
          'switches from “http://” to “https://” and also by the appearance of the lock icon in the browser line.',
        para18:
          'If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.',
      },
      four: {
        mainHeading: '4. Recording of data on this website',
        heading1: 'Cookies',
        para1:
          'Our websites and pages use what the industry refers to as “cookies.” Cookies are small data packages that do\n' +
          'not cause any damage to your device. They are either stored temporarily for the duration of a session\n' +
          '(session cookies) or they are permanently archived on your device (permanent cookies). Session cookies are\n' +
          'automatically deleted once you terminate your visit. Permanent cookies remain archived on your device\n' +
          'until you actively delete them, or they are automatically eradicated by your web browser.',
        para2:
          'In some cases, it is possible that third-party cookies are stored on your device once you enter our site (thirdparty cookies). These cookies enable you or us to take advantage of certain services offered by the third\n' +
          'party (e.g., cookies for the processing of payment services).',
        para3:
          'Cookies have a variety of functions. Many cookies are technically essential since certain website functions\n' +
          'would not work in the absence of the cookies (e.g., the shopping cart function or the display of videos). The\n' +
          'purpose of other cookies may be the analysis of user patterns or the display of promotional messages.',
        para4:
          'Cookies, which are required for the performance of electronic communication transactions, or for the\n' +
          'provision of certain functions you want to use (e.g., for the shopping cart function) or those that are\n' +
          'necessary for the optimization (required cookies) of the website (e.g., cookies that provide measurable\n' +
          'insights into the web audience), shall be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal basis\n' +
          'is cited. The operator of the website has a legitimate interest in the storage of required cookies to ensure the\n' +
          'technically error free and optimized provision of the operator’s services. If your consent to the storage of the\n' +
          'cookies and similar recognition technologies has been requested, processing occurs exclusively on the basis\n' +
          'of the consent obtained (Art. 6(1)(a) GDPR and § 25 (1) TTDSG); this consent may be revoked at any time.',
        para5:
          'You have the option to set up your browser in such a manner that you will be notified any time cookies are\n' +
          'placed and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of\n' +
          'cookies in certain cases or in general or activate the delete function for the automatic eradication of cookies\n' +
          'when the browser closes. If cookies are deactivated, the functions of this website may be limited.',
        para6:
          'In the event that third-party cookies are used or if cookies are used for analytical purposes, we will\n' +
          'separately notify you in conjunction with this Data Protection Policy and, if applicable, ask for your consent.',
        heading2: 'Contact form',
        para7:
          'If you submit inquiries to us via our contact form, the information provided in the contact form as well as any\n' +
          'contact information provided therein will be stored by us in order to handle your inquiry and in the event\n' +
          'that we have further questions. We will not share this information without your consent.',
        para8:
          'The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a\n' +
          'contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based\n' +
          'on our legitimate interest in the effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or\n' +
          'on your agreement (Art. 6(1)(a) GDPR) if this has been requested; the consent can be revoked at any time.',
        para9:
          'The information you have entered into the contact form shall remain with us until you ask us to eradicate the\n' +
          'data, revoke your consent to the archiving of data or if the purpose for which the information is being\n' +
          'archived no longer exists (e.g., after we have concluded our response to your inquiry). This shall be without\n' +
          'prejudice to any mandatory legal provisions, in particular retention periods.',
        heading3: 'Request by e-mail, telephone, or fax',
        para10:
          'If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name,request) will be stored and processed by us for the purpose of processing your request. We do not pass these\n' +
          'data on without your consent.',
        para11:
          'These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a\n' +
          'contract or is required for the performance of pre-contractual measures. In all other cases, the data are\n' +
          'processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us (Art.\n' +
          '6(1)(f) GDPR) or on the basis of your consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent can be\n' +
          'revoked at any time.',
        para12:
          'The data sent by you to us via contact requests remain with us until you request us to delete, revoke your\n' +
          'consent to the storage or the purpose for the data storage lapses (e.g. after completion of your request).\n' +
          'Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.',
        heading4: 'Calendly',
        para13:
          'You can make appointments with us on our website. We use the “Calendly” tool for booking appointments.\n' +
          'The provider is Calendly LLC, 271 17th St NW, 10th Floor, Atlanta, Georgia 30363, USA (hereinafter\n' +
          '“Calendly”).',
        para14:
          'To work with us, enter the requested data and the desired date in the screen provided. The data\n' +
          'entered will be used for planning, executing and, if necessary, for the follow-up of the appointment. The\n' +
          'appointment data is stored for us on the servers of Calendly, whose privacy policy can be viewed here:',
        para15:
          'The data you have entered will remain with us until you ask us to delete it, revoke your consent for storage\n' +
          'or the purpose for which the data was stored ceases to apply. Mandatory legal provisions, in particular\n' +
          'retention periods, remain unaffected.',
        para16:
          'The legal basis for data processing is Art. 6(1)(f) GDPR. The website operator has a justified interest in\n' +
          'making appointments with interested parties and customers in as uncomplicated a manner as possible. If\n' +
          'appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a)\n' +
          'GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information in\n' +
          'the user’s end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can be\n' +
          'revoked at any time.',
        para17:
          'The data transfer to the USA is based on the standard contractual clauses of the European Commission.\n' +
          'Details can be found here:',
        heading5: 'ProvenExpert',
        para18:
          'We have included evaluation seals from ProvenExpert on this website. The provider is Expert Systems AG,\n' +
          'Quedlinburger Str. 1, 10589 Berlin,',
        para19:
          'The ProvenExpert seal enables us to display on our website customer reviews that have been submitted to\n' +
          'ProvenExpert about our company. When you visit our website, a connection to ProvenExpert is established\n' +
          'so that ProvenExpert can determine that you have visited our website. Furthermore, ProvenExpert records\n' +
          'your language settings to display the seal in the chosen language.',
        para20:
          'The use of ProvenExpert is based on Art. 6(1)(f) GDPR. The website operator has a legitimate interest in the\n' +
          'most comprehensible presentation of customer reviews. If appropriate consent has been obtained, the\n' +
          'processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the\n' +
          'consent includes the storage of cookies or the access to information in the user’s end device (e.g., device\n' +
          'fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any time.',
        heading6: 'Visable GmbH',
        para21:
          'We products and services for analysis and marketing purposes, which are provided by Visable GmbH (www.visable.com) in cooperation with them. To that end, pixel-code technology is used to collect, process and store data in order to create at least pseudonymised, but where possible and sensible, completely anonymous user profiles. Data collected, which may initially still include personal data, is transmitted to Visable or is collected directly by Visable and is used to create the aforementioned user profiles there. Visitors to this website are not personally identified and no other personal data is merged with the user profiles. If IP addresses are identified as personal, they are immediately deleted. You can object to the processing operations described with future effect at any time:',
        linkText1: 'Your visits are no longer recorded.',
        linkText2: 'Exclude visitor recording (Note: Link sets a 1st-party cookie for an opt-out)',
      },
      five: {
        mainHeading: '5. Analysis tools and advertising',
        heading1: 'Matomo',
        para1: 'This website uses the open-source web analysis service Matomo.',
        para2:
          'Through Matomo, we are able to collect and analyze data on the use of our website-by-website visitors. This\n' +
          'enables us to find out, for instance, when which page views occurred and from which region they came. In\n' +
          'addition, we collect various log files (e.g. IP address, referrer, browser, and operating system used) and can\n' +
          'measure whether our website visitors perform certain actions (e.g. clicks, purchases, etc.).',
        para3:
          'The use of this analysis tool is based on Art. 6(1)(f) GDPR. The website operator has a legitimate interest in\n' +
          'the analysis of user patterns, in order to optimize the operator’s web offerings and advertising. If appropriate\n' +
          'consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and §\n' +
          '25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information in the user’s\n' +
          'end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any\n' +
          'time.',
        subHeading1: 'IP anonymization',
        para4:
          'For analysis with Matomo we use IP anonymization. Your IP address is shortened before the analysis, so that\n' +
          'it is no longer clearly assignable to you.',
        subHeading2: 'Hosting',
        para5:
          'We host Matomo exclusively on our own servers so that all analysis data remains with us and is not passed\n' +
          'on',
      },
      six: {
        mainHeading: '6. Newsletter',
        heading1: 'Newsletter data',
        para1:
          'If you would like to subscribe to the newsletter offered on this website, we will need from you an e-mail\n' +
          'address as well as information that allow us to verify that you are the owner of the e-mail address provided\n' +
          'and consent to the receipt of the newsletter. No further data shall be collected or shall be collected only on a\n' +
          'voluntary basis. We shall use such data only for the sending of the requested information and shall not share\n' +
          'such data with any third parties.',
        para2:
          'The processing of the information entered into the newsletter subscription form shall occur exclusively on\n' +
          'the basis of your consent (Art. 6(1)(a) GDPR). You may revoke the consent you have given to the archiving of\n' +
          'data, the e-mail address, and the use of this information for the sending of the newsletter at any time, for\n' +
          'instance by clicking on the “Unsubscribe” link in the newsletter. This shall be without prejudice to the\n' +
          'lawfulness of any data processing transactions that have taken place to date.',
        para3:
          'The data deposited with us for the purpose of subscribing to the newsletter will be stored by us until you\n' +
          'unsubscribe from the newsletter or the newsletter service provider and deleted from the newsletter\n' +
          'distribution list after you unsubscribe from the newsletter or after the purpose has ceased to apply. We\n' +
          'reserve the right to delete or block e-mail addresses from our newsletter distribution list at our own\n' +
          'discretion within the scope of our legitimate interest in accordance with Art. 6(1)(f) GDPR.',
        para4: 'Data stored for other purposes with us remain unaffected.',
        para5:
          'After you unsubscribe from the newsletter distribution list, your e-mail address may be stored by us or the\n' +
          'newsletter service provider in a blacklist, if such action is necessary to prevent future mailings. The data from the blacklist is used only for this purpose and not merged with other data. This serves both your\n' +
          'interest and our interest in complying with the legal requirements when sending newsletters (legitimate\n' +
          'interest within the meaning of Art. 6(1)(f) GDPR). The storage in the blacklist is indefinite.',
        line1: ' You may object to the storage if your interests outweigh our legitimate interest.',
      },
      seven: {
        mainHeading: '7. Online-based Audio and Video Conferences (Conference tools)',
        subHeading1: 'Data processing',
        para1:
          'We use online conference tools, among other things, for communication with our customers. The tools we\n' +
          'use are listed in detail below. If you communicate with us by video or audio conference using the Internet,\n' +
          'your personal data will be collected and processed by the provider of the respective conference tool and by\n' +
          'us. The conferencing tools collect all information that you provide/access to use the tools (email address\n' +
          'and/or your phone number). Furthermore, the conference tools process the duration of the conference, start\n' +
          'and end (time) of participation in the conference, number of participants and other “context information”\n' +
          'related to the communication process (metadata).',
        para2:
          'Furthermore, the provider of the tool processes all the technical data required for the processing of the\n' +
          'online communication. This includes, in particular, IP addresses, MAC addresses, device IDs, device type,\n' +
          'operating system type and version, client version, camera type, microphone or loudspeaker and the type of\n' +
          'connection.',
        para3:
          'Should content be exchanged, uploaded, or otherwise made available within the tool, it is also stored on the\n' +
          'servers of the tool provider. Such content includes, but is not limited to, cloud recordings, chat/ instant\n' +
          'messages, voicemail uploaded photos and videos, files, whiteboards, and other information shared while\n' +
          'using the service.',
        para4:
          'Please note that we do not have complete influence on the data processing procedures of the tools used. Our\n' +
          'possibilities are largely determined by the corporate policy of the respective provider. Further information\n' +
          'on data processing by the conference tools can be found in the data protection declarations of the tools\n' +
          'used, and which we have listed below this text.',
        subHeading2: 'Purpose and legal bases',
        para5:
          'The conference tools are used to communicate with prospective or existing contractual partners or to offer\n' +
          'certain services to our customers (Art. 6(1)(b) GDPR). Furthermore, the use of the tools serves to generally\n' +
          'simplify and accelerate communication with us or our company (legitimate interest in the meaning of Art.\n' +
          '6(1)(f) GDPR). Insofar as consent has been requested, the tools in question will be used on the basis of this\n' +
          'consent; the consent may be revoked at any time with effect from that date.',
        subHeading3: 'Duration of storage',
        para6:
          'Data collected directly by us via the video and conference tools will be deleted from our systems\n' +
          'immediately after you request us to delete it, revoke your consent to storage, or the reason for storing the\n' +
          'data no longer applies. Stored cookies remain on your end device until you delete them. Mandatory legal\n' +
          'retention periods remain unaffected.',
        para7:
          'We have no influence on the duration of storage of your data that is stored by the operators of the\n' +
          'conference tools for their own purposes. For details, please directly contact the operators of the conference\n' +
          'tools.',
        subHeading4: 'Conference tools used',
        para8: 'We employ the following conference tools:',
        heading1: 'Zoom',
        para9:
          'We use Zoom. The provider of this service is Zoom Communications Inc, San Jose, 55 Almaden Boulevard,\n' +
          '6th Floor, San Jose, CA 95113, USA. For details on data processing, please refer to Zoom’s privacy policy:',
        para10:
          'Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European\n' +
          'Commission. Details can be found here:',
      },
      eight: {
        mainHeading: '8. Custom Services',
        heading1: 'Handling applicant data',
        para1:
          'We offer website visitors the opportunity to submit job applications to us (e.g., via e-mail, via postal services\n' +
          'on by submitting the online job application form). Below, we will brief you on the scope, purpose and use of\n' +
          'the personal data collected from you in conjunction with the application process. We assure you that the\n' +
          'collection, processing, and use of your data will occur in compliance with the applicable data privacy rights\n' +
          'and all other statutory provisions and that your data will always be treated as strictly confidential.',
        subHeading1: 'Scope and purpose of the collection of data',
        para2:
          'If you submit a job application to us, we will process any affiliated personal data (e.g., contact and\n' +
          'communications data, application documents, notes taken during job interviews, etc.), if they are required to\n' +
          'make a decision concerning the establishment or an employment relationship. The legal grounds for the\n' +
          'aforementioned are § 26 BDSG according to German Law (Negotiation of an Employment Relationship), Art.\n' +
          '6(1)(b) GDPR (General Contract Negotiations) and – provided you have given us your consent – Art. 6(1)(a)\n' +
          'GDPR. You may revoke any consent given at any time. Within our company, your personal data will only be\n' +
          'shared with individuals who are involved in the processing of your job application.',
        para3:
          'If your job application should result in your recruitment, the data you have submitted will be archived on the\n' +
          'grounds of § 26 BDSG and Art. 6(1)(b) GDPR for the purpose of implementing the employment relationship\n' +
          'in our data processing system.',
        subHeading2: 'Data Archiving Period',
        para4:
          'If we are unable to make you a job offer or you reject a job offer or withdraw your application, we reserve the\n' +
          'right to retain the data you have submitted on the basis of our legitimate interests (Art. 6(1)(f) GDPR) for up\n' +
          'to 6 months from the end of the application procedure (rejection or withdrawal of the application).\n' +
          'Afterwards the data will be deleted, and the physical application documents will be destroyed. The storage\n' +
          'serves in particular as evidence in the event of a legal dispute. If it is evident that the data will be required\n' +
          'after the expiry of the 6-month period (e.g., due to an impending or pending legal dispute), deletion will only\n' +
          'take place when the purpose for further storage no longer applies.',
        para5:
          'Longer storage may also take place if you have given your agreement (Article 6(1)(a) GDPR) or if statutory\n' +
          'data retention requirements preclude the deletion.',
      },
      seo: {
        title: 'Privacy Policy - Your Data Protection Information',
        description:
          'Discover our commitment to data privacy and security. Our Privacy Policy outlines how we collect, use, and protect your personal information. Learn more about your rights and choices regarding your data',
        keywords:
          'privacy policy, data protection, personal information, GDPR, CCPA, data privacy, user rights, data security, cookie policy, information handling',
      },
    },
    strategySection: {
      title: 'Our Strategy',
      first: {
        title: 'Define goal',
        content:
          'The basis of our cooperation is your personal goal, which we define together with you',
      },
      second: {
        title: 'Identify advantages',
        content:
          'We work out your unique selling points as an employer and record what specific benefits you offer employees.',
      },
      third: {
        title: 'Recruiting Strategy',
        content:
          'We identify your customized recruiting strategy that will set you apart from your competition and allow applicants to quickly get a quick picture of you.',
      },
      fourth: {
        title: 'Application Process',
        content:
          'Thanks to the employee friendly application and hiring processes, candidates can apply to you in a matter of minutes.',
      },
      fifth: {
        title: 'Long-term strategy',
        content:
          'With constant market analyzes you keep an eye on current strategies and ensure the long-term success of your recruiting campaign.',
      },
    },
    socialrecrutingcards: {
      title: 'Why social recruiting?',
      cardOne: {
        heading: 'The problem with job advertisements',
        paragraph:
          'With conventional job advertisements, you only address those who are actively looking for a job, not those who are currently employed.',
      },

      cardTwo: {
        heading: 'Social recruiting',
        paragraph: 'With social recruiting you address all employees, including current employees.',
      },
      cardThree: {
        heading: 'Qualified Employees',
        paragraph:
          'As a result, you continuously gain qualified employees who are an ideal fit for your company.',
      },
    },
    faq: {
      one: {
        question: 'Why are classic job advertisements not very helpful?',
        answer:
          'In the digital transformation, classic job advertisements offer only limited opportunities to drive recruitment. Job advertisements are fundamentally static. Many applicants do not feel sufficiently addressed and there is a risk of misinterpreting tasks and areas of activity in a job advertisement. The potential to find a suitable applicant through a job advertisement is therefore limited. Social recruiting brings more dynamism into the processes and responds more individually to the applicants.',
      },
      two: {
        question: 'What are the costs of social recruiting?',
        answer:
          'Initially, there are no costs for you in the counseling sessions. The total costs depend on your goal and your current status quo. In the free initial consultation, an expert analyses the situation and prepares relevant information. There are costs for further consultation appointments, ads and the landing page.',
      },
      three: {
        question: 'Why is social recruiting a cost-effective strategy?',
        answer:
          'Since social recruiting is driving the dynamics of the job market, the associated costs must always be viewed flexibly. This is not about having large contingents of job advertisements placed in the classic sense, but rather reacting specifically to internal personnel requirements and controlling social recruiting from there, so that adjustments can be made quickly and implemented in personnel recruitment activities.',
      },
      four: {
        question: 'How do I avoid damaging my reputation on social media?',
        answer:
          'Social media offers numerous potentials for recruiting staff. To avoid possible damage to reputation, it is important to define in advance exactly how the company wants to align and position itself. In this way, a framework is created in which we can move strategically together.',
      },
      five: {
        question: 'What influence does the migration of Facebook users have on social recruiting?',
        answer:
          'Social media offers numerous potentials for recruiting staff. To avoid possible damage to reputation, it is important to define in advance exactly how the company wants to align and position itself. In this way, a framework is created in which we can move strategically together.',
      },
    },
    references: {
      title: 'References',
      cardOne: {
        title: 'Stefanie Müller',
        subTitle: 'Lorem Gmbh',
        time: '3 Months',
        applications: '100 Applications',
        employees: '12 New employees',
        description:
          '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation “',
      },
      cardTwo: {
        title: 'Hans Stein',
        subTitle: 'Lorem Gmbh',
        time: '6 Months',
        applications: '120 Applications',
        employees: '45 New employees',
        description:
          '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation “',
      },
    },
    howwework: {
      title: 'How we work',
      cardOne: {
        title: 'Make an appointment',
        description:
          'Send us your inquiry today and arrange a meeting with us on your desired date.',
        btntext: 'Work with us',
      },
      cardTwo: {
        title: 'Preliminary talk',
        description:
          'In a non-binding preliminary discussion, we analyze your current situation and determine your needs.',
      },
      cardThree: {
        title: 'Consultation',
        description:
          'In the subsequent digital consultation, we will advise you individually and free of charge regarding a cooperation.',
      },
      cardFour: {
        title: 'Future cooperation',
        description:
          'Now you decide whether we will work together in the future and achieve your goals.',
      },
    },
    ourServices: {
      title: 'Services',
      cardOne: {
        title: 'Web design',
        description:
          'A professional website is the hallmark of every company and usually the first point of contact for your customers.',
        check1: 'Optimized for mobile devices',
        check2: 'Dynamic & static',
        check3: 'Safe & high-performance',
        check4: 'Best possible SEO',
      },
      cardTwo: {
        title: 'Software development',
        description:
          'We develop and operate test-driven and agile high-quality web applications using Javascript and React.',
        check1: 'Best quality through Test-First',
        check2: 'Low maintenance costs',
        check3: 'Specialized in NodeJS & React',
        check4: 'Latest technologies and methods',
      },
      cardThree: {
        title: 'Social Media Recruiting',
        description:
          'With the targeted placement of job advertisements, you trump conventional search engines and reach passively searching specialists.',
        check1: 'State-of-the-art approach',
        check2: 'Development of a recruiting process',
        check3: 'Constant adjustment',
        check4: 'Suitable for all industries',
      },
      cardFour: {
        title: 'SEO',
        description:
          'With the help of professional search engine optimization, websites are found more frequently. This is how you increase the success of your company.',
        check1: 'Optimization of your website',
        check2: 'High position in the ranking',
        check3: 'Increasing your success',
        check4: 'Strengthen your internet presence',
      },
    },
    contactUsForm: {
      title: 'Contact Form',
      titlePayment: 'Inquiry for: ',
      placeholder: {
        name: 'Name',
        email: 'E-Mail',
        message: 'Message',
        phone: 'Phone',
      },
      messageText1: 'I am interested in the product “',
      messageText2: '”. Please note my order and get back to me.',
      error: 'An error occurred while sending the message. Please try again.',
      success: 'Your message was sent successfully. We will contact you as soon as possible.',
      nameError: 'Your name must be at least 5 characters long.',
      emptyEmailErr: 'Please enter your e-mail address.',
      InvalidEmailErr: 'Please enter a valid email address.',
      messageError: 'The message must be at least 10 characters long.',
      waitingList: 'You will be added to our waiting list.',
    },
    seo: {
      title: 'Social Media Recruiting Agency | Nicolas Welitzki IT Consulting',
      description:
        'Social Media Recruiting Agency - We help you to find the right employees for your company. We take care of the entire process from advertising to the final interview.',
      keywords:
        'social media recruiting, social media recruiting agency,recuitment help in germany',
    },
  },
  company: {
    common: {
      acceptCookie: 'Please allow functional cookies to enable Calendly!',
    },
    navbar: {
      services: 'Services',
      references: 'References',
      aboutUs: 'About Us',
      products: 'Products',
    },
    hero: {
      firstText: 'We are a ',
      secondText: 'digital studio for ',
      webDesign: 'Web Design',
      softwareDevelopment: 'Web Development',
      socialMediaRecruiting: 'Social Media Recruiting',
      seo: 'SEO',
    },
    quote: {
      text: 'Our goal is to create a combination of first-class conditions, reliability and fair prices. As a result of our many years of experience, we can also implement complex issues in modern IT solutions',
    },
    aboutUs: {
      mika: {
        name: 'Mika Lembrecht',
        role: 'Project Management, Assistance and Content Creator',
        details:
          'As a project manager, Mika coordinates all internal and external resources for regular tasks and one-off projects. He is responsible for the company communication and coordinates the creation of content.',
      },
      payal: {
        name: 'Payal Kaklotar',
        role: 'Software Development and Testing',
        details:
          'Since 2020, Payal has been an experienced software developer who is responsible for software testing within the company. She ensures that all products meet the high quality standards and customer requirements.',
      },
      soft: {
        name: 'Sopuluchukwu Obi',
        role: 'Design and Software Development',
        details:
          'Sopuluchukwu (Soft) has been a software developer since 2015 and has already worked on a large number of software projects. He has extensive experience in various areas of software development, including design, implementation and quality assurance.',
      },
    },
    ourProducts: {
      title: 'Products',
      product1: {
        title: 'Website Analysis',
        tag: 'net',
        check1: 'Website overview and first impressions',
        check2: 'Conducting performance tests',
        check3: 'Compliance with best practices',
        check4: 'Implementation of SEO measures',
        check5: 'Risk of warning by Google Fonts',
        hint: 'Processing time: 3 days',
      },
      product2: {
        title: 'Web-Boost',
        tag: '.',
        free: 'Free',
        desc: 'Slow loading websites scare away potential customers and visitors, let us test and optimize your website.',
        check1: 'Analysis of your current website and identification of optimization opportunities',
        check2: 'Improve performance and speed',
        hint: 'Processing time: 7 days',
      },
      product3: {
        title: 'GDPR elimination',
        tag: 'net',
        desc:
          'Is your website at risk of being dunned?\n \n' +
          'We integrate the Google Fonts correctly and eliminate all risks.\n' +
          '\n',
        check1: 'Fast disposal',
        check2: '100% protection',
        check3: 'Including all subpages',
        hint: 'Processing time: 1-3 working days',
      },
      webBoost: {
        card1: {
          title: 'Web-Boost S',
          tag: 'net',
          check1: '<b>3</b> hours performance optimization of your website',
          check2: 'Analysis with Google Lighthouse',
          check3: 'Action plan to improve the website',
          check4: '2-3 features for optimization',
        },
        card2: {
          title: 'Web-Boost M',
          tag: 'net',
          check1: '<b>5</b> hours performance optimization of your website',
          check2: 'Analysis with Google Lighthouse',
          check3: 'Detailed action plan for website improvement',
          check4: 'Performance optimization for faster loading',
          check5: '4-5 features for optimization',
        },
        card3: {
          title: 'Web-Boost L',
          tag: 'net',
          check1: '<b>10</b> hours performance optimization of your website',
          check2: 'Comprehensive analysis with Google Lighthouse',
          check3: 'Detailed action plan to improve the website',
          check4: 'Performance optimization for faster loading',
          check5: 'Performance and accessibility improvement',
          check6: 'SEO optimization',
          check7: '6-8 features for optimization',
        },
        card4: {
          title: 'Individual',
          check1: 'Individually determined fixed sum based on the problems of the website',
          check2: 'Customized analysis and optimization',
          check3: 'All functions of the M and L variants adapted to individual requirements',
        },
      },
    },
    reference: {
      title: 'References',
      hide: 'Read less',
      read: 'Read more',
      rene: {
        name: 'René Böttiger',
        role: 'Selbstständiger Energieberater',
        details:
          '“I am extremely satisfied with the services provided by Welitzki IT. They designed my company homepage with impressive expertise and utmost professionalism. Our cooperation went smoothly and the result exceeded my expectations. The website is not only appealing, but also functional and user-friendly. I can wholeheartedly recommend Welitzki IT and look forward to more projects with this great team!“',
      },
      david: {
        name: 'David Bieder',
        role: 'Managing Director at cultivate GmbH',
        details:
          '“I enjoyed working with Nicolas in 2019 and 2020 on a B2C React Redux app for Immoscout24. He implemented several features that affected the entire app stack. “',
      },
      andreas: {
        name: 'Florian Kube',
        role: 'Product owner at ImmobilienScout24',
        details:
          '“I strongly recommend N. as an experienced and reliable software developer. It would be my pleasure to work with him again.“',
      },
    },
    aboutUsNic: {
      title: 'About us',
      name: 'Nicolas Welitzki (Owner)',
      detail:
        // eslint-disable-next-line quotes
        '<li>Over 20 years of expertise as a professional software developer and business analyst</li><li>Full-stack developer for web applications using technologies such as JavaScript, React, Vue, Node.js, and Express</li><li>Experience in many different industries (real estate, finance, e-commerce, automobiles, etc.)</li><li>Self-employed since 2019</li><li>Implementation of projects in a combination of permanent employees and partners</li><li>Offers web design, software development & SEO services throughout Germany</li>',
      // Below text is commented out as it is replaced by the above text
      //   paragraphOne:
      //     'The Berlin managing director Nicolas Welitzki can look back on more than 20 years of professional experience as a professional software developer.',
      //   paragraphTwo:
      //     // eslint-disable-next-line quotes
      //     "After completing his master's degree at the University of Applied Sciences and a few job stations, he decided to work independently on his own projects. Nicolas Welitzki has already been able to participate in projects from various industries, such as real estate, the financial sector, e-commerce and many others.",
      //   paragraphThree:
      //     // eslint-disable-next-line quotes
      //     "Since becoming self-employed in June 2019, he has supported, among other things, the largest German real estate platform, Europe's largest provider of leisure vehicles and other national and international companies.",
      //   paragraphFour:
      //     'With his employees, he offers services in the Berlin/Brandenburg area and in Germany.',
      freeTime: 'On a volunteer basis, Nicolas Welitzki is involved in:',
      freeTimeOne: 'Business Association',
      freeTimeTwo: 'Advisory Board HTW Berlin Business Informatics',
      freeTimeThree: 'Management of the support association of a kindergarten',
    },
    contactPartner: {
      title: 'Contact Partner',
      details:
        '<p>In addition to our permanent employees, we have a broad network of partners and contacts that we can rely on at any time. To handle multiple projects simultaneously, we collaborate with freelancers who have been supporting us actively for over three years. These freelancers are distinguished by their expertise in software development, online marketing, and web design.<br><br>Especially for extensive software projects, we also cooperate with other companies to provide our customers with optimal service. The long-standing collaboration with our freelancers and partners ensures a well-coordinated and efficient workflow.<br><br>The quality of our services is ensured not only by our employees but also by this versatile network. This enables us to offer our customers high-quality solutions - and that in an efficient and timely manner.</p>',
    },
    seo: {
      title: 'Web Design / Web Applications / Social Media Recruiting / SEO',
      description:
        'Agency from Berlin for web design ✅ web applications ✅ social media recruiting ✅ and SEO ✅! Create your new website with us, find qualified professionals and let your site rank higher | Nicolas Welitzki IT-Consulting',
      keywords:
        'Web design, landing page, web applications, responsive, social media recruiting, finding employees, SEO, search engine optimization, website analysis, Google Font analysis, protection against warnings',
    },
  },
  webDesign: {
    navbar: {
      start: 'Start',
      wy: 'Why?',
      procedure: 'Procedure',
      whyUs: 'Why Us?',
      aboutUs: 'About Us',
      contact: 'Contact',
      faq: 'FAQ',
    },
    hero: {
      firstText: 'Web design - creation of ',
      secondText: 'performant websites',
      lastText: ' & landing pages with Wordpress',
      button: 'Book your first consultation now',
    },
    callToAction: {
      heading1: 'Ready to design a new website?',
      heading2: 'Make an appointment now',
    },
    howwework: {
      title: 'How we work',
      cardOne: {
        title: 'Make an appointment',
        description:
          'Send us your inquiry today and arrange a meeting with us on your desired date.',
        btntext: 'Work with us',
      },
      cardTwo: {
        title: 'Preliminary talk',
        description:
          'In a non-binding preliminary discussion, we analyze your current situation and determine your needs.',
      },
      cardThree: {
        title: 'Consultation',
        description:
          'In the subsequent digital consultation, we will advise you individually and free of charge regarding a cooperation.',
      },
      cardFour: {
        title: 'Future cooperation',
        description:
          'Now you decide whether you want to work together and we will create your dream website.',
      },
    },
    why: {
      heading: 'Why a modern website is important',
      heading2: 'Never before has a modern website been as important as it is today.',
      firstText: 'Seems serious and professional',
      secondText: 'Is often the first customer contact',
      thirdText: 'Shows who you are and what you offer',
      fourthText: 'Attractive websites increase the chance to attract customers',
      fifthText: 'The competition is just a click away',
    },
    whyUs: {
      heading: 'Why us?',
      cardOne: {
        heading: 'Responsive web design',
        paragraph:
          'We help companies throughout Germany to generate the website of their dreams. Thanks to the responsive design, your website is available both as a desktop and as a mobile version.',
      },
      cardTwo: {
        heading: 'CMS + SEO',
        paragraph:
          'Thanks to our many years of experience with Wordpress and other CMS, we are able to implement almost every wish. But the service does not end here, we also perfect your website with search engine optimization.',
      },
      cardThree: {
        heading: 'Advisory',
        paragraph:
          'Customer contact and fast communication are very important to us. You will be advised and included during the collaboration. This way you can see how your professional website is created.',
      },
      cardFour: {
        heading: 'Further support',
        paragraph:
          'Even after the website has been completed, we remain at your disposal to carry out regular maintenance and updates, as well as to monitor search engine optimization.',
      },
    },
    showCases: {
      card1: {
        heading: 'Design and implementation of a responsive website',
        text: 'Self-employed energy consultant',
      },
      card2: {
        heading: 'Support for old browsers',
        text: 'International company for installation technology',
      },
      card3: {
        heading: 'Creation of a single page application',
        text: 'Online real estate marketplace',
      },
    },
    faq: {
      one: {
        question: 'What is Search Engine Optimization?',
        answer:
          'Search engine optimization, also called SEO (Search Engine Optimization), is a common tool to increase the reach of your website. It includes all measures that serve to position your website higher in the ranking. Almost all components of the website are optimized. This ranges from the source code and the server, to the mobile version, to the basic content, including text and images. The aim of SEO is to increase reach, increase brand awareness and thereby generate higher sales.',
      },
      two: {
        question: 'Static Website vs. Dynamic Website',
        answer:
          'Static websites are very suitable if the content of the website only needs to be changed infrequently. They are programmed in HTML, CSS and JavaScript. Static websites are quick to generate and easy to maintain. Your advantage is a fast loading time. Dynamic websites, on the other hand, obtain their content from at least one database. They are particularly suitable for interactive websites such as online shops. Which variant should be used depends on various factors (e.g. purpose, time, number of texts and how up-to-date they are). Both variants have advantages and disadvantages.',
      },
      three: {
        question: 'What is a Content Management System - CMS?',
        answer:
          'A content management system (CMS) is software for managing and creating websites. Using a CMS requires no experience. The most well-known CMS is WordPress.',
      },
      four: {
        question: 'Was ist Responsive Design?',
        answer:
          'Responsive web design means adapting the website to the respective format of the device. This means there are multiple flexible themes for smartphones, tablets, desktops, etc. This increases the user-friendliness of the website.',
      },
      five: {
        question: 'What is web accessibility?',
        answer:
          'Modern websites should be designed and constructed to be as barrier-free as possible. They should also be easily accessible and understandable for older, disabled or restricted people. This not only increases the number of satisfied users of your website, but Google recognizes this and ranks your website higher.',
      },
      six: {
        question: 'What are my obligations to cooperate?',
        answer:
          'An optimal result can only be guaranteed if customer and service provider work together. Communication and feedback are very important to us. Your wishes and ideas for the website are discussed at the beginning and refined and perfected over time, so that in the end you get the desired end product.',
      },
    },
    aboutUs: {
      heading: 'About Us',
      firstParagraph:
        'Nice that you have found us! We offer tailor-made solutions for small and medium-sized companies in the DACH region. With an experienced and passionate team, the company prioritizes usability and user experience to design attractive and functional websites. Would you like to find out more about us?',
    },
    seo: {
      title: 'Agency for web design in the DACH region',
      description:
        'Our team specializes in designing high-quality and aesthetic websites for companies of all industries. We work with the latest methods and technologies and are very experienced in web design.',
      keywords:
        'Web design, web development, web agency, web design, web development, web agency, web design Berlin, web development Berlin, web agency Berlin',
    },
    contactUs: {
      details: 'Let us analyse and discuss your new web design project together',
    },
  },
  newsLetter: {
    title: 'Sign up for our newsletter!',
    description:
      'Yes, I would like to receive a newsletter on specialist topics such as web design, social media recruiting, software development and SEO. We provide information on revocation and data processing in our ',
    privacyText: 'Privacy Policy.',
    cancelBooking: 'Cancelable at any time',
    placeholder: {
      name: 'Name',
      email: 'Email',
    },
    error: 'An error occurred while sending the Message. Please try again.',
    // eslint-disable-next-line quotes
    success: "Confirmed! You're on the newsletter list.",
    nameError: 'Your name must be at least 5 characters long.',
    emptyEmailErr: 'Please enter your email address.',
    InvalidEmailErr: 'Please enter a valid email address.',
  },
  webDevelopment: {
    navbar: {
      start: 'Start',
      why: 'Why we?',
      showcases: 'Showcases',
      services: 'Services',
      products: 'Products',
    },
    hero: {
      firstText: 'Development',
      secondText: ' professional web applications',
      lastText: ' - with the most modern methods and technologies',
    },
    why: {
      heading: 'Why we?',
      firstText: 'Agile development processes with close customer contact',
      secondText: 'Sustainable approach to web project development',
      thirdText: 'Integration of automated tests for higher quality',
      fourthText: 'Maximum success by focusing on usability & user satisfaction',
    },
    services: {
      heading: 'Services',
      firstCard: {
        heading: 'Web Applications',
        services: {
          first: 'React & TypeScript',
          second: 'JavaScript, PHP & HTML',
          third: 'Redux & other patterns',
        },
      },
      secondCard: {
        heading: 'Software Migration',
        services: {
          first: 'Modernization of old technologies',
          second: 'Transfer old software to new environment',
        },
      },
      thirdCard: {
        heading: 'Desktop-Software',
        services: {
          first: 'Cross-platform desktop applications',
          second: 'Electron with HTML, CSS and JavaScript',
        },
      },
      fourthCard: {
        heading: 'Troubleshooting & Debugging',
        services: {
          first: 'Professional troubleshooting',
          second: 'Repairing faulty software',
        },
      },
    },
    showCases: {
      card1: {
        heading: 'Higher conversion rate',
        text: 'Online real estate marketplace',
      },
      card2: {
        heading: 'Support for old browsers',
        text: 'International company for installation technology',
      },
      card3: {
        heading: 'Creation of a single page application',
        text: 'Online real estate marketplace',
      },
    },
    products: {
      title: 'Products',
      card1: {
        title: 'Booking from minute basis',
        desc: 'starting at 1.50 euros per minute/ 90 euros per hour',
      },
      card2: {
        title: 'Creation of a prototype',
        desc: 'Price on request',
      },
      card3: {
        title: 'Training/Coaching',
        desc: 'Price on request',
      },
    },
    callToAction: {
      heading1: 'Ready to have your individual software developed ?',
      heading2: 'Please feel free to contact us.',
    },
    faq: {
      one: {
        question: 'What do you mean by software development?',
        answer:
          'Software development is the process of creating computer programs. It includes planning, creation, testing and improvement of the software.',
      },
      two: {
        question: 'What technologies do we specialize in?',
        answer:
          'Our team specializes mainly in JavaScript, TypeScript, React, Redux. However, we are also trained in PHP, Java and Kotlin.',
      },
      three: {
        question: 'How to develop a software?',
        answer:
          'Software is developed by first gathering and analyzing requirements, then creating a plan of how the software should look and function. Then the code is written and tested to make sure everything works properly. After all problems are fixed, the software is released and maintained to make sure it will work well in the future.',
      },
      four: {
        question: 'What is the incremental development?',
        answer:
          'Incremental development is a method of software development where you develop a software in small steps or increments, each step is a small and completed unit. This makes it possible to quickly get feedback from users and quickly improve the software. It also allows the software to be developed in a shorter time and with low risk.',
      },
      five: {
        question: 'What is Agile Software Engineering?',
        answer:
          'Agile software engineering is a method of software development that focuses on agility and flexibility. It makes it possible to change requirements quickly and react to problems quickly. It is based on short development cycles and constant collaboration between developers, customers and other stakeholders.',
      },
      six: {
        question: 'What are Single Page Applications (SPA)?',
        answer:
          'Single-page applications (SPA) are web applications where only a single HTML page is loaded while the content is dynamically updated. They differ from traditional web applications, where each page is loaded individually and the user navigates by loading new pages.',
      },
      seven: {
        question: 'What is Test-Driven Development (TDD)?',
        answer:
          'Test-Driven Development (TDD) is a method of the software development process in which tests are first written for the functionality to be developed before the actual code is created. The code is then written to pass the tests and provide the desired functionality. This results in clean and well-structured codes that are easy to maintain and extend.',
      },
      eight: {
        question: 'How to use Version Control Systems (VCS)?',
        answer:
          'VCS are used to track and manage changes to files in a project. Each change is saved in a "version" that can be restored later. VCS also allows changes to be made to a project by multiple people at the same time and conflicts to be resolved automatically.',
      },
      nine: {
        question: 'What are the basic principles of good software design?',
        answer:
          'Good software design is based on several basic principles. The most important is simplicity; well-designed software should be easy to understand, implement, and maintain. It should also be divided into small modules and be reusable.',
      },
      ten: {
        question: 'Why do we create clean code and what is it?',
        answer:
          'Clean code is a concept where code is written in such a way that it can be easily read and understood. This is achieved by using a well-structured code. Clean code facilitates collaboration and efficiency in the team and improves the quality of the codes.',
      },
      eleven: {
        question: 'YAGNI, KISS, Rule of 3, DRY Principles - What do they stand for?',
        answer: `YAGNI stands for "You Ain't Gonna Need It" and is a principle of agile software development that says you should not invest in functionality you may need in the future, but focus on current requirements.

KISS stands for "Keep It Simple, Stupid" and is a design principle that says systems work best when they are simple and easy to understand.

Rule of 3 states that you should use no more than three layers of abstraction in a system to increase code understandability and maintainability.

DRY stands for "Don't Repeat Yourself" and states that you should not use redundant code, but instead reuse functionality to reduce code maintainability and error-proneness`,
      },
      twelve: {
        question: 'What does Red Green Refactor mean?',
        answer:
          '"Red, Green, Refactor" is a process of test-driven development (TDD). It is a process in which developers first write an automated test for a functionality they want to add ("Red" step). Then they write the code to make the test successful ("Green" step). Finally, they refactor the code to make it more readable without changing the functionality ("Refactor" step).',
      },
      thirteen: {
        question: 'Why do we build components with the storybook?',
        answer: `Building components using the storybook has many advantages.

With the help of the storybook, designers and developers can develop the components together, which makes collaboration much easier.Moreover, the storybook makes it possible to reuse individual components by saving them individually.Apart from that, components can be tested independently and automatically.`,
      },
    },
    contactUs: {
      details: 'Let us analyse and discuss your new web-app project together',
    },
    seo: {
      title: 'Development of professional web applications',
      description:
        'Our team specializes in developing highly professional web applications and software. Our team is trained to work with the most modern methods and technologies to deliver the desired product to our customers.',
      keywords:
        'web design, web development, web agency, web design, web development, advertising agency, web design Berlin, web development Berlin, web agency Berlin',
    },
  },
  footer: {
    copyright: `Copyright © ${new Date().getFullYear()}`,
    follow: 'Folge uns',
    cookie: 'Cookie -Einstellungen',

    location: 'Location',
    services: 'Services',
    products: 'Products',
    contact: 'Contact',

    email: 'Email',
    phone: 'Phone',

    webDesign: 'Web Design',
    softwareDevelopment: 'Software development',
    socialMediaRecruiting: 'Social Media Recruiting',
    seo: 'SEO',

    websiteAnalysis: 'Website Analysis',
    webBoost: 'Web-Boost',
    gdprElimination: 'GDPR Elimination',
  },
  blog: {
    title: 'welcome to our blog',
    by: 'by ',
    moreFromUs: 'more from us',
    seo: {
      title: 'All blog entries from Nicolas WelitzkiIT-Consulting',
      description:
        'Find exciting and interesting blog entries from our team. We create blogs about general IT topics, web design, web applications and SEO. We regularly post new blog entries, do not miss anything!',
      keywords:
        'Web Design, Web Development, Web Agency, Webdesign, Web Development, Advertising Agency, Webdesign Berlin, Web Development Berlin, Web Agency Berlin',
    },
  },
}
