import React from 'react'
import { useIntl } from 'react-intl'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'

import product1 from '../../../../assets/images/webDevelopment/product1.svg'
import product2 from '../../../../assets/images/webDevelopment/product2.svg'
import product3 from '../../../../assets/images/webDevelopment/product3.svg'

import WdProductCard from '../../../common/display/WdProductCard/WdProductCard'

const Products = () => {
  const intl = useIntl()

  const data = [
    {
      id: 1,
      card: 'card1',
      image: product1,
      eventName: 'wdev_product_booking',
      name: intl.formatMessage({
        id: 'webDevelopment.products.card1.title',
        defaultMessage: 'Booking from minute basis',
      }),
      desc: intl.formatMessage({
        id: 'webDevelopment.products.card1.desc',
        defaultMessage: 'starting at 1.50 euros per minute/ 90 euros per hour',
      }),
    },
    {
      id: 2,
      card: 'card2',
      image: product2,
      eventName: 'wdev_product_prototype',
      name: intl.formatMessage({
        id: 'webDevelopment.products.card2.title',
        defaultMessage: 'Creation of a prototype',
      }),
      desc: intl.formatMessage({
        id: 'webDevelopment.products.card2.desc',
        defaultMessage: 'Price on request',
      }),
    },
    {
      id: 3,
      card: 'card3',
      image: product3,
      eventName: 'wdev_product_training',
      name: intl.formatMessage({
        id: 'webDevelopment.products.card3.title',
        defaultMessage: 'Training/Coaching',
      }),
      desc: intl.formatMessage({
        id: 'webDevelopment.products.card3.desc',
        defaultMessage: 'Price on request',
      }),
    },
  ]
  return (
    <div id='product' className='section'>
      <h2>
        <Msg id='webDevelopment.products.title' />
      </h2>
      <div className='grid justify-center x:grid-flow-col x:auto-cols-max pb-[50px] gap-[47px] '>
        {data.map((card) => (
          <WdProductCard
            key={card.id}
            image={card.image}
            eventName={card.eventName}
            name={card.name}
            desc={card.desc}
            card={card.card}
          />
        ))}
      </div>
    </div>
  )
}

Products.propTypes = {
  bgColor: PropTypes.string,
}

export default Products
