import React from 'react'
import PropTypes from 'prop-types'
import ErrorRed from '../../../../assets/images/errorRed.png'
import ErrorYellow from '../../../../assets/images/errorYellow.png'

const TextInput = ({
  id,
  value,
  type,
  mandatory,
  placeholder,
  onChange,
  errorMsg,
  extraClasses,
  secondVariant,
}) => {
  return (
    <>
      <div className='flex flex-col'>
        <input
          id={id}
          name={id}
          type={type}
          value={value}
          required={mandatory}
          placeholder={`${placeholder}${mandatory ? ' *' : ''}`}
          onChange={(e) => onChange(e.target.value)}
          className={`form-input bg-formBG px-3 w-full py-2 h-[51px] rounded-xl  ${
            extraClasses ? extraClasses : 'placeholder-textPrimary'
          } ${errorMsg ? 'border-red' : 'border-none'}`}
        />
        {errorMsg && (
          <span className={`${secondVariant ? 'text-white' : 'text-red'} flex pt-1 text-sm`}>
            <img
              src={`${secondVariant ? ErrorYellow : ErrorRed}`}
              className='h-7 pr-1'
              alt='Error Message Icon'
            />
            <span role='alert' aria-label={placeholder} className='pt-1'>
              {errorMsg}
            </span>
          </span>
        )}
      </div>
    </>
  )
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  mandatory: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  extraClasses: PropTypes.string,
  secondVariant: PropTypes.bool,
}
export default TextInput
