import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'
import { scroller } from 'react-scroll'
import Button from '../../inputs/Button/Button'

import HeroSVGDesign from '../../../../assets/images/webDesign/hero.svg'
import HeroSVGDev from '../../../../assets/images/webDevelopment/webTechnologies.svg'
import { trackEvent } from '../../../../matomo'

const Hero = ({ page, categoryName }) => {
  const logo = page === 'webDesign' ? HeroSVGDesign : HeroSVGDev
  const trackEventHero = () => {
    let eventName =
      categoryName === 'WebDesign Page' ? 'wdesign_hero_btn_click' : 'wdev_hero_btn_click'
    trackEvent(categoryName, 'Click', eventName)
  }
  return (
    <div
      id='hero-text-web-design'
      className={`px-[35px] ${
        page === 'webDevelopment' ? '' : 'bg-secondary'
      } md:px-10 lg:px-32 flex flex-col md:flex-row`}
    >
      <div className='py-auto mt-10 text-left sm:text-center md:text-left w-full md:w-[50%] flex flex-col items-start justify-center'>
        <h1 className='leading-[30px] md:leading-[45px] lg:leading-[54px] pb-10 sm:py-5 sm:px-36 md:px-0 md:pr-8 text-xl font-semibold tracking-normal text-left sm:text-center md:text-left align-middle md:text-3xl lg:text-4xl'>
          <Msg id={`${page}.hero.firstText`} defaultMessage='First Line' />
          <span className='underline underline-offset-[10px] decoration-blue decoration-[3px] md:decoration-[5px]'>
            <Msg id={`${page}.hero.secondText`} defaultMessage='second text' />
          </span>
          <Msg id={`${page}.hero.lastText`} defaultMessage='3rd text' />
        </h1>
        <Button
          transparent={false}
          withIcon={true}
          onClick={() => {
            trackEventHero()
            scroller.scrollTo('contactus', {
              duration: 150,
              delay: 100,
              smooth: true,
            })
          }}
          extraClasses='mb-8 sm:mb-16 mx-auto md:mx-0 font-semibold h-[51px]'
          text='consultation'
        />
      </div>
      <div className=' flex justify-center items-center mx-auto h-[200px] md:h-[541px]'>
        <img
          src={logo}
          alt={page}
          title={page}
          className={` md:h-[306.33px]  sm:w-full 
          ${page === 'webDevelopment' ? 'w-[228.71px] h-[222.87px]' : 'w-4/5 h-[180px]'}`}
        />
      </div>
    </div>
  )
}
Hero.propTypes = {
  page: PropTypes.string,
  categoryName: PropTypes.string,
}

export default Hero
