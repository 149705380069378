import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import OurProductCard from '../../common/display/OurProductCard/OurProductCard'

import product1 from '../../../assets/images/webDesign/product1.svg'
import product2 from '../../../assets/images/webDesign/product2.svg'
import product3 from '../../../assets/images/webDesign/product3.svg'

const OurProducts = () => {
  const data = [
    {
      id: 1,
      name: 'home_product_btn_click_wanalysis',
      card: 'product1',
      image: product1,
      tag: true,
      showPrice: true,
      showDescription: false,
      showInformation: false,
      productCount: 5,
      showIcon: false,
      smallOffer: false,
      bigOffer: false,
      price: '49,95 €',
      offerPrice: '49,95 €',
      hint: true,
    },
    {
      id: 2,
      name: 'home_product_btn_click_gdprcheck',
      card: 'product2',
      image: product2,
      thin: true,
      showPrice: true,
      priceSmall: true,
      showDescription: true,
      showInformation: false,
      productCount: 2,
      showIcon: false,
      smallOffer: false,
      bigOffer: false,
      price: 'Paket S, M, L, Individual',
      offerPrice: '0',
      showProducts: true,
      hint: true,
    },
    {
      id: 3,
      name: 'home_product_btn_click_gdprelimination',
      card: 'product3',
      image: product3,
      tag: true,
      showPrice: true,
      showDescription: true,
      showInformation: false,
      productCount: 3,
      showIcon: false,
      smallOffer: false,
      bigOffer: false,
      price: '79,95 €',
      offerPrice: '79,95 €',
      hint: true,
    },
  ]

  return (
    <div className='section-without-padding' id='products'>
      <h2>
        <Msg id='company.ourProducts.title' defaultMessage='Our Products' />
      </h2>
      <div
        id='productGrid'
        className='grid justify-center x:grid-cols-3 gap-[42px] xl:gap-[113px] mx-auto px-[15px] ti:px-[32px] xl:px-[57px] mb-[80px]'
      >
        {data.map((item) => (
          <OurProductCard
            key={item.id}
            eventName={item.name}
            card={item.card}
            image={item.image}
            tag={item.tag}
            showPrice={item.showPrice}
            priceSmall={item.priceSmall}
            showDescription={item.showDescription}
            showInformation={item.showInformation}
            productCount={item.productCount}
            showIcon={item.showIcon}
            smallOffer={item.smallOffer}
            bigOffer={item.bigOffer}
            price={item.price}
            offerPrice={item.offerPrice}
            showProducts={item.showProducts}
            hint={item.hint}
          />
        ))}
      </div>
    </div>
  )
}

export default OurProducts
