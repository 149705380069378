import React from 'react'
import Collapse from '../../../common/display/Collapse/Collapse'

const FAQ_LIST = [
  {
    id: 1,
    name: 'one',
    isExpand: true,
  },
  {
    id: 2,
    name: 'two',
    isExpand: false,
  },
  {
    id: 3,
    name: 'three',
    isExpand: false,
  },
  {
    id: 4,
    name: 'four',
    isExpand: false,
  },
  {
    id: 5,
    name: 'five',
    isExpand: false,
  },
]

const Faq = () => (
  <div className='section bg-secondary'>
    <h2>FAQ</h2>
    <div className='pb-10 flex flex-col gap-6'>
      {FAQ_LIST.map((item) => (
        <Collapse
          key={item.id}
          faqOrder={item.name}
          isExpand={item.isExpand}
          name={'socialMediaRecruiting'}
        />
      ))}
    </div>
  </div>
)

export default Faq
