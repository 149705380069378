import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Navbar from '../../components/common/sections/Navbar/Navbar'
import rootLogo from '../../assets/images/welitzki-it-logo-long.svg'
import Hero from '../../components/Main/Hero/Hero'
import Footer from '../../components/common/sections/Footer/Footer'
import OurServices from '../../components/Main/OurServices/OurServices'
import ReferencesSection from '../../components/Main/ReferencesSection/ReferencesSection'
import AboutUsNic from '../../components/Main/AboutUsNic/AboutUsNic'
import { navItemsMain } from '../navItems'
import OurProducts from '../../components/Main/OurProducts/OurProducts'
import ModalContactUs from '../../components/common/display/ModalContactUs/ModalContactUs'
import ModalCalendly from '../../components/common/display/ModalPopUp/ModalCalendly'
import NewsletterCTA from '../../components/common/sections/NewsletterCTA/NewsletterCTA'
import MetaData from '../MetaData/MetaData'
import Slogan from '../../components/common/sections/Slogan/Slogan'
import RecentArticles from '../../components/Main/RecentArticles/RecentArticles'
import ContactPartner from '../../components/Main/ContactPartner/ContactPartner'

const Company = ({ hasAcceptedFunctionalCookie, setShowCookieConsent, bannerAccepted }) => {
  const [showCalendlyContactForm, setShowCalendlyContactForm] = React.useState(false)
  const [showModal, setShowModal] = useState(false)
  return (
    <div className='page-wrapper'>
      <MetaData
        title='Webdesign / Web Applikationen / Social Media Recruiting / SEO'
        description='Agentur aus Berlin für Webdesign ✅ Web-Applikationen ✅ Social Media Recruiting ✅ und SEO ✅! Erstellen Sie mit uns Ihre neue Webseite, finden sie qualifizierte Fachkräfte und lassen sie Ihre Seite höher ranken | Nicolas Welitzki IT-Consulting'
        keywords='Webdesign, Landingpage, Web-Applikationen, Responsive, Social Media Recruiting, Mitarbeiter finden, SEO, Suchmaschinenoptimierung,  Websiteanalyse, Google Font Analyse, Absicherung vor Abmahnungen'
      />
      <Navbar
        navItems={navItemsMain}
        secondVarient={false}
        logo={rootLogo}
        categoryName='Home Page'
      />
      <div role='main'>
        <Hero setShowCalendlyContactForm={setShowCalendlyContactForm} />
        <OurServices />
        <OurProducts />
        <Slogan
          variant='primary'
          setShowCalendlyContactForm={setShowCalendlyContactForm}
          categoryName='Home Page'
        />
        <ReferencesSection />
        <RecentArticles />
        <NewsletterCTA categoryName='Home Page' />
        <AboutUsNic
          setShowCalendlyContactForm={setShowCalendlyContactForm}
          categorycategoryName='Home Page'
        />
        <ContactPartner />
      </div>
      <Footer
        theme={'light'}
        setShowCookieConsent={setShowCookieConsent}
        setShowModal={setShowModal}
        categoryName='Home Page'
        hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
        bannerAccepted={bannerAccepted}
      />
      <ModalCalendly
        showModal={showCalendlyContactForm}
        setShowModal={setShowCalendlyContactForm}
        hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
      />
      <ModalContactUs showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

Company.propTypes = {
  setShowCookieConsent: PropTypes.func,
  hasAcceptedFunctionalCookie: PropTypes.bool,
  bannerAccepted: PropTypes.bool,
}

export default Company
