import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Navbar from '../../components/common/sections/Navbar/Navbar'
import { useParams } from 'react-router-dom'
import { navItemsMain } from '../navItems'
import rootLogo from '../../assets/images/welitzki-it-logo-long.svg'
import Footer from '../../components/common/sections/Footer/Footer'
import SinglePost from '../../components/SinglePost/SinglePost'
import NewsletterCTA from '../../components/common/sections/NewsletterCTA/NewsletterCTA'
import ModalContactus from '../../components/common/display/ModalContactUs/ModalContactUs'

const PostDetail = ({ bannerAccepted, setShowCookieConsent }) => {
  const [showModal, setShowModal] = useState(false)

  const { slug } = useParams()
  return (
    <div className='page-wrapper'>
      <Navbar navItems={navItemsMain} secondVarient={false} logo={rootLogo} />
      <SinglePost slug={slug} />
      <NewsletterCTA blog={true} />
      <Footer
        theme={'light'}
        setShowModal={setShowModal}
        setShowCookieConsent={setShowCookieConsent}
        bannerAccepted={bannerAccepted}
      />
      <ModalContactus showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

PostDetail.propTypes = {
  bannerAccepted: PropTypes.bool,
  setShowCookieConsent: PropTypes.func,
}

export default PostDetail
