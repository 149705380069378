import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import CardsWithIcon from '../../../common/display/CardsWithIcon/CardsWithIcon'

import computer from '../../../../assets/images/computer.svg'
import rightArrow from '../../../../assets/images/arrowRight.svg'
import leftArrow from '../../../../assets/images/arrowLeft.svg'
import userIcon from '../../../../assets/images/userIcon.svg'
import manStars from '../../../../assets/images/manStars.svg'

const socialRecruitingContent = [
  {
    card: 'cardOne',
  },
  {
    card: 'cardTwo',
  },
  {
    card: 'cardThree',
  },
]

const ConnectedCards = () => {
  return (
    <div className='section min-h-[823px] pb-10 sm:pb-0'>
      <h2>
        <Msg id='socialMediaRecruiting.socialrecrutingcards.title' />
      </h2>
      <div className='flex flex-col lg:gap-4 gap-10'>
        <div className='flex lg:justify-start justify-center lg:mb-0 xl:-mb-2'>
          <CardsWithIcon
            image={computer}
            content={socialRecruitingContent[0]}
            extraClass='mr-10'
            paragraphClass='w-[375px]'
            imageWidthDesktop='w-[128px] h-[86px] xl:w-[125px] xl:h-[83px]'
          />
          <div className='hidden lg:block lg:mt-[110px] xl:mt-[85px] ml-4 -mb-4'>
            <img
              src={rightArrow}
              alt='rightArrow'
              title='rightArrow'
              loading='lazy'
              className='h-auto xl:w-full lg:w-40'
            />
          </div>
        </div>
        <div className='flex lg:justify-end justify-center'>
          <CardsWithIcon
            image={userIcon}
            content={socialRecruitingContent[1]}
            extraClass='mr-16 -mt-8'
            paragraphClass='w-[350px]'
            imageWidthDesktop='w-[117px] h-[68px]'
          />
        </div>
        <div className='flex lg:justify-start justify-center xl:-mt-2'>
          <CardsWithIcon
            image={manStars}
            content={socialRecruitingContent[2]}
            extraClass='mr-20 -mt-3'
            paragraphClass='w-[350px]'
            imageWidthDesktop='w-[107px] h-[83px]'
          />
          <div className='hidden lg:block ml-2 xl:ml-0 xl:mt-1 '>
            <img
              src={leftArrow}
              alt='leftArrow'
              title='leftArrow'
              loading='lazy'
              className='h-auto xl:w-full lg:w-40'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectedCards
