import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'

const Card = ({ image, name, page }) => {
  return (
    <div
      data-testid={`${name}`}
      className='box-border p-4 rounded-lg bg-white w-[240px] md:w-[340px] x:w-[400px] overflow-hidden shadow-lg flex flex-col items-center'
    >
      <img className='w-8/12 md:w-9/12 x:w-full' src={image} alt='showcase' />
      <div className='px-6 md:px-4 py-4 text-center'>
        <h2 className='font-semibold mb-2 text-blue text-center text-[22px] md:text-[26px] x:text-[32px] leading-[32px] md:leading-[38px]'>
          <Msg id={`${page}.showCases.${name}.heading`} defaultMessage='showCase1' />
        </h2>
        <p className='px-2 text-black text-semibold  text-[16px] md:text-[18px] x:text-[20px] leading-[30px] md:leading-[34px]'>
          <Msg id={`${page}.showCases.${name}.text`} defaultMessage='Heraklit' />
        </p>
      </div>
    </div>
  )
}
Card.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  page: PropTypes.string,
}

export default Card
