import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import { InlineWidget } from 'react-calendly'
import PropTypes from 'prop-types'

import contactUsNic from '../../../../assets/images/contactUsNic.webp'
import './ContactUs.css'

const ContactUs = ({ hasAcceptedFunctionalCookie, name }) => (
  <div className='section-without-padding' id='contactus'>
    <h2>
      <Msg id='socialMediaRecruiting.contactUs.title' defaultMessage='Contact' />
    </h2>
    <div className='grid '>
      <div className='flex justify-center px-[35px] lg:px-[125px] mb-5 lg:mb-10'>
        <div className='grid rounded-2xl bg-secondary drop-shadow-xl justify-items-center md:grid-cols-4 gap-4 md:pl-[80.5px] md:pr-[10px] py-[22px] sm:py-[40px] place-content-center md:h-[217px] px-[34.5px] sm:px-[18.15972222%] max-w-[917px] object-center'>
          <img
            src={contactUsNic}
            alt='Nicolas Welitzki'
            className='w-[125px] h-[125px] rounded-full'
            title='Nicolas Welitzki'
          />
          <div className='not-italic font-semibold text-base md:text-[22px] leading-8 grid content-center md:col-span-3 text-center md:text-left'>
            “
            <Msg id={`${name}.contactUs.details`} defaultMessage='Learn more...' />
            .“
          </div>
        </div>
      </div>
      {hasAcceptedFunctionalCookie && (
        <div
          className='w-full px-0 lg:px-[125px] calendly-contact-us'
          data-testid='calendly'
          id='calendlySection'
        >
          <InlineWidget
            url='https://calendly.com/welitzki-it/30min'
            pageSettings={{ hideGdprBanner: true }}
          />
        </div>
      )}
    </div>
  </div>
)

ContactUs.propTypes = {
  hasAcceptedFunctionalCookie: PropTypes.bool,
  name: PropTypes.string,
}

export default ContactUs
