import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const PostCard = ({ title, excerpt, category, slug, coverImageURL }) => {
  return (
    <>
      <Link to={`/post/${slug}`}>
        <div
          data-testid='post-card'
          className='mx-auto post__card w-[290px] h-[544px] md:w-[365px] md:h-[538px]'
        >
          <div className='px-[25px] md:px-[33px] pt-[23px] md:pt-[27px]'>
            <img
              src={coverImageURL}
              alt='post cover'
              className='w-full max-w-[237px] h-auto md:max-w-[299px] md:max-h-[178px] object-contain'
            />
          </div>

          <div className='pt-[10px] md:pt-[19px]'>
            <p className='mx-auto bg-blue w-[100px] h-[30px] not-italic font-medium text-xs leading-[18px] text-center md:text-sm md:leading-[21px] text-white pt-1'>
              {category}
            </p>
          </div>
          <h3
            className='not-italic font-bold text-lg leading-[27px] text-center text-black px-[25px] md:px-[33px] pt-4 md:pt-7'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <p
            className='italic font-medium text-sm leading-[21px] text-center text-black px-[25px] md:px-[33px] pt-6 md:pt-7'
            dangerouslySetInnerHTML={{
              __html: excerpt,
            }}
          />
        </div>
      </Link>
    </>
  )
}

PostCard.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  category: PropTypes.string,
  slug: PropTypes.string,
  coverImageURL: PropTypes.string,
}

export default PostCard
