import React from 'react'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
/*import { convertHtmlToReact as toHTML } from '@hedgedoc/html-to-react'*/

import user from '../../../assets/images/main/user.svg'
import man from '../../../assets/images/main/man.svg'
import nicImage from '../../../assets/images/main/aboutUsNic.webp'
import nicImageMobile from '../../../assets/images/main/aboutUsNicMobile.webp'
import degree from '../../../assets/images/main/degree.svg'
import Button from '../../common/inputs/Button/Button'
import SocialIcons from '../../common/display/SocialIcons/SocialIcons'
import PropTypes from 'prop-types'
import { trackEvent } from '../../../matomo'

const AboutUsNic = ({ setShowCalendlyContactForm, categoryName }) => {
  const HtmlToReactParser = require('html-to-react').Parser
  const htmlToReactParser = new HtmlToReactParser()
  const intl = useIntl()
  return (
    <div id='ueber-uns' className='section'>
      <h2>
        <Msg id='company.aboutUsNic.title' />
      </h2>
      <div className='flex flex-col x:flex-row gap-[11px] x:gap-[67px] items-center'>
        <div className='mx-auto x:mx-0'>
          <div className='w-[251px] h-[274px] md:w-[345px] md:h-[481px] x:h-[581px]'>
            <img
              src={nicImage}
              alt='nic'
              title='nic'
              className='w-full h-full hidden md:block'
              loading='lazy'
            />
            <img
              src={nicImageMobile}
              alt='nic'
              title='nic'
              className='w-full h-full block md:hidden'
              loading='lazy'
            />
          </div>
          <div className='mx-auto hidden x:block pt-[11px]'>
            <SocialIcons categoryName={categoryName} eventName={'Home'} />
          </div>
        </div>

        <div className='flex flex-col gap-5'>
          <h3 className='not-italic font-bold text-lg x:text-2xl leading-[27px] x:leading-9 mx-auto x:ml-[-20px]'>
            <Msg id='company.aboutUsNic.name' />
          </h3>
          <ul className='list-disc aboutus__detail__onLarge w-full x:max-w-[600px] xl:max-w-[742px] not-italic font-normal text-base leading-[27.2px] text-textPrimary hyphenateManual'>
            {htmlToReactParser.parse(
              intl.formatMessage({
                id: 'company.aboutUsNic.detail',
                defaultMessage: 'About Nic',
              })
            )}
          </ul>
          <div className='aboutus__detail__onSmall flex flex-col gap-6 leading-[22px] min-w[254px] mx-auto px-10'>
            <ul className='list-disc'>
              {htmlToReactParser.parse(
                intl.formatMessage({
                  id: 'company.aboutUsNic.detail',
                  defaultMessage: 'About Nic',
                })
              )}{' '}
            </ul>
          </div>
          <p className='not-italic font-semibold text-base md:ml-[-20px] mx-auto md:mx-0'>
            <Msg id='company.aboutUsNic.freeTime' />
          </p>

          <div className='flex flex-col gap-[17px] min-w[254px] md:ml-[-20px] mx-auto md:mx-0'>
            <div className='flex gap-[25px] items-center'>
              <div className='w-8'>
                <img src={user} alt='user' title='user' className='w-[28px] h-[32px]' />
              </div>
              <p className='leading-[24px] not-italic font-semibold text-base text-blue w-[180px] sm:w-full'>
                <Msg id='company.aboutUsNic.freeTimeOne' />
              </p>
            </div>
            <div className='flex gap-[21px] items-center'>
              <div className='w-9'>
                <img src={degree} alt='degree' title='degree' className='w-[32px] h-[23px]' />
              </div>
              <p className='leading-[24px] not-italic font-semibold text-base text-blue w-[196px] sm:w-full'>
                <Msg id='company.aboutUsNic.freeTimeTwo' />
              </p>
            </div>
            <div className='flex gap-[25px] items-center'>
              <div className='w-8'>
                <img src={man} alt='man' title='man' className='w-[24px] h-[36px]' />
              </div>
              <p className='leading-[24px] not-italic font-semibold text-base text-blue w-[196px] sm:w-full'>
                <Msg id='company.aboutUsNic.freeTimeThree' />
              </p>
            </div>
          </div>
          <div className='md:ml-[-20px] mx-auto md:mx-0'>
            <Button
              onClick={() => {
                trackEvent('Home Page', 'Click', 'home_nic_aboutus_btn_click')
                setShowCalendlyContactForm(true)
              }}
              extraClasses='font-semibold text-blue mx-auto ti:mx-0 x:mx-0 h-[51px]'
              withIcon={true}
              transparent={false}
              text='consultation'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

AboutUsNic.propTypes = {
  setShowCalendlyContactForm: PropTypes.func,
  categoryName: PropTypes.string,
}

export default AboutUsNic
