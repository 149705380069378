import React from 'react'

const SoftwareDevelopment = () => (
  <div className='page-wrapper'>
    <h1>Software Development</h1>
    <p>A new website will soon be created here.</p>
  </div>
)

export default SoftwareDevelopment
