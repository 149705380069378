import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const ScrollToView = () => {
  const location = useLocation()

  useEffect(() => {
    const handleLoad = () => {
      const { hash } = location
      const id = hash.replace('#', '')
      document.getElementById(id)?.scrollIntoView()
    }

    if (location.hash) {
      window.addEventListener('load', handleLoad)
    } else {
      window.scrollTo(0, 0)
    }

    return () => {
      if (location.hash) {
        window.removeEventListener('load', handleLoad)

        window.scrollTo(0, -10)
      }
    }
  }, [location])

  return null
}
export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
