import React from 'react'
import Carousel from 'react-spring-3d-carousel'
import { useState, useEffect } from 'react'
import { config } from 'react-spring'
import PropTypes from 'prop-types'

export default function Carroussel(props) {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) }
  })

  const [offsetRadius, setOffsetRadius] = useState(2)
  const [showArrows, setShowArrows] = useState(true)
  const [goToSlide, setGoToSlide] = useState(0)
  const [cards] = useState(table)

  useEffect(() => {
    setOffsetRadius(props.offset)
    setShowArrows(props.showArrows)
  }, [props.offset, props.showArrows])

  return (
    <div className={`relative my-0 mx-auto ${props.height}`} style={{ width: props.width }}>
      <button
        className='bg-blueLight text-white text-[25px] w-[35px] h-[35px] ti:hidden block absolute top-2/4 left-0 z-10 rounded-full'
        onClick={() =>
          setGoToSlide((prev) => {
            if (prev === 0) {
              return cards.length - 1
            }
            return prev - 1
          })
        }
      >
        &lt;
      </button>
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
      <button
        className='bg-blueLight text-white text-[25px] w-[35px] h-[35px] ti:hidden block absolute top-2/4 right-0 z-10 rounded-full'
        onClick={() =>
          setGoToSlide((prev) => {
            if (prev === cards.length - 1) {
              return 0
            }
            return prev + 1
          })
        }
      >
        &gt;
      </button>
    </div>
  )
}

Carroussel.propTypes = {
  cards: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
  offset: PropTypes.number,
  showArrows: PropTypes.bool,
}
