import React from 'react'
import PropTypes from 'prop-types'
import ErrorRed from '../../../../assets/images/errorRed.png'

const TextArea = ({ id, mandatory, value, placeholder, onChange, errorMsg }) => {
  return (
    <div>
      <textarea
        id={id}
        rows='1'
        name={id}
        value={value}
        required={mandatory}
        placeholder={`${placeholder}${mandatory ? ' *' : ''}`}
        onChange={(e) => onChange(e.target.value)}
        className={`form-input rounded-xl bg-formBG min-h-[245px] px-3 py-2 w-full placeholder-textPrimary ${
          errorMsg ? 'border-red' : 'border-none'
        }`}
      ></textarea>
      {errorMsg && (
        <span className='text-red flex pt-1'>
          <img src={ErrorRed} className='h-7 pr-1' />
          <p className='pt-1'>{errorMsg}</p>
        </span>
      )}{' '}
    </div>
  )
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

TextArea.displayName = 'TextArea'

export default TextArea
