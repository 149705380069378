import React, { useEffect } from 'react'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
import CookieNotice from '@shamaz332/react-gdpr'
import PropTypes from 'prop-types'
import { ESSENTIAL, FUNCTIONAL, MARKETING, STATISTICS } from '../../../../domain/cookieManager'

export const cookiePrefix = 'nwit-cookie-'
const cookieStyles = { left: '0', backgroundColor: '#2461FF', opacity: 1 }
const buttonPrimaryStyles = {
  backgroundColor: '#fff',
  color: '#2461FF',
  borderRadius: '6px',
  padding: '10px',
  textAlign: 'center',
}
const buttonSecondaryStyle = {
  ...buttonPrimaryStyles,
  textDecoration: 'none',
  textAlign: 'center',
}

const CookieConsent = ({ setCookies, toggleVisibility, setBannerAccepted }) => {
  const intl = useIntl()
  const cookiesDefinition = [
    {
      name: ESSENTIAL,
      checked: true,
      editable: false,
      default: true,
      title: intl.formatMessage({
        id: 'socialMediaRecruiting.contactUs.essentialCookie',
        defaultMessage: 'Essential',
      }),
    },
    {
      name: MARKETING,
      checked: false,
      editable: true,
      title: intl.formatMessage({
        id: 'socialMediaRecruiting.contactUs.marketingCookie',
        defaultMessage: 'Marketing',
      }),
    },
    {
      name: STATISTICS,
      checked: false,
      editable: true,
      title: intl.formatMessage({
        id: 'socialMediaRecruiting.contactUs.statisticsCookie',
        defaultMessage: 'Statistics',
      }),
    },
    {
      name: FUNCTIONAL,
      checked: false,
      editable: true,
      title: intl.formatMessage({
        id: 'socialMediaRecruiting.contactUs.functionalCookie',
        defaultMessage: 'Functional',
      }),
    },
  ]
  useEffect(() => {
    const isBannerAccepted = localStorage.getItem('cookieBannerAccepted')
    if (isBannerAccepted) {
      setBannerAccepted(true)
    }
  }, [setBannerAccepted])
  const handleSave = (cookies) => {
    setCookies(cookies)
    setBannerAccepted(true)
    localStorage.setItem('cookieBannerAccepted', 'true')
  }
  return (
    <div data-testid='cookie-notice'>
      <CookieNotice
        acceptAllButtonText={intl.formatMessage({
          id: 'socialMediaRecruiting.contactUs.acceptButton',
          defaultMessage: 'Accept all',
        })}
        preferencesButtonText={intl.formatMessage({
          id: 'socialMediaRecruiting.contactUs.openPreferencesButton',
          defaultMessage: 'Cookie preferences',
        })}
        savePreferencesButtonText={intl.formatMessage({
          id: 'socialMediaRecruiting.contactUs.saveCookieSettingsButton',
          defaultMessage: 'Save on Computer',
        })}
        cookiePrefix={cookiePrefix}
        containerStyle={cookieStyles}
        buttonPrimaryStyle={buttonPrimaryStyles}
        buttonSecondaryStyle={buttonSecondaryStyle}
        onInit={(cookies) => {
          setCookies(cookies)
        }}
        onSave={handleSave}
        cookies={cookiesDefinition}
        toggleVisibility={toggleVisibility}
        linkOne='impressum'
        linkTwo='datenschutz'
        linkOneText={intl.formatMessage({
          id: 'common.button.imprint',
          defaultMessage: 'Imprint',
        })}
        linkTwoText={intl.formatMessage({
          id: 'common.button.privacy',
          defaultMessage: 'Privacy',
        })}
      >
        <div className='my-2'>
          <Msg id='socialMediaRecruiting.contactUs.acceptMessage' />
        </div>
      </CookieNotice>
    </div>
  )
}

CookieConsent.propTypes = {
  setCookies: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.bool.isRequired,
  setBannerAccepted: PropTypes.func,
}

export default CookieConsent
