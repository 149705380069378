import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'

import why from '../../../../assets/images/webDesign/why.webp'
import why_us from '../../../../assets/images/why_us.svg'
import checkmark from '../../../../assets/images/webDesign/checkmark.svg'

const Why = ({ name, desc }) => {
  let data = [
    {
      title: `${name}.why.firstText`,
      defaultMessage: 'Seems serious and professional',
    },
    {
      title: `${name}.why.secondText`,
      defaultMessage: 'Often the first customer contact',
    },
    {
      title: `${name}.why.thirdText`,
      defaultMessage: 'Shows who you are and what you offer',
    },
    {
      title: `${name}.why.fourthText`,
      defaultMessage: 'Attractive websites increase the chance to attract customers',
    },
    {
      title: `${name}.why.fifthText`,
      defaultMessage: 'Competition is just a click away',
    },
  ]

  let data1 = [
    {
      title: `${name}.why.firstText`,
      defaultMessage: 'Seems serious and professional',
    },
    {
      title: `${name}.why.secondText`,
      defaultMessage: 'Often the first customer contact',
    },
    {
      title: `${name}.why.thirdText`,
      defaultMessage: 'Shows who you are and what you offer',
    },
    {
      title: `${name}.why.fourthText`,
      defaultMessage: 'Attractive websites increase the chance to attract customers',
    },
  ]

  return (
    <div className={`${name === 'webDevelopment' ? '' : 'bg-gprimary1'} pb-[50px] section`}>
      <h2>
        <Msg id={`${name}.why.heading`} defaultMessage='Why a modern website is important' />
      </h2>
      <div className='flex flex-col x:flex-row items-center gap-10'>
        {name === 'webDesign' ? (
          <img
            className='mi:w-[291px] mi:h-[221px] lg:w-[595px] lg:h-[397px] mx-auto x:mx-0 my-0 py-0'
            src={why}
            alt='Why a modern website is important'
            title='Why a modern website is important'
            loading='lazy'
          />
        ) : (
          <img
            className='mi:w-[291px] mi:h-[221px] lg:w-[595px] lg:h-[397px] mx-auto x:mx-0 my-0 py-0'
            src={why_us}
            alt='Why we'
            title='Why we'
            loading='lazy'
          />
        )}

        <div className='flex items-center'>
          <div className='flex flex-col'>
            {desc && (
              <p className='not-italic text-base md:text-[26px] leading-[27px] lg:leading-[34px] p-2 lg:p-0 text-textPrimary w-full mb-10'>
                <Msg
                  id={`${name}.why.heading2`}
                  defaultMessage='Never before has a modern website been as important as it is today.'
                />
              </p>
            )}

            {name === 'webDesign' ? (
              <div className='not-italic text-sm md:text-base leading-[27px] lg:leading-[34px] p-2 lg:p-0 text-textPrimary w-full flex flex-col gap-5'>
                {data.map((data, index) => (
                  <React.Fragment key={index}>
                    <div className='flex flex-row items-center'>
                      <img
                        className='inline mr-5 h-[30px] w-[30px]'
                        src={checkmark}
                        alt='Icon'
                        title='Icon'
                        loading='lazy'
                      />
                      <Msg id={data.title} defaultMessage={data.defaultMessage} />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div className='not-italic text-sm md:text-base leading-[27px] lg:leading-[34px] p-2 lg:p-0 text-textPrimary w-full flex flex-col gap-5'>
                {data1.map((data, index) => (
                  <React.Fragment key={index}>
                    <div className='flex flex-row items-center'>
                      <img
                        className='inline mr-5 h-[30px] w-[30px]'
                        src={checkmark}
                        alt='Icon'
                        title='Icon'
                        loading='lazy'
                      />
                      <Msg id={data.title} defaultMessage={data.defaultMessage} />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Why.propTypes = {
  name: PropTypes.string,
  desc: PropTypes.bool,
}

export default Why
