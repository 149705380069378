import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
import { scroller } from 'react-scroll'
import Button from '../../inputs/Button/Button'
import { trackEvent } from '../../../../matomo'
/*import { convertHtmlToReact as toHTML } from '@hedgedoc/html-to-react'*/

const HowWeWorkCard = ({ card, image, number, button, extraClass, name, categoryName }) => {
  const HtmlToReactParser = require('html-to-react').Parser
  const htmlToReactParser = new HtmlToReactParser()
  const intl = useIntl()
  const eventTracking = () => {
    let eventName
    if (categoryName === 'WebDesign Page') {
      eventName = 'wdesign_howwework_btn_click'
    } else if (categoryName === 'SocialMediaRecruiting Page') {
      eventName = 'smr_howwework_btn_click'
    } else {
      eventName = 'other_howwework_btn_click'
    }
    trackEvent(categoryName, 'click', eventName)
  }
  return (
    <div className='bg-secondary rounded-3xl howwework__card w-[291px] h-[380px] ti:h-[380px] sm:w-[554px] sm:h-[263px] relative mx-auto'>
      <div className=' bg-blue rounded-full absolute -left-4 -top-6 sm:-left-5 sm:-top-6 md:-left-8 md:-top-8 border-[6px] border-white'>
        <span className=' text-white md:h-[49px] w-[32px] h-[32px] md:w-[49px] text-[24px] md:text-[32px] flex justify-center items-center'>
          {number}
        </span>
      </div>
      <h3 className='pl-[18px] sm:pl-[35px] pt-[18px] sm:pt-[35px] text-[20px] sm:text-[22px] leading-[27px] sm:leading-[33px] pb-3 w-full hidden sm:block'>
        <Msg id={`${name}.howwework.${card}.title`} />
      </h3>
      <div className='flex flex-col-reverse justify-end sm:justify-between gap-0 sm:gap-5 sm:flex-row'>
        <div className='pl-[18px] sm:pl-[35px] pt-[18px] sm:pt-[0px]'>
          <span className='h3 text-[20px] sm:text-[22px] leading-[27px] sm:leading-[33px] max-w-[300px] pb-3 block sm:hidden'>
            <Msg id={`${name}.howwework.${card}.title`} />
          </span>
          <p className='text-[16px] not-italic font-normal leading-[25.2px] sm:[30px] max-w-[250px] md:max-w-[300px] pb-5'>
            {htmlToReactParser.parse(
              intl.formatMessage({
                id: `${name}.howwework.${card}.description`,
                defaultMessage: 'description',
              })
            )}
          </p>
          {button && (
            <Button
              extraClasses='font-semibold text-white h-[43px]'
              withIcon={true}
              transparent={false}
              text='howwework'
              onClick={() => {
                eventTracking()
                scroller.scrollTo('contactus', {
                  duration: 150,
                  delay: 100,
                  smooth: true,
                })
              }}
            />
          )}
        </div>
        <div className='flex justify-center items-center sm:pr-[52px] pt-[18px] sm:-mt-[50px]'>
          <img
            src={image}
            alt='How we work card icons'
            title='How we work card icons'
            loading='lazy'
            className={`${extraClass} `}
          />
        </div>
      </div>
    </div>
  )
}

HowWeWorkCard.propTypes = {
  card: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  button: PropTypes.bool.isRequired,
  number: PropTypes.number.isRequired,
  extraClass: PropTypes.string,
  name: PropTypes.string,
  categoryName: PropTypes.string,
}
export default HowWeWorkCard
