import { useState, useEffect } from 'react'
import api from '../api'

const usePosts = (perPage = 6, currentPage = 1) => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true)
      try {
        const response = await api.get('/posts', {
          params: {
            per_page: perPage,
            page: currentPage,
            _embed: true,
          },
        })

        const categoryIds = response.data.map((post) => post.categories).flat()
        const categoryResponse = await api.get('/categories', {
          params: {
            include: categoryIds.join(','),
          },
        })
        const categories = {}
        categoryResponse.data.forEach((category) => {
          categories[category.id] = category.name
        })

        const formattedPosts = response.data.map(async (post) => {
          if (post.featured_media) {
            const mediaResponse = await api.get(`/media/${post.featured_media}`)
            post.featuredImageURL = mediaResponse.data.source_url
          } else {
            post.featuredImageURL = null
          }

          return {
            ...post,
            _embedded: {
              ...post._embedded,
              category: post.categories.map((categoryId) => {
                return {
                  id: categoryId,
                  name: categories[categoryId],
                }
              }),
            },
          }
        })

        // Since fetching media is asynchronous, we need to wait for all the requests to complete
        const allFormattedPosts = await Promise.all(formattedPosts)
        setPosts(allFormattedPosts)
        setLoading(false)
        setTotalPages(parseInt(response.headers['x-wp-totalpages']))
      } catch (error) {
        setError(error)
        setLoading(false)
      }
    }

    fetchPosts()
  }, [perPage, currentPage])

  return { posts, loading, error, totalPages }
}

export default usePosts
