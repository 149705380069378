import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'

const Collapse = ({ faqOrder, isExpand, name }) => {
  const [expand, setExpand] = useState(isExpand)

  return (
    <div
      className={`h-auto w-full p-5 lg:p-9 flex flex-col rounded-2xl ${
        name === 'socialMediaRecruiting' ? 'bg-[#FAFCFF]' : 'product__card'
      } shadow-sm`}
    >
      <div className='flex justify-between items-center md:items-start'>
        <div className='flex items-center gap-2'>
          <h3 className='leading-[21px] lg:leading-[33px] not-italic font-semibold text-sm lg:text-[22px] text-blueLight pr-4'>
            <Msg id={`${name}.faq.${faqOrder}.question`} defaultMessage='Question' />
          </h3>
        </div>
        <button
          data-testid='openfaq'
          onClick={() => setExpand(!expand)}
          aria-label='Open Collapse'
          className='h-[19px] w-[19px] lg:h-[30px] lg:w-[30px]'
        >
          {expand ? (
            <svg
              viewBox='0 0 30 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='w-[19px] md:w-[30px] h-[19px] md:h-[30px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15 30C23.2841 30 30 23.2841 30 15C30 6.71591 23.2841 0 15 0C6.71591 0 0 6.71591 0 15C0 23.2841 6.71591 30 15 30ZM19.4905 18.0095C19.7476 18.2579 20.0921 18.3954 20.4496 18.3923C20.8072 18.3892 21.1492 18.2458 21.402 17.9929C21.6549 17.7401 21.7983 17.3981 21.8014 17.0405C21.8045 16.683 21.667 16.3385 21.4186 16.0814L15.9641 10.6268C15.7084 10.3712 15.3616 10.2276 15 10.2276C14.6384 10.2276 14.2916 10.3712 14.0359 10.6268L8.58136 16.0814C8.33297 16.3385 8.19552 16.683 8.19863 17.0405C8.20173 17.3981 8.34515 17.7401 8.59797 17.9929C8.8508 18.2458 9.19282 18.3892 9.55036 18.3923C9.9079 18.3954 10.2524 18.2579 10.5095 18.0095L15 13.5191L19.4905 18.0095Z'
                fill='#2461FF'
              />
            </svg>
          ) : (
            <svg
              viewBox='0 0 31 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='w-[19px] md:w-[30px] h-[19px] md:h-[30px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.9697 -0.000977874C7.68564 -0.00097715 0.969727 6.71493 0.969728 14.999C0.969729 23.2831 7.68564 29.999 15.9697 29.999C24.2538 29.999 30.9697 23.2831 30.9697 14.999C30.9697 6.71493 24.2538 -0.000978598 15.9697 -0.000977874ZM11.4793 11.9895C11.2221 11.7411 10.8776 11.6036 10.5201 11.6067C10.1625 11.6098 9.82053 11.7533 9.5677 12.0061C9.31487 12.2589 9.17146 12.6009 9.16835 12.9585C9.16525 13.316 9.30269 13.6605 9.55109 13.9177L15.0056 19.3722C15.2614 19.6278 15.6081 19.7715 15.9697 19.7715C16.3313 19.7715 16.6781 19.6278 16.9338 19.3722L22.3884 13.9177C22.6368 13.6605 22.7742 13.316 22.7711 12.9585C22.768 12.6009 22.6246 12.2589 22.3718 12.0061C22.1189 11.7533 21.7769 11.6098 21.4194 11.6067C21.0618 11.6036 20.7174 11.7411 20.4602 11.9895L15.9697 16.4799L11.4793 11.9895Z'
                fill='#2461FF'
              />
            </svg>
          )}
        </button>
      </div>
      {expand && (
        <div
          data-testid='expandedtext'
          className='whitespace-pre-line not-italic font-normal text-sm lg:text-base text-textSecondary leading-7 pr-4 mt-4'
        >
          <Msg id={`${name}.faq.${faqOrder}.answer`} defaultMessage='Question' />
        </div>
      )}
    </div>
  )
}

Collapse.propTypes = {
  faqOrder: PropTypes.string,
  isExpand: PropTypes.bool,
  name: PropTypes.string,
}

export default Collapse
