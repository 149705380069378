import React, { useState } from 'react'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import * as emailjs from '@emailjs/browser'
import newLetterImage from '../../../../assets/images/main/newsLetterrs.svg'
import TextInput from '../../inputs/TextInput/TextInput'
import Button from '../../inputs/Button/Button'
import { Link } from 'react-router-dom'
import { trackEvent } from '../../../../matomo'

const NewsletterCTA = ({ variant, categoryName, blog, bg }) => {
  const intl = useIntl()
  const [state, setState] = useState({
    name: '',
    email: '',
    nameErrorText: '',
    emailErrorText: '',
    isSubmitted: false,
    status: 'NOT_DISPATCHED',
  })

  const eventPrefixes = {
    'Home Page': 'home_',
    'WebDesign Page': 'wdesign_',
    'SocialMediaRecruiting Page': 'smr_',
    'WebDevelopment Page': 'wdev_',
  }

  const eventName = eventPrefixes[categoryName] || ''

  const validate = (property, value) => {
    const testEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$/
    if (property === 'name') {
      if (value.length < 5) {
        return intl.formatMessage({ id: 'newsLetter.nameError', defaultMessage: 'Enter name' })
      }
    }
    if (property === 'email') {
      if (value.length < 1) {
        return intl.formatMessage({
          id: 'newsLetter.emptyEmailErr',
          defaultMessage: 'Please enter your email address.',
        })
      }
      if (!testEmail.test(value)) {
        return intl.formatMessage({
          id: 'newsLetter.InvalidEmailErr',
          defaultMessage: 'Please enter a valid email address.',
        })
      }
    }
  }

  const onChange = (property, value) =>
    setState({ ...state, [property]: value, [`${property}ErrorText`]: validate(property, value) })
  const onClick = () => {
    const emailErrorText = validate('email', state.email)
    const nameErrorText = validate('name', state.name)
    const isValid =
      !(nameErrorText || emailErrorText) &&
      (process.env.REACT_APP_EMAIL_DISABLED ?? false) === false
    setState({
      ...state,
      nameErrorText,
      emailErrorText,
      isSubmitted: isValid,
      ...(isValid && { loading: true }),
    })
    isValid &&
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          {
            from_name: state.name,
            to_name: 'Nicolas Welitzki IT-Consulting',
            message: `Newsletter subscription from ${state.name} with email ${state.email}`,
            reply_to: state.email,
          },
          process.env.REACT_APP_PUBLIC_KEY
        )

        .then((result) => {
          trackEvent(categoryName, 'Submit', `${eventName}newsletter__btn_submit`)
          if (result.status === 200) {
            setState({
              ...state,
              loading: false,
              status: 'SHIPPED',
            })
          }
          if (result.status === 400) {
            setState({
              ...state,
              loading: false,
              status: 'ERROR',
            })
          }
        })
        .catch(() => setState({ ...state, loading: false, name: '', email: '', status: 'ERROR' }))
  }
  return (
    <div id='newsletter' className={`${bg === 'white' ? '' : 'mb-10'} `}>
      <div
        className={`${
          variant === 'secondary' ? 'bg-blue' : 'bg-gprimary1'
        } py-8 grid grid-cols-12 rounded-[20px] gap-5 h-auto xl:min-h-[274px] w-auto x:w-[1190px] mx-[35px] ti:mx-10 sm:mx-16 x:mx-auto mt-10 lg:mt-[70px] ${
          blog && 'blog__news_letter mb-[60px]'
        } `}
      >
        <div
          className={`hidden lg:block col-span-3 lg:col-span-0 mx-auto ${
            variant === 'secondary' ? 'text-white' : ''
          }`}
        >
          <img
            src={newLetterImage}
            alt='newLetter'
            className='w-[216px] h-[180px]'
            title='newLetter'
          />
        </div>
        <div className='col-span-12 lg:col-span-9'>
          <h2
            className={`text-[20px] lg:text-[32px] font-semibold text-center lg:text-left pb-2 px-5 lg:px-0 ${
              variant === 'secondary' ? 'text-white' : 'text-blueLight'
            }`}
          >
            <Msg id='newsLetter.title' defaultMessage='title' />
          </h2>
          <p
            className={`text-sm font-medium text-center lg:text-left pb-[15px] px-5 lg:pl-0 lg:pr-5 ${
              variant === 'secondary' ? 'text-white' : 'text-blueLight'
            }`}
          >
            <span>
              <Msg id='newsLetter.description' defaultMessage='description' />
            </span>
            <Link to='/datenschutz' className='underline' title='Privacy'>
              <Msg id='newsLetter.privacyText' defaultMessage='privacyText' />
            </Link>
          </p>

          <div className='grid grid-cols-12 float-left w-[100%]'>
            <div className='col-span-12 lg:col-span-6 flex flex-col gap-4 max-w-[250px] ms:max-w-[367px] w-full mx-auto lg:mx-0 float-left'>
              <TextInput
                id='name-newsletter'
                value={state.name}
                placeholder={intl.formatMessage({
                  id: 'newsLetter.placeholder.name',
                })}
                mandatory={true}
                type='text'
                onChange={(value) => onChange('name', value)}
                errorMsg={state.nameErrorText}
                extraClasses='placeholder-blue'
                secondVariant={variant === 'secondary'}
              />
              <TextInput
                id='email-newsletter'
                value={state.email}
                placeholder={intl.formatMessage({
                  id: 'newsLetter.placeholder.email',
                })}
                mandatory={true}
                type='email'
                onChange={(value) => onChange('email', value)}
                errorMsg={state.emailErrorText}
                extraClasses='placeholder-blue'
                secondVariant={variant === 'secondary'}
              />
            </div>
            <div className='col-span-12 lg:col-span-4 flex flex-col justify-center items-center'>
              <div className='mx-auto'>
                {state.status === 'SHIPPED' ? (
                  <Button
                    onClick={() => {
                      setState({
                        loading: false,
                        isSubmitted: false,
                        name: state.name,
                        email: state.email,
                        status: 'NOT_DISPATCHED',
                      })
                    }}
                    extraClasses={`font-semibold text-blue mx-auto mt-10 lg:mt-0 ${
                      variant === 'secondary' && 'bg-white text-blue'
                    }`}
                    withIcon={variant !== 'secondary'}
                    transparent={variant === 'secondary'}
                    text='newResponse'
                  />
                ) : state.status === 'ERROR' ? (
                  <Button
                    onClick={() => {
                      setState({
                        loading: false,
                        isSubmitted: false,
                        name: state.name,
                        email: state.email,
                        status: 'NOT_DISPATCHED',
                      })
                    }}
                    extraClasses={`font-semibold text-blue mx-auto mt-10 lg:mt-0 ${
                      variant === 'secondary' && 'bg-white text-blue'
                    }`}
                    withIcon={variant !== 'secondary'}
                    transparent={variant === 'secondary'}
                    text='tryAgain'
                  />
                ) : (
                  <>
                    <Button
                      onClick={() => onClick()}
                      extraClasses={`font-semibold text-blue mx-auto mt-10 lg:mt-0 ${
                        variant === 'secondary' && 'bg-white text-blue'
                      }`}
                      withIcon={variant !== 'secondary'}
                      transparent={variant === 'secondary'}
                      text={state.loading ? 'loading' : 'signUp'}
                    />
                    <div className='flex items-center justify-center'>
                      <span
                        className={`font-medium leading-[21px] whitespace-pre-line mt-[10px] text-sm ${
                          variant === 'secondary' ? 'text-white' : 'text-blueLight'
                        }`}
                      >
                        <Msg id='newsLetter.cancelBooking' defaultMessage='cancelBooking' />
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className='pt-4 lg:pt-2 px-10 lg:px-0 text-center'>
                {state.status === 'ERROR' && (
                  <div className={`${variant === 'secondary' ? 'text-white' : 'text-red'}`}>
                    <Msg id='newsLetter.error' />
                  </div>
                )}
                {state.status === 'SHIPPED' && (
                  <div className={`${variant === 'secondary' ? 'text-white' : 'text-blueLight'}`}>
                    <Msg id='newsLetter.success' />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NewsletterCTA.propTypes = {
  categoryName: PropTypes.string,
  variant: PropTypes.string,
  blog: PropTypes.bool,
  bg: PropTypes.string,
}

export default NewsletterCTA
