import React, { useEffect } from 'react'
import { FormattedMessage as Msg } from 'react-intl'

import dropDownArrow from '../../../../assets/images/dropDownArrow.svg'
import strategy01 from '../../../../assets/images/strategy01.svg'
import strategy02 from '../../../../assets/images/strategy02.svg'
import strategy03 from '../../../../assets/images/strategy03.svg'
import strategy04 from '../../../../assets/images/strategy04.svg'
import strategy05 from '../../../../assets/images/strategy05.svg'

const StrategySection = () => {
  useEffect(() => {
    const handleResize = () => {
      let width = window.innerWidth * 0.7510833333 + 'px'
      if (window.innerWidth < 768) {
        width = window.innerWidth * 0.8083333333 + 'px'
      }
      if (window.innerWidth > 1440) {
        width = 1440 * 0.7510833333 + 'px'
      }
      getID('strategy1').style.width = width
      getID('strategy2').style.width = width
      getID('strategy3').style.width = width
      getID('strategy4').style.width = width
      getID('strategy5').style.width = width
      getID('dropdownButton').style.width = width
    }

    handleResize()
    window.addEventListener('resize', handleResize, false)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getID = (x) => document.getElementById(x)
  const scrollFunc = (e) => {
    const pos = e.target.scrollLeft
    const path = e.target.scrollWidth - e.target.clientWidth
    const showDot = 'mx-1 p-1 md:mx-2 md:p-2 bg-blue rounded-full'
    const hideDot = 'mx-1 p-1 md:mx-2 md:p-2 bg-secondaryDark rounded-full'
    const showBar = 'decoration-4 decoration-blue underline underline-offset-8 cursor-pointer w-1/5'
    const hideBar = 'cursor-pointer w-1/5'
    if (pos < path * 0.2) {
      getID('strategyDot1').className = showDot
      getID('strategyDot2').className =
        getID('strategyDot3').className =
        getID('strategyDot4').className =
        getID('strategyDot5').className =
          hideDot
      getID('strategyBar1').className = showBar
      getID('strategyBar2').className =
        getID('strategyBar3').className =
        getID('strategyBar4').className =
        getID('strategyBar5').className =
          hideBar
    } else if (pos > path * 0.2 && pos < path * 0.4) {
      getID('strategyDot2').className = showDot
      getID('strategyDot1').className =
        getID('strategyDot3').className =
        getID('strategyDot4').className =
        getID('strategyDot5').className =
          hideDot
      getID('strategyBar2').className = showBar
      getID('strategyBar1').className =
        getID('strategyBar3').className =
        getID('strategyBar4').className =
        getID('strategyBar5').className =
          hideBar
    } else if (pos > path * 0.4 && pos < path * 0.6) {
      getID('strategyDot3').className = showDot
      getID('strategyDot2').className =
        getID('strategyDot1').className =
        getID('strategyDot4').className =
        getID('strategyDot5').className =
          hideDot
      getID('strategyBar3').className = showBar
      getID('strategyBar2').className =
        getID('strategyBar1').className =
        getID('strategyBar4').className =
        getID('strategyBar5').className =
          hideBar
    } else if (pos > path * 0.6 && pos < path * 0.8) {
      getID('strategyDot4').className = showDot
      getID('strategyDot2').className =
        getID('strategyDot3').className =
        getID('strategyDot1').className =
        getID('strategyDot5').className =
          hideDot
      getID('strategyBar4').className = showBar
      getID('strategyBar2').className =
        getID('strategyBar3').className =
        getID('strategyBar1').className =
        getID('strategyBar5').className =
          hideBar
    } else {
      getID('strategyDot5').className = showDot
      getID('strategyDot2').className =
        getID('strategyDot3').className =
        getID('strategyDot4').className =
        getID('strategyDot1').className =
          hideDot
      getID('strategyBar5').className = showBar
      getID('strategyBar2').className =
        getID('strategyBar3').className =
        getID('strategyBar4').className =
        getID('strategyBar1').className =
          hideBar
    }
  }
  const show = (x) => {
    getID('strategy' + x).scrollIntoView({ behavior: 'smooth', block: 'center' })
    getID('dropdownOptions').className = 'hidden'
  }
  const dropdown = (x) => {
    getID('dropDownName').innerHTML = x
  }

  return (
    <div className='section bg-secondary px-4' id='strategie'>
      <h2>
        <Msg id='socialMediaRecruiting.strategySection.title' defaultMessage='Unsere Strategie' />
      </h2>
      <div className='grid justify-center text-xl mx-auto pb-10 md:hidden'>
        <div
          id='dropdownButton'
          className='text-white bg-blue rounded-md text-sm px-4 py-2.5 items-center cursor-pointer w-full'
          onClick={() => {
            if (getID('dropdownOptions').className === 'hidden') {
              getID('dropdownOptions').className =
                'text-white bg-blue rounded-b-md text-sm px-4 pt-4 py-2.5 items-center cursor-pointer w-full -mt-2'
            } else {
              getID('dropdownOptions').className = 'hidden'
            }
          }}
          aria-label='dropdownButton'
        >
          <div className='inline-block' id='dropDownName'>
            <Msg
              id='socialMediaRecruiting.strategySection.first.title'
              defaultMessage='Ziel definieren'
            />
          </div>
          <img
            src={dropDownArrow}
            alt='Define goal'
            title='Define goal'
            loading='lazy'
            className='align-top ml-2 inline-block float-right h-[19px] w-[19px]'
          />
        </div>
        <div id='dropdownOptions' className='hidden' aria-label='dropdownOptions'>
          <div
            onClick={(e) => {
              show(1)
              dropdown(e.target.innerHTML)
            }}
            className='py-2'
            aria-label='dropdownOption1'
          >
            <Msg
              id='socialMediaRecruiting.strategySection.first.title'
              defaultMessage='Ziel definieren'
            />
          </div>
          <div
            onClick={(e) => {
              show(2)
              dropdown(e.target.innerHTML)
            }}
            className='py-2'
            aria-label='dropdownOption2'
          >
            <Msg
              id='socialMediaRecruiting.strategySection.second.title'
              defaultMessage='Vorteile ermitteln'
            />
          </div>
          <div
            onClick={(e) => {
              show(3)
              dropdown(e.target.innerHTML)
            }}
            className='py-2'
            aria-label='dropdownOption3'
          >
            <Msg
              id='socialMediaRecruiting.strategySection.third.title'
              defaultMessage='Recruiting-Strategie'
            />
          </div>
          <div
            onClick={(e) => {
              show(4)
              dropdown(e.target.innerHTML)
            }}
            className='py-2'
            aria-label='dropdownOption4'
          >
            <Msg
              id='socialMediaRecruiting.strategySection.fourth.title'
              defaultMessage='Bewerbungsprozess'
            />
          </div>
          <div
            onClick={(e) => {
              show(5)
              dropdown(e.target.innerHTML)
            }}
            className='py-2'
            aria-label='dropdownOption5'
          >
            <Msg
              id='socialMediaRecruiting.strategySection.fifth.title'
              defaultMessage='Langfristige Strategie'
            />
          </div>
        </div>
      </div>

      <div className='md:flex justify-around font-semibold  text-sm xl:text-xl lg:text-base mb-16 text-center hidden'>
        <div
          className='cursor-pointer w-1/5'
          onClick={() => show(1)}
          id='strategyBar1'
          aria-label='strategyBar1'
        >
          <Msg
            id='socialMediaRecruiting.strategySection.first.title'
            defaultMessage='Ziel definieren'
          />
        </div>
        <div
          className='cursor-pointer w-1/5'
          onClick={() => show(2)}
          id='strategyBar2'
          aria-label='strategyBar2'
        >
          <Msg
            id='socialMediaRecruiting.strategySection.second.title'
            defaultMessage='Vorteile ermitteln'
          />
        </div>
        <div
          className='cursor-pointer w-1/5'
          onClick={() => show(3)}
          id='strategyBar3'
          aria-label='strategyBar3'
        >
          <Msg
            id='socialMediaRecruiting.strategySection.third.title'
            defaultMessage='Recruiting-Strategie'
          />
        </div>
        <div
          className='cursor-pointer w-1/5'
          onClick={() => show(4)}
          id='strategyBar4'
          aria-label='strategyBar4'
        >
          <Msg
            id='socialMediaRecruiting.strategySection.fourth.title'
            defaultMessage='Bewerbungsprozess'
          />
        </div>
        <div
          className='cursor-pointer w-1/5'
          onClick={() => show(5)}
          id='strategyBar5'
          aria-label='strategyBar5'
        >
          <Msg
            id='socialMediaRecruiting.strategySection.fifth.title'
            defaultMessage='Langfristige Strategie'
          />
        </div>
      </div>

      <div
        className='flex gap-6 snap-x snap-mandatory overflow-x-auto px-14 pb-4'
        id='strategy'
        aria-label='strategy'
        onScroll={(e) => {
          scrollFunc(e)
        }}
      >
        <div
          className='grid grid-cols-5 gap-0 snap-center content-start shrink-0 w-16 rounded-2xl shadow-md h-[265px] p-4 sm:h-[360px] sm:p-16 mx-20 bg-white text-textPrimary'
          id='strategy1'
          aria-label='strategy1'
        >
          <div className='col-span-4 text-blue sm:mb-3.5 font-bold text-[20px] md:text-4xl text-blue-600'>
            01
          </div>
          <div className='row-span-2 sm:row-span-3 sm:my-auto flex justify-center'>
            <img
              src={strategy01}
              alt='Define goal'
              title='Define goal'
              loading='lazy'
              className='align-top h-[38px] w-[38px] sm:w-[132.47px] sm:h-[129.35px]'
            />
          </div>
          <h3 className='col-span-4 text-textPrimary font-bold text-xl sm:text-[40px] mb-7 md:mb-[30px] sm:mb-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.first.title'
              defaultMessage='Ziel definieren'
            />
          </h3>
          <div className='col-span-5 sm:col-span-4 font-normal text-sm sm:text-xl pr-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.first.content'
              defaultMessage='Die Grundlage unserer Zusammenarbeit bildet Ihr persönliches Ziel, das wir gemeinsam mit Ihnen definieren.'
            />
          </div>
        </div>
        <div
          className='grid grid-cols-5 gap-0 snap-center content-start shrink-0 w-16 rounded-2xl shadow-md h-[265px] p-4 sm:h-[360px] sm:p-16 mx-20 bg-white text-textPrimary'
          id='strategy2'
          aria-label='strategy2'
        >
          <div className='col-span-4 text-blue sm:mb-3.5 font-bold text-[20px] md:text-4xl text-blue-600'>
            02
          </div>
          <div className='row-span-2 sm:row-span-3 sm:my-auto flex justify-center'>
            <img
              src={strategy02}
              alt='Identify advantages'
              title='Identify advantages'
              loading='lazy'
              className='align-top h-[38px] w-[38px] sm:w-[132.47px] sm:h-[129.35px]'
            />
          </div>
          <h3 className='col-span-4 text-textPrimary font-bold text-xl sm:text-[40px] mb-7 md:mb-[30px] sm:mb-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.second.title'
              defaultMessage='Vorteile ermitteln'
            />
          </h3>
          <div className='col-span-5 sm:col-span-4 font-normal text-sm sm:text-xl pr-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.second.content'
              defaultMessage='Wir arbeiten Ihre Alleinstellungsmerkmale als Arbeitgeber heraus und halten fest, welche konkreten Vorteile Sie Arbeitnehmern bieten.'
            />
          </div>
        </div>
        <div
          className='grid grid-cols-5 gap-0 snap-center content-start shrink-0 w-16 rounded-2xl shadow-md h-[265px] p-4 sm:h-[360px] sm:p-16 mx-20 bg-white text-textPrimary'
          id='strategy3'
          aria-label='strategy3'
        >
          <div className='col-span-4 text-blue sm:mb-3.5 font-bold text-[20px] md:text-4xl text-blue-600'>
            03
          </div>
          <div className='row-span-2 sm:row-span-3 sm:my-auto flex justify-center'>
            <img
              src={strategy03}
              alt='Recruiting Strategy'
              title='Recruiting Strategy'
              loading='lazy'
              className='align-top h-[38px] w-[38px] sm:w-[132.47px] sm:h-[129.35px]'
            />
          </div>
          <h3 className='col-span-4 text-textPrimary font-bold text-xl sm:text-[40px] mb-7 md:mb-[30px] sm:mb-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.third.title'
              defaultMessage='Recruiting-Strategie'
            />
          </h3>
          <div className='col-span-5 sm:col-span-4 font-normal text-sm sm:text-xl pr-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.third.content'
              defaultMessage='Wir ermitteln Ihre individuelle Recruiting-Strategie, die Sie von Ihrer Konkurrenz abhebt und es Bewerbern erlaubt, sich schnell ein Bild Ihres Images zu machen.'
            />
          </div>
        </div>
        <div
          className='grid grid-cols-5 gap-0 snap-center content-start shrink-0 w-16 rounded-2xl shadow-md h-[265px] p-4 sm:h-[360px] sm:p-16 mx-20 bg-white text-textPrimary'
          id='strategy4'
          aria-label='strategy4'
        >
          <div className='col-span-4 text-blue sm:mb-3.5 font-bold text-[20px] md:text-4xl text-blue-600'>
            04
          </div>
          <div className='row-span-2 sm:row-span-3 sm:my-auto flex justify-center'>
            <img
              src={strategy04}
              alt='Application Process'
              title='Application Process'
              loading='lazy'
              className='align-top h-[38px] w-[38px] sm:w-[132.47px] sm:h-[129.35px]'
            />
          </div>
          <h3 className='col-span-4 text-textPrimary font-bold text-xl sm:text-[40px] mb-7 md:mb-[30px] sm:mb-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.fourth.title'
              defaultMessage='Bewerbungsprozess'
            />
          </h3>
          <div className='col-span-5 sm:col-span-4 font-normal text-sm sm:text-xl pr-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.fourth.content'
              defaultMessage='Dank unserer arbeit-nehmerfreundlichen Bewerbungs- und Einstellungsprozesse können sich Kandidaten innerhalb weniger Minuten bei Ihnen bewerben.'
            />
          </div>
        </div>
        <div
          className='grid grid-cols-5 gap-0 snap-center content-start shrink-0 w-16 rounded-2xl shadow-md h-[265px] p-4 sm:h-[360px] sm:p-16 mx-20 bg-white text-textPrimary'
          id='strategy5'
          aria-label='strategy5'
        >
          <div className='col-span-4 text-blue sm:mb-3.5 font-bold text-[20px] md:text-4xl text-blue-600'>
            05
          </div>
          <div className='row-span-2 sm:row-span-3 sm:my-auto flex justify-center'>
            <img
              src={strategy05}
              alt='Long-term strategy'
              title='Long-term strategy'
              loading='lazy'
              className='align-top h-[38px] w-[38px] sm:w-[132.47px] sm:h-[129.35px]'
            />
          </div>
          <h3 className='col-span-4 text-textPrimary font-bold text-xl sm:text-[40px] mb-7 md:mb-[30px] sm:mb-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.fifth.title'
              defaultMessage='Langfristige Strategie'
            />
          </h3>
          <div className='col-span-5 sm:col-span-4 font-normal text-sm sm:text-xl pr-8'>
            <Msg
              id='socialMediaRecruiting.strategySection.fifth.content'
              defaultMessage='Mit stetigen Marktanalysen behalten Sie aktuelle Strategien im Blick und sichern den langfristigen Erfolg Ihrer Recruiting Kampagne.'
            />
          </div>
        </div>
      </div>
      <div className='flex justify-center text-xl pt-5'>
        <button id='strategyDot1' aria-label='strategyDot1' onClick={() => show(1)}></button>
        <button id='strategyDot2' aria-label='strategyDot2' onClick={() => show(2)}></button>
        <button id='strategyDot3' aria-label='strategyDot3' onClick={() => show(3)}></button>
        <button id='strategyDot4' aria-label='strategyDot4' onClick={() => show(4)}></button>
        <button id='strategyDot5' aria-label='strategyDot5' onClick={() => show(5)}></button>
      </div>
    </div>
  )
}

export default StrategySection
