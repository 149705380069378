import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/common/sections/Footer/Footer'
import Navbar from '../../components/common/sections/Navbar/Navbar'
import ModalCalendly from '../../components/common/display/ModalPopUp/ModalCalendly'
import { navItemsMain } from '../navItems'

import noMatch from '../../assets/images/noMatch.png'
import logo from '../../assets/images/welitzki-it-logo-long.svg'

const NoMatch = ({ hasAcceptedFunctionalCookie, setShowCookieConsent, bannerAccepted }) => {
  const redirect = useNavigate()
  const [showCalendlyContactForm, setShowCalendlyContactForm] = React.useState(false)
  return (
    <div className='bg-secondaryDark page-wrapper'>
      <Navbar
        navItems={navItemsMain}
        secondVarient={false}
        logo={logo}
        hasScrollDirection={false}
      />
      <div role='main'>
        <div className='md:min-h-[460px] min-h-[680px] x:min-h-[430px] flex items-center layout'>
          <div className='w-full flex flex-col md:flex-row items-center justify-between gap-5'>
            <div className='max-w-md'>
              <h1 className='text-5xl font-dark font-bold'>404</h1>
              <h2 className='text-2xl md:text-3xl font-light leading-normal'>
                <Msg id='message.routeError.messageOne' />
              </h2>
              <p className='mb-8'>
                <Msg id='message.routeError.messageTwo' />
              </p>
              <button
                onClick={() => redirect('/')}
                className='px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700'
              >
                <Msg id='common.button.takeToHome' />
              </button>
            </div>
            <div className='max-w-md'>
              <img src={noMatch} alt='Page not Found' title='Page not Found' />
            </div>
          </div>
        </div>
      </div>
      <Footer
        theme={'light'}
        setShowCalendlyContactForm={setShowCalendlyContactForm}
        setShowCookieConsent={setShowCookieConsent}
        bannerAccepted={bannerAccepted}
      />
      <ModalCalendly
        showModal={showCalendlyContactForm}
        setShowModal={setShowCalendlyContactForm}
        hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
      />
    </div>
  )
}

NoMatch.propTypes = {
  setShowCookieConsent: PropTypes.func,
  hasAcceptedFunctionalCookie: PropTypes.bool,
  bannerAccepted: PropTypes.bool,
}

export default NoMatch
