export const navItemsMain = [
  { id: 1, messageId: 'services', href: 'services' },
  { id: 2, messageId: 'products', href: 'products' },
  { id: 3, messageId: 'references', href: 'referenzen' },
  { id: 4, messageId: 'aboutUs', href: 'ueber-uns' },
]

export const navItemsSMR = [
  { id: 1, messageId: 'start', href: 'start' },
  { id: 2, messageId: 'strategy', href: 'strategie' },
  { id: 3, messageId: 'procedure', href: 'ablauf' },
  { id: 4, messageId: 'aboutUs', href: 'ueber_uns' },
]

export const navItemsWD = [
  { id: 1, messageId: 'start', href: 'start' },
  { id: 2, messageId: 'wy', href: 'warum' },
  { id: 3, messageId: 'procedure', href: 'ablauf' },
  { id: 4, messageId: 'whyUs', href: 'warum_wir' },
  { id: 5, messageId: 'aboutUs', href: 'ueber_uns' },
]

export const navItemsWDevelopment = [
  { id: 1, messageId: 'start', href: 'start' },
  { id: 2, messageId: 'why', href: 'warum' },
  { id: 3, messageId: 'showcases', href: 'showcases' },
  { id: 4, messageId: 'services', href: 'services' },
  { id: 5, messageId: 'products', href: 'product' },
]
