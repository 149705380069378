import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Navbar from '../../components/common/sections/Navbar/Navbar'
import Hero from '../../components/socialMediaRecruiting/section/Hero/Hero'
import Footer from '../../components/common/sections/Footer/Footer'
import StrategySection from '../../components/socialMediaRecruiting/section/StrategySection/StrategySection'
import Faq from '../../components/socialMediaRecruiting/section/Faq/Faq'
import ConnectedCards from '../../components/socialMediaRecruiting/section/ConnectedCards/ConnectedCards'
import HowWeWork from '../../components/socialMediaRecruiting/section/HowWeWork/HowWeWork'
import AboutUs from '../../components/socialMediaRecruiting/section/AboutUs/AboutUs'
import ContactUs from '../../components/socialMediaRecruiting/section/ContactUs/ContactUs'
import ContactUsForm from '../../components/socialMediaRecruiting/section/ContactUsForm/ContactUsForm'
import ModalContactUs from '../../components/common/display/ModalContactUs/ModalContactUs'
import ModalCalendly from '../../components/common/display/ModalPopUp/ModalCalendly'
import { navItemsSMR } from '../navItems'

import logo from '../../assets/images/welitzki-it-logo-short.svg'
import NewsletterCTA from '../../components/common/sections/NewsletterCTA/NewsletterCTA'
import Quote from '../../components/common/sections/Quote/Quote'
import Slogan from '../../components/common/sections/Slogan/Slogan'
import MetaData from '../MetaData/MetaData'

const SocialMediaRecruiting = ({
  hasAcceptedFunctionalCookie,
  setShowCookieConsent,
  bannerAccepted,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showCalendlyContactForm, setShowCalendlyContactForm] = React.useState(false)
  return (
    <div className='page-wrapper'>
      <MetaData
        title='Social Media Recruiting Agentur |  Welitzki-IT'
        description='Social Media Recruiting Agentur - Wir helfen Ihnen die richtigen Mitarbeiter für Ihr Unternehmen zu finden. Wir kümmern uns um den gesamten Prozess von der Ausschreibung bis zum Abschlussgespräch'
        keywords='Social Media Recruiting, Social Media Recruiting Agentur, Rekrutierungshilfe in Deutschland'
        link='/stelle-gesucht/'
      />
      <Navbar
        navItems={navItemsSMR}
        secondVarient={true}
        name={'socialMediaRecruiting'}
        logo={logo}
        categoryName='SocialMediaRecruiting Page'
      />
      <div role='main'>
        <div id='start'>
          <Hero />
        </div>
        <ConnectedCards />
        <StrategySection />
        <Slogan
          variant='primary'
          hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
          categoryName='SocialMediaRecruiting Page'
        />
        <HowWeWork name='socialMediaRecruiting' categoryName='SocialMediaRecruiting Page' />
        {/*Temporarily disable until we really have references*/}
        {/*<References />*/}
        <NewsletterCTA variant='secondary' categoryName='SocialMediaRecruiting Page' />
        <AboutUs />
        <ContactUsForm categoryName='SocialMediaRecruiting Page' />
        <Quote />
        <ContactUs
          hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
          name='socialMediaRecruiting'
        />
        <Faq />
      </div>
      <Footer
        theme={'dark'}
        setShowModal={setShowModal}
        setShowCookieConsent={setShowCookieConsent}
        categoryName='SocialMediaRecruiting Page'
        bannerAccepted={bannerAccepted}
      />
      <ModalCalendly
        showModal={showCalendlyContactForm}
        setShowModal={setShowCalendlyContactForm}
        hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
      />
      <ModalContactUs showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

SocialMediaRecruiting.propTypes = {
  hasAcceptedFunctionalCookie: PropTypes.bool,
  setShowCookieConsent: PropTypes.func,
  bannerAccepted: PropTypes.bool,
}

export default SocialMediaRecruiting
