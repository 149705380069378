import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'

const CardsWithIcon = ({ image, content, extraClass, paragraphClass, imageWidthDesktop }) => {
  return (
    <>
      <div className='w-full hidden max-w-[591px] h-[186px] p-2 bg-secondary rounded-xl md:flex justify-evenly gap-8 shadow-xl'>
        <div className='w-full h-full flex justify-between items-center'>
          <img
            src={image}
            alt='Why social recruiting cards Icon'
            title='Why social recruiting cards Icon'
            className={`pl-8 ${imageWidthDesktop}`}
            loading='lazy'
          />
        </div>
        <div className={`flex flex-col gap-2 justify-center ${extraClass ?? ''}`}>
          <h3 className='text-textPrimary not-italic font-bold text-[22px]'>
            <Msg id={`socialMediaRecruiting.socialrecrutingcards.${content.card}.heading`} />
          </h3>
          <span
            className={`text-textSecondary not-italic font-normal text-[16px] leading-[27.2px] ${
              paragraphClass ?? ''
            }`}
          >
            <Msg id={`socialMediaRecruiting.socialrecrutingcards.${content.card}.paragraph`} />
          </span>
        </div>
      </div>

      <div className='md:hidden min-h-[218px] w-[291px] bg-secondary rounded-xl p-6 flex flex-col gap-3 shadow-xl'>
        <div className='flex gap-6 items-center'>
          <img
            src={image}
            alt='Why social recruiting cards Icon'
            title='Why social recruiting cards Icon'
            className='h-[41px] w-[44px] sm:h-8'
            loading='lazy'
          />

          <div className='h3 text-textPrimary not-italic font-bold text-lg '>
            <Msg id={`socialMediaRecruiting.socialrecrutingcards.${content.card}.heading`} />
          </div>
        </div>
        <div>
          <span className='text-textSecondary not-italic font-normal text-sm leading-[23.8px]'>
            <Msg id={`socialMediaRecruiting.socialrecrutingcards.${content.card}.paragraph`} />
          </span>
        </div>
      </div>
    </>
  )
}

CardsWithIcon.propTypes = {
  image: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  extraClass: PropTypes.string,
  paragraphClass: PropTypes.string,
  imageWidthDesktop: PropTypes.string,
}
export default CardsWithIcon
