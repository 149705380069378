import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import Navbar from '../../components/common/sections/Navbar/Navbar'
import Hero from '../../components/Main/Hero/Hero'
import PrivacyData from '../../components/common/sections/PrivacyData/PrivacyData'
import Footer from '../../components/common/sections/Footer/Footer'
import ModalContactUs from '../../components/common/display/ModalContactUs/ModalContactUs'
import { navItemsMain } from '../navItems'

import rootLogo from '../../assets/images/welitzki-it-logo-long.svg'
import ScrollToTop from '../../scrollToView'
import MetaData from '../MetaData/MetaData'

const Privacy = ({ setShowCookieConsent, bannerAccepted }) => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <ScrollToTop />
      <MetaData
        title='Datenschutzrichtlinie - Ihre Datenschutzinformationen'
        description='Entdecken Sie unser Engagement für Datenschutz und Sicherheit. Unsere Datenschutzrichtlinie zeigt auf, wie wir Ihre persönlichen Informationen erfassen, verwenden und schützen. Erfahren Sie mehr über Ihre Rechte und Entscheidungsmöglichkeiten bezüglich Ihrer Daten'
        keywords='Datenschutzrichtlinie, Datenschutz, persönliche Informationen, DSGVO, CCPA, Datensicherheit, Nutzerrechte, Datensicherheit, Cookie-Richtlinie, Informationsverarbeitung'
        link='/datenschutz/'
      />
      <div className='page-wrapper'>
        <Navbar navItems={navItemsMain} secondVarient={false} logo={rootLogo} />
        <div role='main'>
          <Hero
            heroText={intl.formatMessage({
              id: 'socialMediaRecruiting.privacy.title',
              defaultMessage: 'Privacy',
            })}
          />
          <div className='layout grid bg-white justify-center item-center'>
            <div className='flex-col mi:mx-[0px] w-[292px] ti:w-auto my-[80px] bg-secondary rounded-xl justify-center item-center align-middle self-center shadow-xl h-auto'>
              <div className='px-[20.05px] ti:px-[59.66px] text-left my-[34.86px] ti:my-[50px]'>
                <PrivacyData />
              </div>
            </div>
          </div>
        </div>
        <Footer
          theme={'light'}
          setShowModal={setShowModal}
          setShowCookieConsent={setShowCookieConsent}
          categoryName='Privacy Page'
          bannerAccepted={bannerAccepted}
        />
        <ModalContactUs showModal={showModal} setShowModal={setShowModal} />
      </div>
    </>
  )
}

Privacy.propTypes = {
  setShowCookieConsent: PropTypes.func.isRequired,
  bannerAccepted: PropTypes.bool,
}

export default Privacy
