import React from 'react'
import PropTypes from 'prop-types'
import { scroller } from 'react-scroll'
import { FormattedMessage as Msg } from 'react-intl'
import slogan from '../../../../assets/images/slogan.svg'
import Button from '../../inputs/Button/Button'
import { trackEvent } from '../../../../matomo'

const Slogan = ({
  variant,
  setShowCalendlyContactForm,
  hasAcceptedFunctionalCookie,
  categoryName,
}) => {
  const scrollTOContactUs = () => {
    scroller.scrollTo('contactus', {
      duration: 150,
      delay: 100,
      smooth: true,
    })
  }
  const eventPrefixes = {
    'Home Page': 'home_',
    'WebDesign Page': 'wdesign_',
    'SocialMediaRecruiting Page': 'smr_',
  }

  const eventName = eventPrefixes[categoryName] || ''

  const trackEvents = () => {
    trackEvent(categoryName, 'Click', `${eventName}slogan_btn_click`)
  }
  return (
    <div id='slogan' className={`pt-10 ${variant === 'secondary' && 'bg-secondary'}`}>
      <div
        className={`${
          variant === 'secondary' ? 'bg-blue' : 'bg-gprimary1'
        } grid grid-cols-12 rounded-[20px] gap-5 h-auto py-5 lg:min-h-[274px] w-auto x:w-[1190px] mx-[35px] ti:mx-10 sm:mx-16 x:mx-auto`}
      >
        <div
          className={`hidden lg:block col-span-4 lg:col-span-0 mx-auto my-auto x:mt-[1.8rem] ${
            variant === 'secondary' ? 'text-white' : ''
          }`}
        >
          <img src={slogan} alt='slogan' className='w-[271px] h-[215px]' title='newLetter' />
        </div>

        <div className='col-span-12 lg:col-span-8 -ml-0 x:-ml-8'>
          <h2
            className={`whitespace-pre-line text-[24px] lg:text-[38px] font-semibold text-center lg:text-left px-5 mt-0 lg:mt-[38px] lg:px-0 leading-[56px] ${
              variant === 'secondary' ? 'text-white' : 'text-blueLight'
            }`}
          >
            <Msg id='common.slogan.text' />
          </h2>

          <Button
            transparent={variant === 'secondary'}
            withIcon={true}
            onClick={() => {
              trackEvents()
              hasAcceptedFunctionalCookie ? scrollTOContactUs() : setShowCalendlyContactForm(true)
            }}
            extraClasses={`lg:ml-0 mx-auto text-blue text-left font-semibold h-[51px] mt-[10px] lg:mt-[29px] ${
              variant === 'secondary' && 'bg-white text-blue'
            }`}
            text='consultation'
          />
        </div>
      </div>
    </div>
  )
}

Slogan.propTypes = {
  categoryName: PropTypes.string,
  variant: PropTypes.string,
  setShowCalendlyContactForm: PropTypes.func,
  hasAcceptedFunctionalCookie: PropTypes.bool,
}

export default Slogan
