import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import { scroller } from 'react-scroll'
import Button from '../../../common/inputs/Button/Button'

import HeroSVG from '../../../../assets/images/heroImage.svg'
import { trackEvent } from '../../../../matomo'

const Hero = () => {
  return (
    <div
      id='hero-text-smr'
      className='layout pt-10 md:px-10 lg:px-32 md:pt-32 pb-16 bg-secondary md:px16 flex flex-col md:flex-row'
    >
      <div className='p-auto pb-4 md:pb-10 text-left sm:text-center md:text-left w-full md:w-[50%] lg:w-[60%]'>
        <h1 className='leading-[30px] md:leading-[45px] lg:leading-[54px] py-10 sm:px-36 md:px-0 md:pr-8 text-xl font-semibold tracking-normal text-left sm:text-center md:text-left align-middle md:text-3xl lg:text-4xl'>
          <Msg
            id='socialMediaRecruiting.hero.firstText'
            defaultMessage='Revolutionize your personnel-recruiting and gain'
          />
          <span className='underline decoration-blue decoration-[3px] md:decoration-[5px]'>
            <Msg
              id='socialMediaRecruiting.hero.secondText'
              defaultMessage='qualified professionals'
            />
          </span>
          <Msg id='socialMediaRecruiting.hero.lastText' defaultMessage=' for yourself.' />
        </h1>
        <Button
          transparent={false}
          withIcon={true}
          onClick={() => {
            trackEvent('SocialMediaRecruiting Page', 'Click', 'smr_hero_btn_click')
            scroller.scrollTo('contactus', {
              duration: 150,
              delay: 100,
              smooth: true,
            })
          }}
          extraClasses='mb-16 mx-auto md:mx-0 font-semibold h-[51px]'
          text='consultation'
        />
      </div>
      <div className='flex justify-center md:justify-end md:align-top sm:h-[330px] md:w-[50%] lg:w-[40%] w-full'>
        <img
          src={HeroSVG}
          alt='hero image'
          title='hero image'
          className='align-top w-[290px] h-[211px] sm:w-[463px] sm:h-[330px] lg:w-[307px] xl:w-[453px]'
        />
      </div>
    </div>
  )
}
export default Hero
