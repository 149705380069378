import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import logoBlue from '../../../../assets/images/main/rootLogoSmall.svg'
import Email from '../../../../assets/images/main/mail.svg'
import Phone from '../../../../assets/images/main/phone.svg'
import LinkedIn from '../../../../assets/images/main/linkedin.svg'
import Xing from '../../../../assets/images/main/xing.svg'
import Instagram from '../../../../assets/images/main/instagram.svg'
import logoWhite from '../../../../assets/images/welitzki-it-logo-short-small.svg'
import EmailWhite from '../../../../assets/images/main/mailWhite.svg'
import PhoneWhite from '../../../../assets/images/main/phoneWhite.svg'
import LinkedInWhite from '../../../../assets/images/main/linkedinWhite.svg'
import XingWhite from '../../../../assets/images/main/xingWhite.svg'
import InstagramWhite from '../../../../assets/images/main/instagramWhite.svg'
import cookieDark from '../../../../assets/images/cookie.svg'
import cookieLight from '../../../../assets/images/cookie-white.svg'
import SocialIcons from '../../display/SocialIcons/SocialIcons'
import { trackEvent } from '../../../../matomo'

const Footer = ({ categoryName, theme, setShowCookieConsent, setShowModal, bannerAccepted }) => {
  const eventPrefixes = {
    'Home Page': 'home_',
    'WebDesign Page': 'wdesign_',
    'SocialMediaRecruiting Page': 'smr_',
    'WebDevelopment Page': 'wdev_',
    'Imprint Page': 'imprint_',
    'Privacy Page': 'privacy_',
  }
  const navTo = (link, externalLink) => {
    if (externalLink === true) {
      window.open(link, '_blank', 'noopener', 'noreferrer')
    } else if (window.location.pathname === '/') {
      window.location.hash = link
      window.scrollTo(0, document.getElementById('products')?.offsetTop - 100)
    } else {
      window.location.hash = link
      window.open('/#' + link, '_self')
    }
  }
  const eventName = eventPrefixes[categoryName] || ''
  return (
    <>
      <footer
        role='contentinfo'
        className={`text-base ${
          theme === 'light' ? 'bg-footerLight text-black' : 'bg-footerDark text-white'
        }`}
      >
        <div className='h-[230px] hidden x:grid x:grid-flow-col'>
          {bannerAccepted && (
            <div className='my-auto w-[230px]'>
              {
                //<!-- ProvenExpert Bewertungssiegel -->
              }
              <Link
                to='https://www.provenexpert.com/nicolas-welitzki-it-consulting/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget'
                title='Kundenbewertungen &amp; Erfahrungen zu Nicolas Welitzki IT-Consulting. Mehr Infos anzeigen.'
                target='_blank'
                rel='noopener noreferrer'
                className='block w-[130px] mx-auto'
              >
                <img
                  src='https://images.provenexpert.com/6c/2c/e1f25b6f27b0cf78bceca7ec2576/widget_portrait_130_de_0.png'
                  alt='Erfahrungen &amp; Bewertungen zu Nicolas Welitzki IT-Consulting'
                  className='border-0 h-[156px] w-[130px]'
                />
              </Link>

              {
                //<!-- ProvenExpert Bewertungssiegel -->
              }
            </div>
          )}
          <div className='p-4 pt-[60px] w-[252px]'>
            <div className='flex flex-col'>
              <div
                className={`font-bold text-xl mb-5 ${
                  theme === 'dark' ? 'text-white' : 'text-blue'
                }`}
              >
                <Msg id='footer.location' defaultMessage='location' />
              </div>
              <div className='mb-0'>
                Poschingerstraße 30
                <br />
                12157 Berlin
                <br />
                Deutschland
              </div>
            </div>
          </div>
          <div className='p-4 pt-[60px] w-[268px]'>
            <div className='flex flex-col'>
              <div
                className={`font-bold text-xl mb-5 ${
                  theme === 'dark' ? 'text-white' : 'text-blue'
                }`}
              >
                <Msg id='footer.services' defaultMessage='Services' />
              </div>
              <div>
                <Link
                  onClick={() =>
                    trackEvent(
                      categoryName,
                      'Web Design Click',
                      `${eventName}footer_webDesign_navigation`
                    )
                  }
                  id='Web-Design-link'
                  title='Web Design'
                  to='/web-design'
                >
                  <Msg id='footer.webDesign' defaultMessage='Web Design' />
                </Link>
              </div>
              <div>
                <Link
                  onClick={() =>
                    trackEvent(
                      categoryName,
                      'Web Development Click',
                      `${eventName}footer_webDevelopment_navigation`
                    )
                  }
                  id='Software-Development-link'
                  title='Software development'
                  to='/web-entwicklung'
                >
                  <Msg id='footer.softwareDevelopment' defaultMessage='Software development' />
                </Link>
              </div>
              <div>
                <Link
                  onClick={() =>
                    trackEvent(
                      categoryName,
                      'Social Media Recruiting Click',
                      `${eventName}footer_social_media_recruiting_navigation`
                    )
                  }
                  id='Social-Media-Recruiting-link'
                  title='Social Media Recruiting'
                  to='/stelle-gesucht'
                >
                  <Msg id='footer.socialMediaRecruiting' defaultMessage='Social Media Recruiting' />
                </Link>
              </div>
              <div>
                <Msg id='footer.seo' defaultMessage='SEO' />
              </div>
            </div>
          </div>
          <div className='p-4 pt-[60px] w-[243px]'>
            <div className='flex flex-col'>
              <div
                className={`font-bold text-xl mb-5 ${
                  theme === 'dark' ? 'text-white' : 'text-blue'
                }`}
              >
                <Msg id='footer.products' defaultMessage='Products' />
              </div>
              <div>
                <button
                  onClick={() => {
                    trackEvent(
                      categoryName,
                      'Website Analysis Click',
                      `${eventName}footer_websiteAnalysis_navigation`
                    )
                    navTo('products')
                  }}
                  id='Website-Analysis-link'
                  title='Website Analysis'
                >
                  <Msg id='footer.websiteAnalysis' defaultMessage='Website Analysis' />
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    trackEvent(
                      categoryName,
                      'Web Boost Click',
                      `${eventName}footer_Web_Boost_navigation`
                    )
                    navTo('products')
                  }}
                  id='Web-Boost-link'
                  title='Web Boost'
                >
                  <Msg id='footer.webBoost' defaultMessage='Web Boost' />
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    trackEvent(
                      categoryName,
                      'GDPR Elimination Click',
                      `${eventName}footer_GDPRElimination_navigation`
                    )
                    navTo('products')
                  }}
                  id='GDPR-elimination-link'
                  title='GDPR Elimination'
                >
                  <Msg id='footer.gdprElimination' defaultMessage='GDPR elimination' />
                </button>
              </div>
            </div>
          </div>
          <div className='p-4 pt-[60px] w-[170px]'>
            <div className='flex flex-col'>
              <div
                className={`font-bold text-xl mb-5 ${
                  theme === 'dark' ? 'text-white' : 'text-blue'
                }`}
              >
                <Msg id='footer.contact' defaultMessage='Contact' />
              </div>
              <div className='flex flex-row gap-2 items-center mb-1'>
                <button
                  title='Send Email'
                  className='flex flex-row gap-2 items-center cursor-pointer'
                  onClick={() => {
                    trackEvent(
                      categoryName,
                      'Social Media Click',
                      `${eventName}footer_company_email_navigation`
                    )
                    setShowModal(true)
                  }}
                >
                  <img
                    src={theme === 'dark' ? EmailWhite : Email}
                    className='h-5 w-5'
                    alt='Send Email'
                    loading='lazy'
                  />
                  <Msg id='footer.email' defaultMessage='Email' />
                </button>
              </div>
            </div>
            <div>
              <Link
                to='tel: +4915123246715'
                title='Phone'
                rel='noopener noreferrer'
                className='flex flex-row gap-2 items-center'
                onClick={() =>
                  trackEvent(
                    categoryName,
                    'Social Media Click',
                    `${eventName}footer_company_phone_navigation`
                  )
                }
              >
                <img
                  src={theme === 'dark' ? PhoneWhite : Phone}
                  className='h-5 w-5 cursor-pointer fill-white'
                  alt='Make a Call'
                  title='Phone'
                  loading='lazy'
                />
                <Msg id='footer.phone' defaultMessage='Phone' />
              </Link>
            </div>
          </div>
          <div className='p-4 pt-[60px] mr-[100px] w-[200px]'>
            <div className='flex flex-col gap-1'>
              <div
                className={`font-bold text-xl mb-5 ${
                  theme === 'dark' ? 'text-white' : 'text-blue'
                }`}
              >
                Social Media
              </div>
              <div>
                <button
                  onClick={() => {
                    navTo('https://www.linkedin.com/company/nicolas-welitzki-it-consulting/', true)
                    trackEvent(
                      categoryName,
                      'Social Media Click',
                      `${eventName}footer_linkedin_navigation`
                    )
                  }}
                  title='LinkedIn'
                  className='flex flex-row gap-2 items-center'
                >
                  <img
                    src={theme === 'dark' ? LinkedInWhite : LinkedIn}
                    className='h-5 w-5 cursor-pointer'
                    alt='Linkedin Logo'
                    title='Linkedin Logo'
                    loading='lazy'
                  />
                  LinkedIn
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    navTo('https://www.xing.com/profile/Nicolas_Welitzki/', true)
                    trackEvent(
                      categoryName,
                      'Social Media Click',
                      `${eventName}footer_xing_navigation`
                    )
                  }}
                  title='Xing'
                  className='flex flex-row gap-2 items-center'
                >
                  <img
                    src={theme === 'dark' ? XingWhite : Xing}
                    className='h-5 w-5 cursor-pointer'
                    alt='Xing Logo'
                    title='Xing Logo'
                    loading='lazy'
                  />
                  Xing
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    navTo('https://www.instagram.com/nicolaswelitzkiitconsulting/', true)
                    trackEvent(
                      categoryName,
                      'Social Media Click',
                      `${eventName}footer_instagram_navigation`
                    )
                  }}
                  title='Instagram'
                  className='flex flex-row gap-2 items-center'
                >
                  <img
                    src={theme === 'dark' ? InstagramWhite : Instagram}
                    className='h-5 w-5 cursor-pointer'
                    alt='Instagram Logo'
                    title='Instagram Logo'
                    loading='lazy'
                  />
                  Instagram
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='x:hidden'>
          {bannerAccepted && (
            <div className='py-[20px]'>
              {
                //<!-- ProvenExpert Bewertungssiegel -->
              }
              <Link
                to='https://www.provenexpert.com/nicolas-welitzki-it-consulting/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget'
                title='Kundenbewertungen &amp; Erfahrungen zu Nicolas Welitzki IT-Consulting. Mehr Infos anzeigen.'
                target='_blank'
                rel='noopener noreferrer'
                className='w-[130px]'
              >
                <img
                  src='https://images.provenexpert.com/6c/2c/e1f25b6f27b0cf78bceca7ec2576/widget_portrait_130_de_0.png'
                  alt='Erfahrungen &amp; Bewertungen zu Nicolas Welitzki IT-Consulting'
                  className='border-0 h-[156px] w-[130px] mx-auto'
                />
              </Link>

              {
                //<!-- ProvenExpert Bewertungssiegel -->
              }
            </div>
          )}
          <div className='pt-[15px] py-[15px]'>
            <SocialIcons categoryName={categoryName} theme={theme} eventName={eventName} />
          </div>
          <div className='p-[15px] text-center'>
            Poschingerstraße 30 | 12157
            <br />
            Berlin | Deutschland
          </div>
        </div>
        <div
          className={`grid grid-cols-1 x:grid-cols-2 gap-4 h-[46px] x:h-[66px] items-center justify-around border-t-2 ${
            theme === 'light' ? 'border-blue' : 'border-white'
          } px-[100px]`}
        >
          <div className='text-white w-full hidden x:flex x:justify-start'>
            <Link
              onClick={() => {
                trackEvent(categoryName, 'Navigate', `${eventName}footer_home_navigation`)
              }}
              id='Home-link'
              title='Home'
              to='/'
            >
              <img
                src={theme === 'light' ? logoBlue : logoWhite}
                className='h-[32.13px] w-[43.82px]'
                alt='Company Logo'
                title='Company Logo'
                loading='lazy'
              />
            </Link>
          </div>
          <div className='flex justify-center x:justify-end'>
            <ul
              className={`flex items-center justify-center gap-[9px] x:gap-[30px] font-bold text-xs x:text-base w-[274px] ${
                theme === 'light' ? 'text-black' : 'text-white'
              } `}
            >
              <li>
                <Link
                  onClick={() => {
                    trackEvent(categoryName, 'Navigate', `${eventName}footer_imprint_navigation`)
                  }}
                  id='imprint-link'
                  title='Imprint'
                  to='/impressum'
                >
                  <Msg id='common.button.imprint' defaultMessage='Imprint' />
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    trackEvent(categoryName, 'Navigate', `${eventName}footer_privacy_navigation`)
                  }}
                  id='privacy-link'
                  to='/datenschutz'
                  title='Privacy'
                >
                  <Msg id='common.button.privacy' defaultMessage='Privacy' />
                </Link>
              </li>
              <li className='w-[21px]'>
                <img
                  onClick={() => setShowCookieConsent()}
                  src={theme === 'light' ? cookieDark : cookieLight}
                  alt='Change Cookies Preferences'
                  title='Change Cookies Preferences'
                  className='h-[21px] cursor-pointer w-full'
                />
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
Footer.propTypes = {
  theme: PropTypes.string,
  setShowCookieConsent: PropTypes.func,
  categoryName: PropTypes.string,
  setShowModal: PropTypes.func,
  bannerAccepted: PropTypes.bool,
}
export default Footer
