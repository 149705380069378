import React, { Suspense, useState } from 'react'
import PropTypes from 'prop-types'

import Navbar from '../../components/common/sections/Navbar/Navbar'
import logo from '../../assets/images/welitzki-it-logo-short.svg'
import { navItemsWD } from '../navItems'
import Why from '../../components/common/sections/Why/Why'
import HowWeWork from '../../components/socialMediaRecruiting/section/HowWeWork/HowWeWork'
import WhyUs from '../../components/WebDesign/section/WhyUs/WhyUs'
import Faq from '../../components/WebDesign/section/Faq/Faq'
import Footer from '../../components/common/sections/Footer/Footer'
import AboutUs from '../../components/WebDesign/section/AboutUs/AboutUs'
import ContactUs from '../../components/socialMediaRecruiting/section/ContactUs/ContactUs'
import MetaData from '../MetaData/MetaData'
import NewsletterCTA from '../../components/common/sections/NewsletterCTA/NewsletterCTA'
import Slogan from '../../components/common/sections/Slogan/Slogan'
import Showcases from '../../components/common/sections/Showcases/Showcases'
import Hero from '../../components/common/sections/Hero/Hero'
import ModalCalendly from '../../components/common/display/ModalPopUp/ModalCalendly'
import ModalContactUs from '../../components/common/display/ModalContactUs/ModalContactUs'

const WebDesign = ({ setShowCookieConsent, hasAcceptedFunctionalCookie, bannerAccepted }) => {
  const [showCalendlyContactForm, setShowCalendlyContactForm] = React.useState(false)
  const [showModal, setShowModal] = useState(false)
  return (
    <div className='page-wrapper'>
      <MetaData
        title='Agentur für Webdesign in der DACH-Region'
        description='Unser Team ist spezialisiert auf die Gestaltung hochwertiger und ästhetischer Webseiten für Unternehmenaller Branchen. Wir arbeiten mit den neusten Methoden und Technologien und sind sehr erfahren im Bereich Webdesign.'
        keywords='Webdesign, Web Development, Web Agency, Webdesign, Webentwicklung, Werbeagentur, Webdesign Berlin, Webentwicklung Berlin, Webagentur Berlin'
        link='/web-design/'
      />
      <Navbar
        navItems={navItemsWD}
        secondVarient={true}
        name={'webDesign'}
        logo={logo}
        categoryName='WebDesign Page'
      />
      <div role='main'>
        <div id='start'>
          <Hero page='webDesign' categoryName='WebDesign Page' />
        </div>
        <div id='warum'>
          <Why name={'webDesign'} desc={true} />
        </div>
        <div id='warum_wir'>
          <WhyUs />
        </div>
        <Slogan
          variant='secondary'
          hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
          categoryName='WebDesign Page'
        />
        <Suspense>
          <Showcases page='webDesign' categoryName='WebDesign Page' />
        </Suspense>
        <HowWeWork bgColor='primaryCTA' name='webDesign' categoryName='WebDesign Page' />
        <div id='ueber_uns'>
          <AboutUs />
        </div>
        <NewsletterCTA variant='secondary' categoryName='WebDesign Page' bg='white' />
        <ContactUs hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie} name='webDesign' />
        <Faq />
      </div>
      <Footer
        theme={'light'}
        setShowModal={setShowModal}
        setShowCookieConsent={setShowCookieConsent}
        categoryName='WebDesign Page'
        bannerAccepted={bannerAccepted}
      />
      <ModalCalendly
        showModal={showCalendlyContactForm}
        setShowModal={setShowCalendlyContactForm}
        hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
      />
      <ModalContactUs showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

WebDesign.propTypes = {
  setShowCookieConsent: PropTypes.func,
  hasAcceptedFunctionalCookie: PropTypes.bool,
  bannerAccepted: PropTypes.bool,
}
export default WebDesign
