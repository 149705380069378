import React from 'react'
import { useIntl, FormattedMessage as Msg } from 'react-intl'

const ContactPartner = () => {
  const HtmlToReactParser = require('html-to-react').Parser
  const htmlToReactParser = new HtmlToReactParser()
  const intl = useIntl()

  return (
    <div className='bg-gprimary1 py-8 rounded-[20px] gap-5 h-auto xl:max-h-[431px] w-auto x:w-[1188px] mx-[35px] ti:mx-10 sm:mx-16 x:mx-auto mt-10 lg:mt-[70px] mb-[60px]'>
      <h2 className='text-[20px] text-black lg:text-4xl font-semibold text-center pb-4 px-5 lg:px-0'>
        <Msg id='company.contactPartner.title' />
      </h2>

      <div className='text-base text-black font-medium px-[50px]'>
        {htmlToReactParser.parse(
          intl.formatMessage({
            id: 'company.contactPartner.details',
            defaultMessage: 'Contact Partner',
          })
        )}
      </div>
    </div>
  )
}

export default ContactPartner
