import React from 'react'
import PropTypes from 'prop-types'

import ModalPopUp from '../ModalPopUp/ModalPopUp'
import OurProductCard from '../OurProductCard/OurProductCard'

import webBoost from '../../../../assets/images/webDesign/webBoost.svg'

const ModalOurProducts = ({ showModal, setShowModal }) => {
  const data = [
    {
      id: 1,
      name: 'home_product_btn_click_webboost1',
      card: 'webBoost.card1',
      tag: true,
      showPrice: true,
      priceMedium: true,
      showDescription: false,
      showInformation: false,
      productCount: 4,
      showIcon: false,
      smallOffer: false,
      bigOffer: false,
      price: '199,95 €',
    },
    {
      id: 2,
      name: 'home_product_btn_click_webboost2',
      card: 'webBoost.card2',
      tag: true,
      showPrice: true,
      priceMedium: true,
      showDescription: false,
      showInformation: false,
      productCount: 5,
      showIcon: false,
      smallOffer: false,
      bigOffer: false,
      price: '299,95 €',
    },
    {
      id: 3,
      name: 'home_product_btn_click_webboost3',
      card: 'webBoost.card3',
      tag: true,
      showPrice: true,
      priceMedium: true,
      showDescription: false,
      showInformation: false,
      productCount: 7,
      showIcon: false,
      smallOffer: false,
      bigOffer: false,
      price: '499,95 €',
    },
    {
      id: 4,
      name: 'home_product_btn_click_webboost4',
      card: 'webBoost.card4',
      tag: false,
      showPrice: true,
      priceSmall: true,
      showDescription: false,
      showInformation: false,
      productCount: 3,
      showIcon: false,
      smallOffer: false,
      bigOffer: false,
      price: 'Preis nach Absprache',
    },
  ]
  return (
    <ModalPopUp
      showModal={showModal}
      setShowModal={setShowModal}
      extraClass='max-w-[1440px] w-[90%] min-h-[474px] mt-auto sm:my-auto'
    >
      <div className='grid justify-center items-start x:grid-cols-4 gap-[20px] xl:gap-auto mx-auto px-[15px] ti:px-[32px] xl:px-[57px] mb-[40px]'>
        {data.map((item) => (
          <OurProductCard
            key={item.id}
            eventName={item.name}
            card={item.card}
            image={webBoost}
            tag={item.tag}
            thin={true}
            showPrice={item.showPrice}
            priceSmall={item.priceSmall}
            priceMedium={item.priceMedium}
            showDescription={item.showDescription}
            showInformation={item.showInformation}
            productCount={item.productCount}
            showIcon={item.showIcon}
            smallOffer={item.smallOffer}
            bigOffer={item.bigOffer}
            price={item.price}
            offerPrice={item.offerPrice}
            hint={false}
          />
        ))}
      </div>
    </ModalPopUp>
  )
}

ModalOurProducts.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}

export default ModalOurProducts
