import React, { useState } from 'react'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
import * as emailjs from '@emailjs/browser'
import PropTypes from 'prop-types'

import TextArea from '../../../common/inputs/TextArea/TextArea'
import TextInput from '../../../common/inputs/TextInput/TextInput'
import Button from '../../../common/inputs/Button/Button'
import { trackEvent } from '../../../../matomo'

const ContactUsForm = ({ type, payment, card, image, price, page, section }) => {
  const intl = useIntl()

  let messageText = ''
  let mainDIV

  if (payment) {
    mainDIV = 'w-full lg:w-[915px] min-h-[592px] mt-auto 1 pb-6'
    messageText =
      intl.formatMessage({
        id: 'socialMediaRecruiting.contactUsForm.messageText1',
        defaultMessage: 'I am interested in the product “',
      }) +
      intl.formatMessage({
        id: `${page}.${section}.${card}.title`,
        defaultMessage: 'title',
      }) +
      intl.formatMessage({
        id: 'socialMediaRecruiting.contactUsForm.messageText2',
        defaultMessage: '”. Please note my order and get back to me.',
      })
  } else if (type) {
    mainDIV = 'py-12 sm:w-[534px] rounded-3xl'
  } else {
    mainDIV = 'section bg-secondary pb-10'
  }

  const [state, setState] = useState({
    loading: false,
    name: '',
    nameErrorText: '',
    email: '',
    phone: '',
    emailErrorText: '',
    message: messageText,
    messageErrorText: '',
    isSubmitted: false,
    status: 'NOT_DISPATCHED',
  })
  const validate = (property, value) => {
    const testEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$/
    if (property === 'name') {
      if (value.length < 5) {
        return intl.formatMessage({ id: 'socialMediaRecruiting.contactUsForm.nameError' })
      }
    }
    if (property === 'email') {
      if (value.length < 1) {
        return intl.formatMessage({ id: 'socialMediaRecruiting.contactUsForm.emptyEmailErr' })
      }
      if (!testEmail.test(value)) {
        return intl.formatMessage({ id: 'socialMediaRecruiting.contactUsForm.InvalidEmailErr' })
      }
    }
    if (property === 'message') {
      if (value.length < 10) {
        return intl.formatMessage({ id: 'socialMediaRecruiting.contactUsForm.messageError' })
      }
    }
  }

  const onChange = (property, value) =>
    setState({ ...state, [property]: value, [`${property}ErrorText`]: validate(property, value) })

  const onClick = () => {
    const emailErrorText = validate('email', state.email)
    const nameErrorText = validate('name', state.name)
    const messageErrorText = validate('message', state.message)
    const isValid =
      !(nameErrorText || emailErrorText || messageErrorText) &&
      (process.env.REACT_APP_EMAIL_DISABLED ?? false) === false
    const getProductName = (card) => {
      const mapping =
        page === 'company' ? ['product1', 'product2', 'product3'] : ['card1', 'card2', 'card3']
      if (mapping.includes(card)) {
        return `(${intl.formatMessage({ id: `${page}.${section}.${card}.title` })})`
      }
      return ''
    }
    setState({
      ...state,
      nameErrorText,
      emailErrorText,
      messageErrorText,
      isSubmitted: isValid,
      ...(isValid && { loading: true }),
    })
    if (isValid) {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          {
            from_name: `${state.name} ${state?.phone} wants ${getProductName(card)} at ${price}`,
            to_name: 'Nicolas Welitzki IT-Consulting',
            message: state.message,
            reply_to: state.email,
          },
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then((result) => {
          const status = result.status === 200 ? 'SHIPPED' : 'ERROR'
          setState({
            ...state,
            loading: false,
            name: '',
            email: '',
            message: '',
            status,
          })
          trackEvent('Home Page', 'Form Submit', `product_${getProductName(card)}_inquiry`)
        })
        .catch(() =>
          setState({ ...state, loading: false, name: '', email: '', message: '', status: 'ERROR' })
        )
    }
  }
  return (
    <div id='contactUs' className={`mi:mx-auto px-8 w-full ${mainDIV}`}>
      {payment && (
        <h2 className='text-center mb-8'>
          <Msg
            id='socialMediaRecruiting.contactUsForm.titlePayment'
            defaultMessage='Inquiry for:'
          />
        </h2>
      )}
      {!type && (
        <h2>
          <Msg id='socialMediaRecruiting.contactUsForm.title' />
        </h2>
      )}
      <div
        className={`${
          payment ? 'flex flex-col-reverse md:flex-row gap-5 sm:gap-16 items-stretch' : ''
        }`}
      >
        <div
          className={`grid mx-auto ${payment ? 'basis-1/2 w-full' : ''} ${
            type ? 'flex flex-col gap-5 w-full' : 'content-center gap-5 px-3 sm:w-[534px]'
          }`}
        >
          <TextInput
            id='name'
            value={state.name}
            placeholder={intl.formatMessage({
              id: 'socialMediaRecruiting.contactUsForm.placeholder.name',
            })}
            mandatory={true}
            type='text'
            errorMsg={state.nameErrorText}
            onChange={(value) => onChange('name', value)}
          />
          <TextInput
            id='phone'
            value={state.phone}
            placeholder={intl.formatMessage({
              id: 'socialMediaRecruiting.contactUsForm.placeholder.phone',
            })}
            mandatory={false}
            type='text'
            onChange={(value) => onChange('phone', value)}
          />

          <TextInput
            id='email'
            type='email'
            value={state.email}
            placeholder={intl.formatMessage({
              id: 'socialMediaRecruiting.contactUsForm.placeholder.email',
            })}
            mandatory={true}
            errorMsg={state.emailErrorText}
            onChange={(value) => onChange('email', value)}
          />
          <TextArea
            id='message'
            value={state.message}
            mandatory={true}
            placeholder={intl.formatMessage({
              id: 'socialMediaRecruiting.contactUsForm.placeholder.message',
            })}
            errorMsg={state.messageErrorText}
            onChange={(value) => onChange('message', value)}
          />
          {state.status === 'ERROR' && (
            <div className='text-red'>
              <Msg id='socialMediaRecruiting.contactUsForm.error' />
            </div>
          )}
          {state.status === 'SHIPPED' && (
            <div className='text-green'>
              <Msg id='socialMediaRecruiting.contactUsForm.success' />
            </div>
          )}
          <div className='mx-auto'>
            {state.status === 'SHIPPED' ? (
              <Button
                onClick={() => {
                  setState({
                    loading: false,
                    name: state.name,
                    nameErrorText: '',
                    email: state.email,
                    emailErrorText: '',
                    message: state.message,
                    messageErrorText: '',
                    isSubmitted: false,
                    status: 'NOT_DISPATCHED',
                  })
                }}
                extraClasses='font-semibold text-white mt-4'
                withIcon={false}
                transparent={false}
                text='newResponse'
              />
            ) : state.status === 'ERROR' ? (
              <Button
                onClick={() => {
                  setState({
                    loading: false,
                    name: state.name,
                    nameErrorText: '',
                    email: state.email,
                    emailErrorText: '',
                    message: state.message,
                    messageErrorText: '',
                    isSubmitted: false,
                    status: 'NOT_DISPATCHED',
                  })
                }}
                extraClasses='font-semibold text-white mt-4'
                withIcon={false}
                transparent={false}
                text='tryAgain'
              />
            ) : (
              <Button
                onClick={() => onClick()}
                extraClasses='font-semibold text-white mt-2'
                withIcon={false}
                transparent={false}
                text={state.loading ? 'loading' : 'send'}
              />
            )}
          </div>
          {payment && (
            <div className='font-bold text-sm sm:text-[22px] leading-8 text-blue text-center mt-2 md:mb-20 md:hidden block'>
              <Msg
                id='socialMediaRecruiting.contactUsForm.waitingList'
                defaultMessage='You will be added to our waiting list.'
              />
            </div>
          )}
        </div>
        {payment && (
          <div className='mt--5 basis-1/2 grid gap-6 content-between'>
            <div className='mx-auto font-semibold text-sm sm:text-xl leading-8'>
              <Msg id={`${page}.${section}.${card}.title`} defaultMessage='title' />
            </div>
            <img
              className='w-[261px] h-[210px] mx-auto hidden md:block'
              src={image}
              alt='About Us'
              title='About Us'
              loading='lazy'
            />
            <div className='font-bold text-sm sm:text-[22px] leading-8 text-blue text-center md:mb-20 hidden md:block'>
              <Msg
                id='socialMediaRecruiting.contactUsForm.waitingList'
                defaultMessage='You will be added to our waiting list.'
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ContactUsForm.propTypes = {
  type: PropTypes.string,
  price: PropTypes.string,
  section: PropTypes.string,
  payment: PropTypes.bool,
  card: PropTypes.string,
  page: PropTypes.string,
  image: PropTypes.string,
}

export default ContactUsForm
