import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
import Typewriter from 'typewriter-effect'

import Button from '../../common/inputs/Button/Button'
import { trackEvent } from '../../../matomo'

const Hero = ({ heroText, setShowCalendlyContactForm }) => {
  const intl = useIntl()

  return (
    <>
      {heroText ? (
        <div
          id='hero-text'
          className='page-wrapper h-[300px] bg-hero flex justify-center items-center'
        >
          <div className='h-auto w-auto py-[5%] px-[10%] sm:py-[50px] sm:px-[100px] bg-gprimary1 absolute rounded-[20px] backdrop-blur-[28px]'>
            <div>
              <h1 className='font-semibold text-xl leading-[30px] sm:leading-[54px] sm:font-semibold sm:text-4xl'>
                {heroText}
              </h1>
            </div>
          </div>
        </div>
      ) : (
        <div
          id='hero-text'
          className='page-wrapper h-[527px] md:h-[545px] bg-hero flex justify-center items-center'
        >
          <div className='h-[221px] w-[290px] sm:h-[341px] sm:w-[565px] bg-gprimary1 absolute rounded-[20px] backdrop-blur-[28px]'>
            <div>
              <h1 className='pt-5 sm:pt-[39px] pl-5 sm:pl-0 not-italic font-semibold text-xl leading-[30px] sm:leading-[54px] sm:not-italic sm:font-semibold sm:text-4xl sm:text-center'>
                <Msg id='company.hero.firstText' />
                <br />
                <Msg id='company.hero.secondText' />
                <br />
                <span className='w-[270px] sm:w-[564px]' translate='no'>
                  <Typewriter
                    options={{
                      strings: [
                        intl.formatMessage({ id: 'company.hero.webDesign' }),
                        intl.formatMessage({ id: 'company.hero.softwareDevelopment' }),
                        intl.formatMessage({ id: 'company.hero.socialMediaRecruiting' }),
                        intl.formatMessage({ id: 'company.hero.seo' }),
                      ],
                      autoStart: true,
                      loop: true,
                      wrapperClassName:
                        'text-xl sm:text-4xl underline decoration-blue decoration-4',
                    }}
                  />
                </span>
              </h1>
              <div className='flex justify-center' id='hero-btn'>
                <Button
                  onClick={() => {
                    trackEvent('Home Page', 'Click', 'home_hero_btn_click')
                    setShowCalendlyContactForm(true)
                  }}
                  extraClasses='mx-5 text-white mt-[40px] sm:mt-[32px] h-[51px] text-sm not-italic font-semibold sm:text-base'
                  withIcon={true}
                  transparent={false}
                  text='consultation'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

Hero.propTypes = {
  heroText: PropTypes.string,
  setShowCalendlyContactForm: PropTypes.func,
}

export default Hero
