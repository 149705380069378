import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import BlogCover from '../../../assets/images/blog/blog_cover.webp'

const Hero = () => {
  return (
    <div
      id='hero-text-web-design'
      className='relative hero h-[391px] flex items-center justify-center max-w-[1174px] mx-auto bg-cover mb-[68px] md:mb-[94px]'
      style={{
        backgroundImage: `url(${BlogCover})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className='relative z-10 px-[35px] md:px-10 lg:px-[200px] text-center '>
        <h1 className='not-italic font-semibold text-xl leading-[30px] md:text-4xl md:leading-[54px] text-white'>
          <Msg
            id='blog.title'
            defaultMessage='Blog by Nicolas Welitzki IT-Consulting - modern and exciting IT topics'
          />
        </h1>
      </div>
    </div>
  )
}

export default Hero
