import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import close from '../../../../assets/images/close.svg'

const ModalPopUp = ({ children, showModal, extraClass, hasWhiteHeader = false, setShowModal }) => {
  document.addEventListener('keydown', function (event) {
    const { key } = event
    if (key === 'Escape') {
      setShowModal(false)
    }
  })
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [showModal])

  return (
    <div
      id='modalPopUp'
      className={`${showModal === false ? 'hidden ' : ''}${
        hasWhiteHeader ? 'ci:bg-sky bg-white' : 'bg-sky'
      } fixed z-[40]`}
    >
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div
          data-testid='closePopUp'
          onClick={() => setShowModal(false)}
          className='opacity-50 fixed inset-0 z--1 bg-black'
        ></div>
        <div
          className={`w-[90%] sm:w-auto rounded-lg relative my-6 ${extraClass ?? ''} ${
            hasWhiteHeader ? 'ci:bg-sky bg-white' : 'bg-sky'
          }`}
        >
          <div className='border-0 relative flex flex-col w-full outline-none focus:outline-none'>
            <div className='flex rounded-t pb-2'>
              <button className='pt-[25px] pr-[25px] ml-auto' onClick={() => setShowModal(false)}>
                <img
                  src={close}
                  className='w-[30px] h-[30px]'
                  alt='Icon to'
                  title='Close'
                  loading='lazy'
                />
                <span className='sr-only'>Close modal</span>
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

ModalPopUp.propTypes = {
  children: PropTypes.any.isRequired,
  showModal: PropTypes.bool,
  extraClass: PropTypes.string,
  hasWhiteHeader: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
}
export default ModalPopUp
