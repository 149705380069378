import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const RelatedPost = ({ title, category, slug, coverImageURL }) => {
  return (
    <>
      <Link to={`/post/${slug}`}>
        <div data-testid='post-card' className='mx-auto post__card w-[260px] lg:w-[327px] pb-5'>
          <div className='px-[20px] pt-[31px]'>
            <img
              src={coverImageURL}
              alt='post cover'
              className='max-w-full h-auto object-cover mx-auto max-w[284px] max-h-[166px]'
            />
          </div>

          <div className='pt-[10px]'>
            <p className='mx-auto bg-blue w-[100px] h-[30px] not-italic font-medium text-xs leading-[18px] text-center md:text-sm md:leading-[21px] text-white pt-1'>
              {category}
            </p>
          </div>
          <h3
            className='not-italic font-medium text-lg leading-[27px] text-center text-black px-[20px] pt-[18px]'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </div>
      </Link>
    </>
  )
}

RelatedPost.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  slug: PropTypes.string,
  coverImageURL: PropTypes.string,
}

export default RelatedPost
