import React from 'react'
import PropTypes from 'prop-types'

import ModalPopUp from '../ModalPopUp/ModalPopUp'
import ContactUsForm from '../../../socialMediaRecruiting/section/ContactUsForm/ContactUsForm'

const ModalContactus = ({ showModal, setShowModal }) => (
  <ModalPopUp
    showModal={showModal}
    setShowModal={setShowModal}
    extraClass='min-h-[474px] my-auto mt-auto sm:my-auto'
  >
    <ContactUsForm type='popUp' />
  </ModalPopUp>
)

ModalContactus.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}

export default ModalContactus
