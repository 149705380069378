import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PropTypes from 'prop-types'
import Logo from '../../assets/images/metaLogo.svg'

const MetaData = ({ title, description, keywords, link = '/' }) => (
  <HelmetProvider>
    <Helmet>
      <title>{title}</title>
      <meta name='title' content={title} />
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />

      {/* // facebook open graph  */}
      <meta property='og:site_name' content='Nicolas Welitzki IT-Consulting' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={Logo} />
      {/* Twitter card */}
      <meta property='twitter:card' content='summary' />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image' content={Logo} />

      <link rel='canonical' href={`https://welitzki-it.de${link}`} />
      {link !== '/' && <link rel='prev' href='https://www.welitzki-it.de/' />}
    </Helmet>
  </HelmetProvider>
)

MetaData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  link: PropTypes.string,
}

export default MetaData
