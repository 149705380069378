import React from 'react'
import PropTypes from 'prop-types'
import { InlineWidget } from 'react-calendly'
import { FormattedMessage as Msg } from 'react-intl'
import ModalPopUp from './ModalPopUp'

const ModalCalendly = ({ showModal, hasAcceptedFunctionalCookie, setShowModal }) => (
  <ModalPopUp
    showModal={showModal}
    setShowModal={setShowModal}
    hasWhiteHeader={true}
    extraClass='max-w-[1200px] w-[90%] sm:h-[900px] sm:w-[700px] md:w-[700px] lg:w-[1010px] min-h-[474px] mt-auto sm:my-auto'
  >
    <div
      className={
        hasAcceptedFunctionalCookie
          ? 'hidden'
          : 'h3 text-lg lg:text-2xl xl:text-4xl text-center text-darkGray pt-[5%] px-[35px]'
      }
    >
      <Msg id='company.common.acceptCookie' />
    </div>
    <div
      id='calendly'
      data-testid='calendlyPopUp'
      className={`calendly-modal calendly__width ${
        hasAcceptedFunctionalCookie ? '' : 'blur-sm pointer-events-none'
      }`}
    >
      {hasAcceptedFunctionalCookie && (
        <InlineWidget
          url='https://calendly.com/welitzki-it/30min'
          pageSettings={{ hideGdprBanner: true }}
        />
      )}
    </div>
  </ModalPopUp>
)
ModalCalendly.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hasAcceptedFunctionalCookie: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
}

export default ModalCalendly
