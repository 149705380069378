import React from 'react'
import PropTypes from 'prop-types'
import { scroller } from 'react-scroll'
import { FormattedMessage as Msg } from 'react-intl'
import Button from '../../../common/inputs/Button/Button'
import { trackEvent } from '../../../../matomo'

const CallToAction = ({ type, page, categoryName }) => {
  let bgcolor = ''
  if (type) {
    bgcolor = 'primaryCTA'
    if (type === 'secondary') bgcolor = 'secondaryCTA'
    if (type === 'solid') bgcolor = 'bg-secondary'
  }
  const eventTracking = () => {
    let eventName
    if (categoryName === 'WebDevelopment Page') {
      eventName = 'wdev_calltoaction_btn_click'
    } else {
      eventName = 'other_calltoaction_btn_click'
    }
    trackEvent(categoryName, 'click', eventName)
  }

  return (
    <div className={`grid grid-cols-1 w-full gap-0 px-10 md:px-10 lg:px-32 py-4 ${bgcolor}`}>
      <div className='flex-col max-w-[1190px] px-4 sm:px-6 pt-[23px] pb-[29px] sm:py-[45px] bg-blue rounded-xl justify-evenly align-middle sm:justify-items-center'>
        <div className='mb-[28px] md:mb-[25px] lg:mb-6 font-semibold text-white text-left '>
          <div className='leading-[33px] md:leading-[40px] lg:leading-[54px] text-[22px] md:text-3xl xl:text-[36px]'>
            <Msg
              id={`${page}.callToAction.heading1`}
              className='mb-[12px] md:mb-0'
              defaultMessage='Ready to find new professionals?'
            />
            <div className='mt-3'>
              <Msg id={`${page}.callToAction.heading2`} defaultMessage='Make an appointment now' />
            </div>
          </div>
        </div>
        <Button
          transparent={true}
          withIcon={true}
          onClick={() => {
            eventTracking()
            scroller.scrollTo('contactus', {
              duration: 150,
              delay: 100,
              smooth: true,
            })
          }}
          extraClasses='lg:ml-0 mx-auto text-blue text-left font-semibold bg-white h-[51px]'
          text='consultation'
        />
      </div>
    </div>
  )
}

CallToAction.propTypes = {
  type: PropTypes.string,
  page: PropTypes.string,
  categoryName: PropTypes.string,
}

export default CallToAction
