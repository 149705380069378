import React from 'react'

const PostCardSkeleton = () => {
  return (
    <>
      <div
        data-testid='PostCardSkeleton'
        className='mx-auto post__card w-[290px] h-[544px] md:w-[365px] md:h-[538px]'
      >
        <p className='loading-skeleton w-[237px] h-[186px] md:w-[299px] md:h-[178px] object-cover mx-auto mt-8' />
        <p className='loading-skeleton mx-auto w-[100px] h-[30px] mt-2' />
        <p className='loading-skeleton md:px-[33px] md:pt-7 w-4/6 mx-auto h-[70px] my-2' />
        <p className='loading-skeleton md:px-[33px] mx-auto w-5/6 h-[150px]' />
      </div>
    </>
  )
}

export default PostCardSkeleton
