import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
import check from '../../../../assets/images/check.svg'

const WDServiceCard = ({ card, image, id }) => {
  const intl = useIntl()

  return (
    <div className='bg-secondary rounded-3xl howwework__card w-[291px] h-[423px] sm:w-[554px] sm:h-[230px] mx-auto'>
      <div className='flex flex-col-reverse justify-end sm:justify-between gap-0 sm:gap-5 sm:flex-row'>
        <div className='pl-[20px] sm:pl-[27px] pt-0 sm:pt-[33px]'>
          <h4 className='not-italic font-bold text-[22px] leading-[33px] text-center sm:text-left'>
            <Msg id={`webDevelopment.services.${card}.heading`} />
          </h4>

          <div className='flex items-center my-3'>
            <img
              src={check}
              alt='Our Services check'
              title='Our Services check'
              loading='lazy'
              className='w-[30px] h-[30px]'
            />
            <p className='text-[14px] sm:text-[16px] place-self-center sm:place-self-start font-normal w-[194px] sm:w-[229px] pl-5 leading-[23.8px] sm:leading[27px]'>
              <Msg id={`webDevelopment.services.${card}.services.first`} />
            </p>
          </div>
          <div className='flex items-center my-3'>
            <img
              src={check}
              alt='Our Services check'
              title='Our Services check'
              loading='lazy'
              className='w-[30px] h-[30px]'
            />
            <p className='text-[14px] sm:text-[16px] place-self-center sm:place-self-start font-normal w-[194px] sm:w-[229px] pl-5 leading-[23.8px] sm:leading[27px]'>
              <Msg id={`webDevelopment.services.${card}.services.second`} />
            </p>
          </div>
          {id === 1 && (
            <div className='flex items-center my-3'>
              <img
                src={check}
                alt='Our Services check'
                title='Our Services check'
                loading='lazy'
                className='w-[30px] h-[30px]'
              />
              <p className='text-[14px] sm:text-[16px] place-self-center sm:place-self-start font-normal w-[194px] sm:w-[229px] pl-5 leading-[23.8px] sm:leading[27px]'>
                <Msg id={`webDevelopment.services.${card}.services.third`} />
              </p>
            </div>
          )}
        </div>
        <div className='flex align-bottom justify-center items-center h-[210px] sm:h-[230px] pr-0 sm:pr-4 '>
          <img
            src={image}
            alt={intl.formatMessage({ id: `webDevelopment.services.${card}.heading` })}
            title={intl.formatMessage({ id: `webDevelopment.services.${card}.heading` })}
            loading='lazy'
            className='w-[210px] sm:w-[218px]'
          />
        </div>
      </div>
    </div>
  )
}

WDServiceCard.propTypes = {
  card: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}
export default WDServiceCard
