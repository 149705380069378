import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import aboutUsNic from '../../../../assets/images/aboutUsNic.webp'
import Button from '../../../common/inputs/Button/Button'
import { trackEvent } from '../../../../matomo'

const AboutUs = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className='section bg-secondary' id='ueber_uns'>
        <h2>
          <Msg id='socialMediaRecruiting.aboutUs.title' defaultMessage='About us' />
        </h2>
        <div className='flex justify-center p-auto pb-3 md:pb-10 text-center md:text-left sm:hidden'>
          <img
            src={aboutUsNic}
            alt='About us Image'
            title='About us Image'
            loading='lazy'
            className='w-40 h-56 rounded-sm'
          />
        </div>
        <div className='grid sm:grid-cols-2 pb-[50px]'>
          <div className='sm:ml-16 sm:mr-4 mx-6 grid content-center'>
            <div className='text-center font-bold text-base sm:text-2xl leading-6 sm:text-left mb-8'>
              <div className='sm:inline-block sm:mr-1'> Nicolas Welitzki </div>
              <div className='sm:inline-block'>
                <Msg id='socialMediaRecruiting.aboutUs.position' defaultMessage='Company owner' />
              </div>
            </div>
            <div className='font-normal text-base'>
              <div className='mb-8'>
                <span className='text-blueLight pr-1'>Nicolas Welitzki</span>
                <Msg id='socialMediaRecruiting.aboutUs.test1' defaultMessage='has since 2011...' />
              </div>
              <div className='mb-8'>
                <Msg
                  id='socialMediaRecruiting.aboutUs.test2'
                  defaultMessage='In the process, he was able to gain a wealth of experience in...'
                />
              </div>
              <div className='mb-8'>
                <Msg
                  id='socialMediaRecruiting.aboutUs.test3'
                  defaultMessage='He works together with you the ...'
                />
              </div>
            </div>
            <div>
              <Button
                onClick={() => {
                  trackEvent('SocialMediaRecruiting Page', 'Click', 'smr_learn_more_btn_click')
                  navigate('/')
                }}
                extraClasses='font-semibold'
                transparent={false}
                border={false}
                withIcon={true}
                text='ourServices'
              />
            </div>
          </div>
          <div className='sm:flex justify-end mr-16 hidden'>
            <img
              src={aboutUsNic}
              alt='Nicolas Welitzki'
              title='Nicolas Welitzki'
              loading='lazy'
              className='w-[371px] h-[529px] rounded-sm'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
