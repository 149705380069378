export const messages = {
  message: {
    Error: 'Error',
    routeError: {
      messageOne: 'Die gesuchte Seite existiert nicht.',
      messageTwo: 'Aber keine Sorge, auf unserer Homepage finden Sie noch viel mehr.',
    },
  },
  common: {
    button: {
      takeToHome: 'Zurück zur Startseite',
      add: 'Neu',
      cancel: 'Abbrechen',
      new: 'Neu',
      delete: 'Löschen',
      edit: 'Ändern',
      goBack: 'zurück',
      toMain: 'Startseite',
      consultation: 'Reservieren Sie Ihren Platz',
      imprint: 'Impressum',
      privacy: 'Datenschutz',
      howwework: 'Termin buchen',
      send: 'Abschicken',
      tryAgain: 'Versuchen Sie es nochmal',
      newResponse: 'Nochmal abschicken',
      loading: 'Ihre Nachricht wird versendet ...',
      ourServices: 'Mehr erfahren',
      product1: 'Jetzt starten',
      product2: 'Zu den Paketen',
      product3: 'Jetzt absichern',
      signUp: 'Updates erhalten',
      inquiry: 'Jetzt anfragen',
      viewAllBlog: 'weitere Blog-Artikel',
      webBoost: {
        card1: 'Jetzt starten',
        card2: 'Jetzt starten',
        card3: 'Jetzt starten',
        card4: 'Jetzt starten',
      },
    },
    slogan: {
      text: 'Schneller geladen - Besser gefunden - \n Einfach mehr Kunden',
    },
  },
  socialMediaRecruiting: {
    navbar: {
      start: 'Start',
      strategy: 'Strategie',
      procedure: 'Ablauf',
      references: 'Referenzen',
      aboutUs: 'Über uns',
    },
    hero: {
      firstText: 'Revolutionieren Sie Ihr Personal-Recruiting und gewinnen Sie ',
      secondText: 'qualifizierte Fachkräfte',
      lastText: ' für sich.',
      button: 'Reservieren Sie Ihren Platz',
    },
    callToAction: {
      heading1: 'Bereit neue Fachkräfte zu finden?',
      heading2: 'Jetzt Termin vereinbaren',
    },
    aboutUs: {
      title: 'Über uns',
      position: '(Inhaber)',
      test1:
        'hat seit 2011 als Spezialist für Business Intelligence bei diversen Projekten in den Branchen E-Commerce, Finance und Automotive mitgewirkt.',
      test2:
        'Dabei konnte er sich einen umfassenden Erfahrungsschatz hinsichtlich der Erstellung fachlicher Konzepte aneignen, den er nun einsetzt, um das Personal-Recruiting von Handwerks- und Technologieunternehmen grundlegend zu optimieren.',
      test3:
        'Er arbeitet gemeinsam mit Ihnen die Alleinstellungsmerkmale Ihres Unternehmens heraus und verwendet sie in Social-Media-Ads, um Ihre potenziellen Mitarbeiter gezielt anzusprechen.',
      quote: '„Nichts ist so beständig wie der Wandel.“',
      author: 'Heraklit von Ephesus, 535-475 v. Chr.',
    },
    contactUs: {
      title: 'Kontakt',
      details: 'Lernen Sie mehr über Social-Recruiting in einem kurzen Gespräch mit mir',
      acceptMessage:
        'Wir setzen Cookies ein, um die Nutzung unserer Webseiten zu analysieren, einschließlich des Such- und Surfverlaufs, Suchbegriffen und Ihnen auf Ihr Nutzungsverhalten angepasste Informationen anbieten zu können.',
      essentialCookie: 'Essentiell',
      marketingCookie: 'Marketing',
      statisticsCookie: 'Statistik',
      functionalCookie: 'Funktionell',
      acceptButton: 'Akzeptiere alle',
      openPreferencesButton: 'Cookie-Einstellungen',
      saveCookieSettingsButton: 'Speichern',
    },
    imprint: {
      title: 'Impressum',
      heading1: 'Angaben gemäß § 5 TMG:',
      heading2: 'Kontakt',
      heading3: 'Umsatzsteuer-ID',
      heading4: 'Redaktionell verantwortlich',
      heading5: 'Verbraucherstreitbeilegung/ Universalschlichtungsstelle',
      content1: 'Telefon: +49 151 23246715',
      content2: 'Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:',
      content3:
        'Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
      seo: {
        title: 'Impressum –Rechtliche Informationen & Kontaktabgaben',
        description:
          'Entdecken Sie die wesentlichen rechtlichen Informationen, Kontaktangaben und verantwortlichen Personen für Nicolas Welitzki IT-Beratung. In Deutschland ansässig, sind wir der Transparenz und Einhaltung deutscher Vorschriften verpflichtet.',
        keywords:
          'Impressum, rechtliche Informationen, Kontaktangaben, deutsche Vorschriften, verantwortliche Personen, Haftung, Website-Betreiber, Offenlegung',
      },
    },
    privacy: {
      title: 'Datenschutz',
      one: {
        mainHeading: '1. Datenschutz auf einen Blick',
        heading1: 'Allgemeine Hinweise',
        para1:
          'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten\n' +
          'passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie\n' +
          'persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.',
        heading2: 'Datenerfassung auf dieser Website',
        subHeading1: 'Wer ist verantwortlich für die Datenerfassung auf dieser Website?',
        para2:
          'Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.',
        subHeading2: 'Wie erfassen wir Ihre Daten?',
        para3:
          'Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.',
        para4:
          'Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.',
        subHeading3: 'Wofür nutzen wir Ihre Daten?',
        para5:
          'Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.',
        subHeading4: 'Welche Rechte haben Sie bezüglich Ihrer Daten?',
        para6:
          'Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.',
        para7:
          'Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.',
        heading3: 'Analyse-Tools und Tools von Drittanbietern',
        para8:
          'Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor\n' +
          'allem mit sogenannten Analyseprogrammen.',
        para9:
          'Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden\n' +
          'Datenschutzerklärung',
      },
      two: {
        mainHeading: '2. Hosting',
        para1: 'Wir hosten die Inhalte unserer Website bei folgendem Anbieter:',
        heading1: 'Externes Hosting',
        para2:
          'Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.',
        para3:
          'Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten\n' +
          'Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).\n' +
          'Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf\n' +
          'Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung\n' +
          'von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im\n' +
          'Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.',
        para4:
          'Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen..',
        para5: 'Wir setzen folgende(n) Hoster ein:',
        line1: 'united-domains AG',
        line2: 'Gautinger Straße 10',
        line3: '82319 Starnberg',
        line4: 'Deutschland',
      },
      three: {
        mainHeading: '3. Allgemeine Hinweise und Pflichtinformationen',
        heading1: 'Datenschutz',
        para1:
          'Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.',
        para2:
          'Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.',
        para3:
          'Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.',
        heading2: 'Hinweis zur verantwortlichen Stelle',
        para4: 'Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: ',
        para5:
          'Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.',
        heading3: 'Speicherdauer',
        para6:
          'Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben\n' +
          'Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein\n' +
          'berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer\n' +
          'personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im\n' +
          'letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.',
        heading4:
          'Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser\n' +
          'Website',
        para7:
          'Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf\n' +
          'Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien\n' +
          'nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung\n' +
          'personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.\n' +
          '49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in\n' +
          'Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich\n' +
          'auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur\n' +
          'Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre\n' +
          'Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese\n' +
          'zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.\n' +
          'Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f\n' +
          'DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden\n' +
          'Absätzen dieser Datenschutzerklärung informiert.',
        heading5: 'Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten',
        para8:
          'Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen\n' +
          'datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre\n' +
          'personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf\n' +
          'hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann.\n' +
          'Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden\n' +
          'herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht\n' +
          'ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu\n' +
          'Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese\n' +
          'Verarbeitungstätigkeiten keinen Einfluss.',
        heading6: 'Widerruf Ihrer Einwilligung zur Datenverarbeitung',
        para9:
          'Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine\n' +
          'bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten\n' +
          'Datenverarbeitung bleibt vom Widerruf unberührt.',
        heading7:
          'Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen\n' +
          'Direktwerbung (Art. 21 DSGVO)',
        para10:
          'WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO\n' +
          'ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN\n' +
          'SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN\n' +
          'WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES\n' +
          'PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,\n' +
          'ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,\n' +
          'WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES\n' +
          'SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG\n' +
          'NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE\n' +
          'VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON\n' +
          'RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).',
        para11:
          'WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,\n' +
          'SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE\n' +
          'BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG\n' +
          'EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN\n' +
          'VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN\n' +
          'ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH\n' +
          'NACH ART. 21 ABS. 2 DSGVO).',
        heading8: 'Beschwerderecht bei der zuständigen Aufsichtsbehörde',
        para12:
          'Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer\n' +
          'Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes\n' +
          'oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger\n' +
          'verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.',
        heading9: 'Recht auf Datenübertragbarkeit',
        para13:
          'Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags\n' +
          'automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format\n' +
          'aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen\n' +
          'verlangen, erfolgt dies nur, soweit es technisch machbar ist.',
        heading10: 'Auskunft, Löschung und Berichtigung',
        para14:
          'Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche\n' +
          'Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den\n' +
          'Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie\n' +
          'zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.',
        heading11: 'Recht auf Einschränkung der Verarbeitung',
        para15:
          'Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.\n' +
          'Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in\n' +
          'folgenden Fällen:',
        line1:
          'Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir\n' +
          'in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die\n' +
          'Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.',
        line2:
          'Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie\n' +
          'statt der Löschung die Einschränkung der Datenverarbeitung verlangen.',
        line3:
          'Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,\n' +
          'Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der\n' +
          'Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.',
        line4:
          'Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen\n' +
          'Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen\n' +
          'überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten\n' +
          'zu verlangen.',
        para16:
          'Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von\n' +
          'ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder\n' +
          'Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder\n' +
          'juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder\n' +
          'eines Mitgliedstaats verarbeitet werden.',
        heading12: 'SSL- bzw. TLS-Verschlüsselung',
        para17:
          'Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum\n' +
          'Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von\n' +
          '„http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.',
        para18:
          'Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht\n' +
          'von Dritten mitgelesen werden.',
      },
      four: {
        mainHeading: '4. Datenerfassung auf dieser Website',
        heading1: 'Cookies',
        para1:
          'Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf\n' +
          'Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung\n' +
          '(Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies\n' +
          'werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät\n' +
          'gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.',
        para2:
          'Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie\n' +
          'unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter\n' +
          'Dienstleistungen des Drittunternehmens (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).',
        para3:
          'Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte\n' +
          'Websitefunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige\n' +
          'von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.',
        para4:
          'Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung\n' +
          'bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der\n' +
          'Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf\n' +
          'Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird.\n' +
          'Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur\n' +
          'technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur\n' +
          'Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die\n' +
          'Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1\n' +
          'TTDSG); die Einwilligung ist jederzeit widerrufbar.',
        para5:
          'Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und\n' +
          'Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen\n' +
          'sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der\n' +
          'Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.',
        para6:
          'Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber\n' +
          'im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.',
        heading2: 'Kontaktformular',
        para7:
          'Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem\n' +
          'Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage\n' +
          'und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre\n' +
          'Einwilligung weiter.',
        para8:
          'Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen\n' +
          'erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der\n' +
          'effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer\n' +
          'Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit\n' +
          'widerrufbar.',
        para9:
          'Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung\n' +
          'auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt\n' +
          '(z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –\n' +
          'insbesondere Aufbewahrungsfristen – bleiben unberührt.',
        heading3: 'Anfrage per E-Mail, Telefon oder Telefax',
        para10:
          'Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus\n' +
          'hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens\n' +
          'bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.',
        para11:
          'Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit\n' +
          'der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen\n' +
          'erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der\n' +
          'effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer\n' +
          'Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit\n' +
          'widerrufbar.',
        para12:
          'Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung\n' +
          'auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt\n' +
          '(z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –\n' +
          'insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.',
        heading4: 'Calendly',
        para13:
          'Auf unserer Website haben Sie die Möglichkeit, Termine mit uns zu vereinbaren. Für die Terminbuchung\n' +
          'nutzen wir das Tool „Calendly“. Anbieter ist die Calendly LLC, 271 17th St NW, 10th Floor, Atlanta, Georgia\n' +
          '30363, USA (nachfolgend „Calendly“).',
        para14:
          'Zum Zweck der Terminbuchung geben Sie die abgefragten Daten und den Wunschtermin in die dafür\n' +
          'vorgesehene Maske ein. Die eingegebenen Daten werden für die Planung, Durchführung und ggf. für die\n' +
          'Nachbereitung des Termins verwendet. Die Termindaten werden für uns auf den Servern von Calendly\n' +
          'gespeichert, dessen Datenschutzerklärung Sie hier einsehen können:',
        para15:
          'Die von Ihnen eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung\n' +
          'zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche\n' +
          'Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.',
        para16:
          'Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein\n' +
          'berechtigtes Interesse an einer möglichst unkomplizierten Terminvereinbarung mit Interessenten und\n' +
          'Kunden. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich\n' +
          'auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung\n' +
          'von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im\n' +
          'Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.',
        para17:
          'Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.\n' +
          'Details finden Sie hier:',
        heading5: 'ProvenExpert',
        para18:
          'Wir haben Bewertungssiegel von ProvenExpert auf dieser Website eingebunden. Anbieter ist Expert\n' +
          'Systems AG, Quedlinburger Str. 1, 10589 Berlin,',
        para19:
          'Das ProvenExpert-Siegel ermöglicht es uns, Kundenbewertungen, die bei ProvenExpert zu unserem\n' +
          'Unternehmen abgegeben wurden, auf unserer Website in einem Siegel darzustellen. Wenn Sie unsere\n' +
          'Website besuchen, wird eine Verbindung mit ProvenExpert hergestellt, sodass ProvenExpert feststellen\n' +
          'kann, dass Sie unsere Website besucht haben. Ferner erfasst ProvenExpert Ihre Spracheinstellungen, um\n' +
          'das Siegel in der gewählten Landessprache anzuzeigen.',
        para20:
          'Die Verwendung von ProvenExpert erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der\n' +
          'Websitebetreiber hat ein berechtigtes Interesse an einer möglichst nachvollziehbaren Darstellung von\n' +
          'Kundenbewertungen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung\n' +
          'ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung\n' +
          'die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. DeviceFingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.',
        heading6: 'Visable GmbH',
        para21:
          'Wir setzt zu Analyse- und Marketingzwecken Produkte und Dienstleistungen ein, die in Kooperation mit der Visable GmbH (www.visable.com) von dieser zur Verfügung gestellt werden. Hierzu erfolgt mittels Zählpixeltechnologie die Erhebung, Verarbeitung und Speicherung von Daten zur Erstellung mindestens pseudonymisierter, wo möglich und sinnvoll vollständig anonymisierter Nutzungsprofile. Die erhobenen Daten, die zunächst noch personenbezogene Daten beinhalten können, werden an Visable übermittelt oder direkt durch Visable erhoben und dort zur Erstellung der o.a. Nutzungsprofile verwendet. Eine persönliche Identifikation von Besuchern dieser Webseite findet nicht statt, und es werden auch keine sonstigen personenbezogenen Daten mit den Nutzungsprofilen zusammengeführt. Sollten IP-Adressen als personenbezogen identifiziert werden, so werden sie umgehend gelöscht. Den hier geschilderten Formen der Verarbeitung können Sie jederzeit mit Wirkung für die Zukunft widersprechen:',
        linkText1: 'Ihre Besuche werden nun nicht mehr erfasst.',
        linkText2:
          'Besuchererfassung ausschließen (Anmerkung: Link setzt ein 1st-Party-Cookie für einen Opt-Out)',
      },
      five: {
        mainHeading: '5. Analyse-Tools und Werbung',
        heading1: 'Matomo',
        para1: 'Diese Website benutzt den Open Source Webanalysedienst Matomo.',
        para2:
          'Mit Hilfe von Matomo sind wir in der Lage Daten über die Nutzung unserer Website durch die\n' +
          'Websitebesucher zu erfassen und zu analysieren. Hierdurch können wir u. a. herausfinden, wann welche\n' +
          'Seitenaufrufe getätigt wurden und aus welcher Region sie kommen. Außerdem erfassen wir verschiedene\n' +
          'Logdateien (z. B. IP-Adresse, Referrer, verwendete Browser und Betriebssysteme) und können messen, ob\n' +
          'unsere Websitebesucher bestimmte Aktionen durchführen (z. B. Klicks, Käufe u. Ä.).',
        para3:
          'Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der\n' +
          'Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein\n' +
          'Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt\n' +
          'wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1\n' +
          'TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im\n' +
          'Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist\n' +
          'jederzeit widerrufbar.',
        subHeading1: 'IP-Anonymisierung',
        para4:
          'Bei der Analyse mit Matomo setzen wir IP-Anonymisierung ein. Hierbei wird Ihre IP-Adresse vor der\n' +
          'Analyse gekürzt, sodass Sie Ihnen nicht mehr eindeutig zuordenbar ist.',
        subHeading2: 'Hosting',
        para5:
          'Wir hosten Matomo ausschließlich auf unseren eigenen Servern, sodass alle Analysedaten bei uns\n' +
          'verbleiben und nicht weitergegeben werden.',
      },
      six: {
        mainHeading: '6. Newsletter',
        heading1: 'Newsletterdaten',
        para1:
          'Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der\n' +
          'angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere\n' +
          'Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für\n' +
          'den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.',
        para2:
          'Die Verarbeitung der in das Newsletter-Anmeldeformular eingegebenen Daten erfolgt ausschließlich auf\n' +
          'Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der\n' +
          'Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit\n' +
          'widerrufen, etwa über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten\n' +
          'Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.',
        para3:
          'Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer\n' +
          'Austragung aus dem Newsletter bei uns bzw. dem Newsletter-Diensteanbieter gespeichert und nach der\n' +
          'Abbestellung des Newsletters oder nach Zweckfortfall aus der Newsletter-Verteilerliste gelöscht. Wir\n' +
          'behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen\n' +
          'unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren.',
        para4:
          'Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unberührt.',
        para5:
          'Nach Ihrer Austragung aus der Newsletter-Verteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem\n' +
          'Newsletter-Diensteanbieter ggf. in einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger\n' +
          'Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht mit\n' +
          'anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der\n' +
          'Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des\n' +
          'Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.',
        line1:
          ' Sie können der\n' +
          'Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse überwiegen.',
      },
      seven: {
        mainHeading: '7. Audio- und Videokonferenzen',
        subHeading1: 'Datenverarbeitung',
        para1:
          'Für die Kommunikation mit unseren Kunden setzen wir unter anderen Online-Konferenz-Tools ein. Die im\n' +
          'Einzelnen von uns genutzten Tools sind unten aufgelistet. Wenn Sie mit uns per Video- oder Audiokonferenz\n' +
          'via Internet kommunizieren, werden Ihre personenbezogenen Daten von uns und dem Anbieter des\n' +
          'jeweiligen Konferenz-Tools erfasst und verarbeitet. Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der Konferenz,\n' +
          'Beginn und Ende (Zeit) der Teilnahme an der Konferenz, Anzahl der Teilnehmer und sonstige\n' +
          '„Kontextinformationen“ im Zusammenhang mit dem Kommunikationsvorgang (Metadaten).',
        para2:
          'Des Weiteren verarbeitet der Anbieter des Tools alle technischen Daten, die zur Abwicklung der Online-Kommunikation erforderlich sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen, Geräte-IDs,\n' +
          'Gerätetyp, Betriebssystemtyp und -version, Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie\n' +
          'die Art der Verbindung.',
        para3:
          'Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in sonstiger Weise bereitgestellt\n' +
          'werden, werden diese ebenfalls auf den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten zählen\n' +
          'insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten, Voicemails hochgeladene Fotos und Videos,\n' +
          'Dateien, Whiteboards und andere Informationen, die während der Nutzung des Dienstes geteilt werden.',
        para4:
          'Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der\n' +
          'verwendeten Tools haben. Unsere Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik' +
          'des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung durch die Konferenztools entnehmen\n' +
          'Sie den Datenschutzerklärungen der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt haben.',
        subHeading2: 'Zweck und Rechtsgrundlagen',
        para5:
          'Die Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden Vertragspartnern zu\n' +
          'kommunizieren oder bestimmte Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b\n' +
          'DSGVO). Des Weiteren dient der Einsatz der Tools der allgemeinen Vereinfachung und Beschleunigung der\n' +
          'Kommunikation mit uns bzw. unserem Unternehmen (berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f\n' +
          'DSGVO). Soweit eine Einwilligung abgefragt wurde, erfolgt der Einsatz der betreffenden Tools auf\n' +
          'Grundlage dieser Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die Zukunft widerrufbar.',
        subHeading3: 'Speicherdauer',
        para6:
          'Die unmittelbar von uns über die Video- und Konferenz-Tools erfassten Daten werden von unseren\n' +
          'Systemen gelöscht, sobald Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen\n' +
          'oder der Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis\n' +
          'Sie sie löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben unberührt.',
        para7:
          'Auf die Speicherdauer Ihrer Daten, die von den Betreibern der Konferenz-Tools zu eigenen Zwecken\n' +
          'gespeichert werden, haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei\n' +
          'den Betreibern der Konferenz-Tools.',
        subHeading4: 'Eingesetzte Konferenz-Tools',
        para8: 'Wir setzen folgende Konferenz-Tools ein:',
        heading1: 'Zoom',
        para9:
          'Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom Communications Inc., San Jose, 55 Almaden\n' +
          'Boulevard, 6th Floor, San Jose, CA 95113, USA. Details zur Datenverarbeitung entnehmen Sie der\n' +
          'Datenschutzerklärung von Zoom:',
        para10:
          'Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.\n' +
          'Details finden Sie hier:',
      },
      eight: {
        mainHeading: '8. Eigene Dienste',
        heading1: 'Umgang mit Bewerberdaten',
        para1:
          'Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B. per E-Mail, postalisch oder via Online-Bewerberformular). Im Folgenden informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im\n' +
          'Rahmen des Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir versichern, dass die\n' +
          'Erhebung, Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit geltendem Datenschutzrecht\n' +
          'und allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt werden.',
        subHeading1: 'Umfang und Zweck der Datenerhebung',
        para2:
          'Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit verbundenen\n' +
          'personenbezogenen Daten (z. B. Kontakt- und Kommunikationsdaten, Bewerbungsunterlagen, Notizen im\n' +
          'Rahmen von Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die Begründung eines\n' +
          'Beschäftigungsverhältnisses erforderlich ist. Rechtsgrundlage hierfür ist § 26 BDSG nach deutschem Recht (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b DSGVO (allgemeine Vertragsanbahnung)\n' +
          'und – sofern Sie eine Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit\n' +
          'widerrufbar. Ihre personenbezogenen Daten werden innerhalb unseres Unternehmens ausschließlich an\n' +
          'Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt sind.',
        para3:
          'Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten auf Grundlage von § 26\n' +
          'BDSG und Art. 6 Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des Beschäftigungsverhältnisses in\n' +
          'unseren Datenverarbeitungssystemen gespeichert.',
        subHeading2: 'Aufbewahrungsdauer der Daten',
        para4:
          'Sofern wir Ihnen kein Stellenangebot machen können, Sie ein Stellenangebot ablehnen oder Ihre Bewerbung\n' +
          'zurückziehen, behalten wir uns das Recht vor, die von Ihnen übermittelten Daten auf Grundlage unserer\n' +
          'berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung des\n' +
          'Bewerbungsverfahrens (Ablehnung oder Zurückziehung der Bewerbung) bei uns aufzubewahren.\n' +
          'Anschließend werden die Daten gelöscht und die physischen Bewerbungsunterlagen vernichtet. Die\n' +
          'Aufbewahrung dient insbesondere Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich ist,\n' +
          'dass die Daten nach Ablauf der 6-Monatsfrist erforderlich sein werden (z. B. aufgrund eines drohenden oder\n' +
          'anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn der Zweck für die weitergehende\n' +
          'Aufbewahrung entfällt.',
        para5:
          'Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine entsprechende Einwilligung (Art. 6\n' +
          'Abs. 1 lit. a DSGVO) erteilt haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung\n' +
          'entgegenstehen.',
      },
      seo: {
        title: 'Datenschutzrichtlinie - Ihre Datenschutzinformationen',
        description:
          'Entdecken Sie unser Engagement für Datenschutz und Sicherheit. Unsere Datenschutzrichtlinie zeigt auf, wie wir Ihre persönlichen Informationen erfassen, verwenden und schützen. Erfahren Sie mehr über Ihre Rechte und Entscheidungsmöglichkeiten bezüglich Ihrer Daten',
        keywords:
          'Datenschutzrichtlinie, Datenschutz, persönliche Informationen, DSGVO, CCPA, Datensicherheit, Nutzerrechte, Datensicherheit, Cookie-Richtlinie, Informationsverarbeitung',
      },
    },
    strategySection: {
      title: 'Unsere Strategie',
      first: {
        title: 'Ziel definieren',
        content:
          'Die Grundlage unserer Zusammenarbeit bildet Ihr persönliches Ziel, das wir gemeinsam mit Ihnen definieren.',
      },
      second: {
        title: 'Vorteile ermitteln',
        content:
          'Wir arbeiten Ihre Alleinstellungsmerkmale als Arbeitgeber heraus und halten fest, welche konkreten Vorteile Sie Arbeitnehmern bieten.',
      },
      third: {
        title: 'Recruiting-Strategie',
        content:
          'Wir ermitteln Ihre individuelle Recruiting-Strategie, die Sie von Ihrer Konkurrenz abhebt und es Bewerbern erlaubt, sich schnell ein Bild von Ihnen zu machen.',
      },
      fourth: {
        title: 'Bewerbungsprozess',
        content:
          'Dank der arbeitnehmerfreundlichen Bewerbungs- und Einstellungsprozesse können sich Kandidaten innerhalb weniger Minuten bei Ihnen bewerben.',
      },
      fifth: {
        title: 'Langfristige Strategie',
        content:
          'Mit stetigen Marktanalysen behalten Sie aktuelle Strategien im Blick und sichern den langfristigen Erfolg Ihrer Recruiting-Kampagne.',
      },
    },
    socialrecrutingcards: {
      title: 'Warum Social-Recruiting?',
      cardOne: {
        heading: 'Das Problem mit Stellenanzeigen',
        paragraph:
          'Mit herkömmlichen Stellenanzeigen sprechen Sie nur die aktiv Jobsuchenden an, aber nicht die aktuell Beschäftigten.',
      },

      cardTwo: {
        heading: 'Social-Recruiting',
        paragraph:
          'Mit Social-Recruiting sprechen Sie alle Arbeitnehmer an, auch aktuell angestellte.',
      },
      cardThree: {
        heading: 'Qualifizierte Mitarbeiter',
        paragraph:
          'Sie gewinnen kontinuierlich qualifizierte Mitarbeiter, die ideal in Ihr Unternehmen passen.',
      },
    },
    faq: {
      one: {
        question: 'Warum sind klassische Stellenanzeigen wenig hilfreich?',
        answer:
          'Klassische Stellenanzeigen bieten im digitalen Wandel nur begrenzt Möglichkeiten, um die Personalgewinnung voranzutreiben. Stellenanzeigen sind grundsätzlich  statisch. Viele Bewerber fühlen sich  nicht ausreichend angesprochen,  und es besteht die Gefahr, Aufgaben und Tätigkeitsbereiche in einer Stellenanzeige falsch zu interpretieren. Das Potenzial, mittels einer Stellenanzeige einen geeigneten Bewerber zu finden, ist demnach begrenzt. Durch Social-Recruiting wird mehr Dynamik in die Prozesse gebracht und individueller auf die Bewerber eingegangen',
      },
      two: {
        question: 'Welche Kosten entstehen durch Social-Recruiting?',
        answer:
          'Zunächst entstehen für Sie in den Beratungsgesprächen keine Kosten. Die Gesamtkosten sind von Ihrer Zielrichtung sowie von Ihrem aktuellen Status quo abhängig. Im kostenlosen Erstgespräch analysiert ein Experte die Situation und bereitet diesbezüglich relevante Informationen auf. Es entstehen Kosten für weitere Beratungstermine, Ads sowie die Landingpage.',
      },
      three: {
        question: 'Warum ist Social-Recruiting eine kosteneffiziente Strategie?',
        answer:
          'Da Social-Recruiting die Dynamik am Arbeitsmarkt vorantreibt, sind damit verbundenen Kosten auch stets flexibel zu sehen. Hierbei geht es nämlich nicht darum,  im klassischen Sinne umfangreiche Kontingente an Stellenanzeigen schalten zu lassen, sondern gezielt auf internen Personalbedarf zu reagieren und von dort aus das Social-Recruiting zu steuern, so dass Anpassungen schnell umsetzbar und in die Personalgewinnungs-Aktivitäten implementierbar sind.',
      },
      four: {
        question: 'Wie vermeide ich Rufschädigung in Social-Media?',
        answer:
          'Social Media bietet zahlreiche Potenziale zur Personalgewinnung. Um eine mögliche Rufschädigung  zu vermeiden, gilt es im Vorhinein genau zu definieren, wie sich das Unternehmen ausrichten und positionieren möchte. Auf diese Weise wird ein Rahmen geschaffen, in dem wir uns gemeinsam strategisch bewegen können.',
      },
      five: {
        question: 'Welchen Einfluss hat die Abwanderung der Facebook-Nutzer auf Social-Recruiting?',
        answer:
          'Soziale Netzwerke sind ebenso wenig starr wie das Social-Recruiting. Ab- sowie Zuwanderungen zwischen sozialen Netzwerken  stellen  mehr eine Chance als eine Herausforderung dar. So befinden sich auf einzelnen sozialen Kanälen spezielle Zielgruppen, die ganz individuell angesprochen werden können.',
      },
    },
    references: {
      title: 'Referenzen',
      cardOne: {
        title: 'Stefanie Müller',
        subTitle: 'Lorem Gmbh',
        time: '3 Monate',
        applications: '100 Bewerbungen',
        employees: '12 neue Mitarbeiter',
        description:
          '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation “',
      },
      cardTwo: {
        title: 'Hans Stein',
        subTitle: 'Lorem Gmbh',
        time: '6 Monate',
        applications: '120 Bewerbungen',
        employees: '45 neue Mitarbeiter',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation “',
      },
    },
    howwework: {
      title: 'Wie wir arbeiten',
      cardOne: {
        title: 'Termin vereinbaren',
        description:
          'Senden Sie uns noch heute Ihre Anfrage und vereinbaren Sie ein Gespräch mit uns zu Ihrem Wunschtermin.',
        btntext: 'Termin buchen',
      },
      cardTwo: {
        title: 'Vorgespräch',
        description:
          'In einem unverbindlichen Vor&shy;gespräch analysieren wir Ihre aktuelle Situation und ermitteln Ihren Bedarf.',
      },
      cardThree: {
        title: 'Beratungsgespräch',
        description:
          'Im darauffolgenden digitalen Beratungsgespräch beraten wir Sie individuell und kostenlos hinsichtlich  einer Zusammen&shy;arbeit.',
      },
      cardFour: {
        title: 'Zusammenarbeit',
        description:
          'Nun entscheiden Sie, ob Sie eine Zusammenarbeit wünschen und wir gemeinsam durch starke Social-Recruiting-Strategien gezielt neue Mitarbeiter für Sie gewinnen.',
      },
    },
    ourServices: {
      title: 'Dienstleistungen',
      cardOne: {
        title: 'Webdesign',
        description:
          'Eine professionelle Website ist das Aushängeschild eines jeden Unternehmens und meist die erste Anlaufstelle Ihrer Kunden.',
        check1: 'Optimiert für mobile Geräte',
        check2: 'Dynamisch & statisch',
        check3: 'Sicher & performant (leistungsfähig)',
        check4: 'Bestmögliche SEO',
      },
      cardTwo: {
        title: 'Software-Entwicklung',
        description:
          'Wir entwickeln und betreiben testgetrieben und agil hochwertige Web-Applikationen mithilfe von JavaScript und React.',
        check1: 'Beste Qualität durch Test-First',
        check2: 'Geringe Wartungskosten',
        check3: 'Spezialisiert in NodeJS & React',
        check4: 'Neueste Technologien und Methoden',
      },
      cardThree: {
        title: 'Social-Media-Recruiting',
        description:
          'Mit dem gezielten Schalten von Stellenanzeigen übertrumpfen Sie herkömmliche Suchmaschinen und erreichen passiv suchendes Fachpersonal.',
        check1: 'Hochmoderner Ansatz',
        check2: 'Entwicklung eines Recruiting-Prozesses',
        check3: 'Ständige Anpassung',
        check4: 'Geeignet für alle Branchen',
      },
      cardFour: {
        title: 'SEO',
        description:
          'Mithilfe professioneller Suchmaschinenoptimierung werden Webseiten häufiger gefunden. So steigern Sie den Erfolg Ihres Unternehmens.',
        check1: 'Optimierung Ihrer Website',
        check2: 'Hohe Platzierung im Ranking',
        check3: 'Steigerung Ihres Erfolges',
        check4: 'Stärkung Ihrer Internetpräsenz',
      },
    },
    contactUsForm: {
      title: 'Kontaktformular',
      titlePayment: 'Kapazitäten anfragen für: ',
      placeholder: {
        name: 'Name',
        email: 'E-Mail',
        message: 'Nachricht',
        phone: 'Telefonnummer',
      },
      messageText1: 'Ich bin am Produkt “',
      messageText2: '” interessiert. Bitte reservieren Sie Kapazitäten und rufen mich zurück.',
      error: 'Beim Senden der Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
      success:
        'Ihre Nachricht wurde erfolgreich gesendet. Wir werden uns schnellst möglich mit Ihnen in Verbindung setzen.',
      nameError: 'Ihr Name muss mindestens 5 Zeichen lang sein.',
      emptyEmailErr: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
      InvalidEmailErr: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      messageError: 'Die Nachricht muss mindestens 10 Zeichen lang sein.',
      waitingList: 'Sie kommen auf unsere Warteliste.',
    },
    seo: {
      title: 'Social Media Recruiting Agency |  Welitzki-IT',
      description:
        'Social Media Recruiting Agentur - Wir helfen Ihnen die richtigen Mitarbeiter für Ihr Unternehmen zu finden. Wir kümmern uns um den gesamten Prozess von der Ausschreibung bis zum Abschlussgespräch.',
      keywords:
        'Social Media Recruiting, Social Media Recruiting Agentur, Rekrutierungshilfe in Deutschland',
    },
  },
  company: {
    common: {
      acceptCookie: 'Bitte erlauben Sie funktionale Cookies, um Calendly zu aktivieren!',
    },
    navbar: {
      services: 'Dienstleistungen',
      references: 'Referenzen',
      aboutUs: 'Über uns',
      products: 'Produkte',
    },
    hero: {
      firstText: 'Wir sind ein ',
      secondText: 'digitales Studio für ',
      webDesign: 'Webdesign',
      softwareDevelopment: 'Software-Entwicklung',
      socialMediaRecruiting: 'Social-Media-Recruiting',
      seo: 'SEO',
    },
    quote: {
      text: '“Unser Ziel ist es, eine Kombination aus erstklassigen Konditionen, Zuverlässigkeit und fairen Preisen zu schaffen. Infolge unserer langjährigen Erfahrungen können wir auch komplexe Sachverhalte in modernen IT-Lösungen umsetzen. “',
    },
    aboutUs: {
      mika: {
        name: 'Mika Lembrecht',
        role: 'Projektkoordination, \n Assistenz und Content Creator',
        details:
          'Als Projektmanager koordiniert Mika alle internen und externen Ressourcen für regelmäßige Auf&shy;ga&shy;ben und ei&shy;nmalige Pro&shy;jek&shy;te. Er verantwortet die Kom&shy;mu&shy;ni&shy;ka&shy;ti&shy;on des Unter&shy;nehmens und ko&shy;or&shy;di&shy;niert die Erstellung von In&shy;halten.',
      },
      payal: {
        name: 'Payal Kaklotar',
        role: 'Software-Entwicklung und Testing',
        details:
          'Seit dem Jahr 2020 ist Payal eine er&shy;fahr&shy;ene Software-Ent&shy;wick&shy;le&shy;rin, die innerhalb des Un&shy;ter&shy;neh&shy;mens für die Software-Tests zuständig ist. Sie stellt sicher, dass alle Produkte den hohen Qua&shy;li&shy;täts&shy;stan&shy;dards und  den Anforderungen der Kun&shy;den entsprechen.',
      },
      soft: {
        name: 'Sopuluchukwu Obi',
        role: 'Design- und Software-Entwicklung',
        details:
          'Sopuluchukwu (Soft) ist seit 2015 Soft&shy;ware-Entwickler und hat be&shy;reits an einer Vielzahl von Soft&shy;ware-Pro&shy;jek&shy;ten mit&shy;ge&shy;wirkt. Er ver&shy;fügt über umfassende Er&shy;fahr&shy;ungen in verschiedenen Bereichen der Soft&shy;ware&shy;ent&shy;wick&shy;lung, einschließlich Design, Im&shy;ple&shy;men&shy;tie&shy;rung und Qualitäts&shy;sicherung.',
      },
    },
    ourProducts: {
      title: 'Produkte',
      product1: {
        title: 'Webseiten-Analyse',
        tag: 'netto',
        check1: 'Gesamtbild u. erster Eindruck der Webseite',
        check2: 'Durchführung von Leistungstests',
        check3: 'Einhaltung von Best Practices',
        check4: 'Umsetzung von SEO-Maßnahmen',
        check5: 'Abmahngefahr durch Google Fonts',
        hint: 'Bearbeitungsdauer: 3 Tage',
      },
      product2: {
        title: 'Web-Boost',
        tag: '.',
        free: 'Kostenlos',
        desc: 'Langsam ladende Web&shy;seiten schrecken potenzielle Kunden und Besucher ab, lassen Sie ihre Web&shy;seite von uns Testen und optimieren.',
        check1:
          'Analyse Ihrer derzeitigen Web&shy;seite und Fest&shy;stellung von Opti&shy;mierungs&shy;möglich&shy;keiten',
        check2: 'Verbesserung der Perfor&shy;mance und Schnellig&shy;keit ',
        hint: 'Beabeitungsdauer: 7 Tage',
      },
      product3: {
        title: 'DSGVO-Beseitigung ',
        tag: 'netto',
        desc:
          'Ihre Webseite ist ab&shy;mahn&shy;ge&shy;fähr&shy;det? Wir binden die Google Fonts korrekt ein und beseitigen alle Risiken.\n' +
          '\n',
        check1: 'Schnelle Beseitigung',
        check2: '100% Absicherung',
        check3: 'Inklusive aller Unterseiten',
        hint: 'Bearbeitungsdauer: 1-3 Werktage',
      },
      webBoost: {
        card1: {
          title: 'Web-Boost S',
          tag: 'netto',
          check1: '<b>3</b> Stunden Perform&shy;ance-Optimierung Ihrer Webseite',
          check2: 'Analyse mit Google Lighthouse',
          check3: 'Maßnahmeplan zur Verbesserung der Webseite',
          check4: '2-3 Features zur Optimierung',
        },
        card2: {
          title: 'Web-Boost M',
          tag: 'netto',
          check1: '<b>5</b> Stunden Perform&shy;ance-Optimierung Ihrer Webseite',
          check2: 'Analyse mit Google Lighthouse',
          check3: 'Detaillierter Maß&shy;nahmeplan zur Ver&shy;besserung der Webseite',
          check4: 'Performance-Optimierung für schnelleres Laden',
          check5: '4-5 Features zur Optimierung',
        },
        card3: {
          title: 'Web-Boost L',
          tag: 'netto',
          check1: '<b>10</b> Stunden Perform&shy;ance-Optimierung Ihrer Webseite',
          check2: 'Umfassende Analyse mit Google Lighthouse',
          check3: 'Detaillierter Maß&shy;nahme&shy;plan zur Verbesserung der Webseite',
          check4: 'Perform&shy;ance-Optimierung für schnelleres Laden',
          check5: 'Verbesserung der Performance und Zugänglichkeit',
          check6: 'SEO-Optimierung',
          check7: '6-8 Features zur Optimierung',
        },
        card4: {
          title: 'Individuell',
          check1: 'Individuell festgelegte Festsumme basierend auf den Problemen der Webseite',
          check2: 'Maßgeschneiderte Analyse und Optimie&shy;rung',
          check3:
            'Alle Funktionen der M- und L-Variante ange&shy;passt an die individ&shy;uellen Anforderungen',
        },
      },
    },
    reference: {
      title: 'Referenzen',
      hide: 'weniger',
      read: 'mehr',
      rene: {
        name: 'René Böttiger',
        role: 'Selbstständiger Energieberater',
        details:
          '“Ich bin äußerst zufrieden mit den Dienst&shy;leistungen von Welitzki IT. Sie haben meine Firmen&shy;homepage mit beein&shy;druckendem Fachwissen und äußerster Profession&shy;alität gestaltet. Unsere Zusammen&shy;arbeit verlief reibungslos, und das Ergebnis hat meine Erwartungen übertroffen. Die Website ist nicht nur ans&shy;prechend, sondern auch funktional und benutzer&shy;freundlich. Ich kann Welitzki IT uneinge&shy;schränkt weiter&shy;empfehlen und freue mich auf weitere Projekte mit diesem großartigen Team!“',
      },
      david: {
        name: 'David Bieder',
        role: 'Geschäftsführer bei cultivate GmbH',
        details:
          '“Ich hab es genossen 2019 und 2020 mit Nicolas an einer B2C React Redux App für Immoscout24 gearbeitet zu haben. Er implementierte mehrere Funktionen, die den gesamten App-Stack betrafen.“',
      },
      andreas: {
        name: 'Florian Kube',
        role: 'Product Owner bei ImmobilienScout24',
        details:
          '“Ich empfehle N. nachdrücklich als er&shy;fahr&shy;enen und zu&shy;ver&shy;läs&shy;sigen Software&shy;entwickler. Es wäre mir eine Freude, wieder mit ihm zu arbeiten.“',
      },
    },
    aboutUsNic: {
      title: 'Über uns',
      name: 'Nicolas Welitzki (Inhaber)',
      detail:
        '<li>Über 20 Jahre Expertise als professioneller Software-Entwickler und Business-Analyst</li><li>Full-Stack Entwickler für Web-Applikationen mit Technologien wie Javascript, React, Vue, NodeJs und Express</li><li>Erfahrung in vielen verschiedenen Branchen (Immobilien, Finanzen, E-Commerce, Automobile, uvm.)</li><li>Selbstständig tätig seit 2019</li><li>Umsetzung von Projekten in Kombination aus festen Mitarbeitern und Partnern</li><li>Bietet Dienstleistungen im Bereich Webdesign, Software-Entwicklung & SEO in ganz Deutschland an</li>',
      //Below commented text has been changed to the above text
      //   paragraphOne:
      //     'Der Berliner IT-Unternehmer Nicolas Welitzki blickt auf mehr als 20 Jahre Berufserfahrung als professioneller Software-Entwickler zurück.',
      //   paragraphTwo:
      //     'Nach seinem Masterabschluss an der Hochschule für Technik und Wirtschaft Berlin und einigen beruflichen Stationen, entschied er sich 2019 für die Selbstständigkeit. Nicolas Welitzki arbeitete erfolgreich als IT-Spezialist in Projekten aus verschiedenen Branchen mit, zum Beispiel in der Immobilienwirtschaft sowie im Finanz- und E-Commerce-Sektor.',
      //   paragraphThree:
      //     'Seit Juni 2019 unterstützt er als Freelancer die größte deutsche Immobilien-Onlineplattform und den europäischen Marktführer für Freizeitfahrzeuge.',
      //   paragraphFour:
      //     'Mit seinem Team bietet er IT-Dienstleistungen deutschlandweit an. Der Schwerpunkt liegt dabei in der Region Berlin/Brandenburg.',
      freeTime: 'In seiner Freizeit engagiert sich Herr Welitzki für:',
      freeTimeOne: 'Unternehmerverband',
      freeTimeTwo: 'Beirat HTW Berlin Wirtschaftsinformatik',
      freeTimeThree: 'Förderverein Kindertagesstätte',
    },
    contactPartner: {
      title: 'Kontakte und Partner',
      details:
        '<p>Neben unseren festangestellten Mitarbeitern verfügen wir über ein breites Netzwerk an Partnern und Kontakten, auf das wir jederzeit zurückgreifen können. Für die Bewältigung mehrerer Projekte gleichzeitig setzen wir auf die Zusammenarbeit mit Freelancern, die uns bereits seit über drei Jahren tatkräftig unterstützen. Diese Freiberufler zeichnen sich durch ihre Expertise in den Bereichen Software-Entwicklung, Online-Marketing und Webdesign aus.<br><br>Insbesondere bei umfangreichen Softwareprojekten kooperieren wir zudem mit anderen Unternehmen, um unseren Kunden eine optimale Dienstleistung zu bieten. Die langjährige Zusammenarbeit mit unseren Freelancern und Partnern gewährleistet eine eingespielte und effiziente Arbeitsweise.<br><br>Die Qualität unserer Dienstleistungen wird nicht nur durch unsere Mitarbeiter, sondern auch durch dieses vielseitige Netzwerk sichergestellt. Dies ermöglicht es uns, unseren Kunden stets hochwertige Lösungen anzubieten – und das in einem effizienten und zeitnahen Rahmen.</p>',
    },
    seo: {
      title: 'Webdesign / Web Applikationen / Social Media Recruiting / SEO',
      description:
        'Agentur aus Berlin für Webdesign ✅ Web-Applikationen ✅ Social Media Recruiting ✅ und SEO ✅! Erstellen Sie mit uns Ihre neue Webseite, finden sie qualifizierte Fachkräfte und lassen sie Ihre Seite höher ranken | Nicolas Welitzki IT-Consulting',
      keywords:
        'Webdesign, Landingpage, Web-Applikationen, Responsive, Social Media Recruiting, Mitarbeiter finden, SEO, Suchmaschinenoptimierung,  Websiteanalyse, Google Font Analyse, Absicherung vor Abmahnungen',
    },
  },
  webDesign: {
    navbar: {
      start: 'Start',
      wy: 'Warum?',
      procedure: 'Ablauf',
      whyUs: 'Warum Wir?',
      aboutUs: 'Über uns',
      contact: 'Kontakt',
      faq: 'FAQ',
    },
    hero: {
      firstText: 'Webdesign - Erstellung von ',
      secondText: 'performanten Webseiten',
      lastText: ' & Landingpages mit Wordpress',
    },
    callToAction: {
      heading1: 'Bereit eine neue Website zu gestalten?',
      heading2: 'Jetzt Termin vereinbaren',
    },
    howwework: {
      title: 'Wie wir arbeiten',
      cardOne: {
        title: 'Termin vereinbaren',
        description:
          'Senden Sie uns noch heute Ihre Anfrage und vereinbaren Sie ein Gespräch mit uns zu Ihrem Wunschtermin.',
        btntext: 'Termin buchen',
      },
      cardTwo: {
        title: 'Vorgespräch',
        description:
          'In einem unverbindlichen Vor&shy;gespräch analysieren wir Ihre aktuelle Situation und ermitteln Ihren Bedarf.',
      },
      cardThree: {
        title: 'Beratungsgespräch',
        description:
          'Im darauffolgenden digitalen Beratungsgespräch beraten wir Sie individuell und kostenlos hinsichtlich einer Zusammen&shy;arbeit.',
      },
      cardFour: {
        title: 'Zusammenarbeit',
        description:
          'Nun entscheiden Sie, ob Sie eine Zusammenarbeit wünschen und wir Ihre Wunsch-Website erstellen.',
      },
    },
    why: {
      heading: 'Warum eine moderne Website wichtig ist',
      heading2: 'Noch nie war eine moderne Website so wichtig wie heute.',
      firstText: 'Wirkt seriös und professionell',
      secondText: 'Ist Oftmals der erste Kundenkontakt',
      thirdText: 'Zeigt wer Sie sind und was Sie anbieten',
      fourthText: 'Attraktive Webseiten steigern die Chance Kunden zu gewinnen',
      fifthText: 'Die Konkurrenz ist nur einen Klick entfernt',
    },
    whyUs: {
      heading: 'Warum wir?',
      cardOne: {
        heading: 'Responsive Webdesign',
        paragraph:
          'Wir helfen Unternehmen in ganz Deutschland dabei, Ihre Wunsch-Website zu generieren. Dank responsivem Design ist Ihre Website sowohl als Desktop, als auch als mobile Variante verfügbar.',
      },
      cardTwo: {
        heading: 'CMS + SEO',
        paragraph:
          'Durch unsere jahrelange Erfahrung mit Wordpress und anderen CMS gelingt es uns, fast jeden Wunsch umzusetzen. Doch hier endet der Service nicht, wir perfektionieren Ihre Website zusätzlich mit Suchmaschinenoptimierung.',
      },
      cardThree: {
        heading: 'Beratung',
        paragraph:
          'Kundenkontakt und schnelle Kommunikation werden bei uns großgeschrieben. Sie werden beraten und während der Zusammenarbeit mit eingebunden. So können Sie mitverfolgen, wie Ihre professionelle Website entsteht.',
      },
      cardFour: {
        heading: 'Weiterer Support',
        paragraph:
          'Auch nach Abschluss der Website stehen wir Ihnen weiterhin zur Verfügung, um regelmäßig Wartungsarbeiten und Aktualisierungen durchzuführen, sowie die Suchmaschinen-optimierung zu überwachen.',
      },
    },
    showCases: {
      card1: {
        heading: 'Design und Umsetzung einer responsiven Webseite',
        text: 'Selbstständiger Energieberater',
      },
      card2: {
        heading: 'Support für alte Browser',
        text: 'Internationales Unternehmen für Installationstechnik',
      },
      card3: {
        heading: 'Erstellung einer Single Page Application',
        text: 'Online-Marktplatz für Immobilien',
      },
    },
    faq: {
      one: {
        question: 'Was ist Suchmaschinenoptimierung?',
        answer:
          'Suchmaschinenoptimierung, auch SEO genannt (Search Engine Optimization), ist ein gängiges Instrument, um die Reichweite Ihrer Webseite zu erhöhen. Es umfasst alle Maßnahmen, die dazu dienen, Ihre Webseite höher im Ranking zu positionieren. Es werden dabei nahezu alle Komponenten der Webseite optimiert. Die reicht vom Quellcode und dem Server, über die mobile Variante bis hin zum grundlegenden Content, darunter Texte und Bilder. Ziel von SEO ist es, die Reichweite zu vergrößern, die Markenbekanntheit zu steigern und dadurch höhere Umsätze zu generieren.',
      },
      two: {
        question: 'Statische Webseite vs. Dynamische Webseite',
        answer:
          'Statische Webseiten eignen sich sehr gut, wenn Inhalte der Webseite nur selten verändert werden müssen. Sie werden in HTML, CSS und JavaScript programmiert. Statische Webseiten sind schnell zu generieren und einfach zu warten. Ihr Vorteil ist eine schnelle Ladezeit. Dynamische Webseiten dagegen beziehen ihre Inhalte aus mindestens einer Datenbank. Sie eignen sich vor allem für interaktive Webseiten wie Online-Shops. Welche Variante verwendet werden sollte, hängt von verschiedenen Faktoren ab (z.B. Verwendungszweck, Zeit, Anzahl der Texte und deren Aktualität). Beide Varianten besitzen Vor- und Nachteile.',
      },
      three: {
        question: 'Was ist ein Content Management System - CMS?',
        answer:
          'Ein Content Management System (CMS) ist eine Software zur Verwaltung und Erstellung von Webseiten. Die Benutzung eines CMS erfordert keine Erfahrung. Das bekannteste CMS ist WordPress.',
      },
      four: {
        question: 'Was ist Responsive Design?',
        answer:
          'Unter responsivem Webdesign versteht man die Anpassung der Webseite an das jeweilige Format des Gerätes. Dies bedeutet, es gibt mehrere flexible Designs für Smartphones, Tablets, Desktops usw. Das erhöht die Nutzerfreundlichkeit der Webseite.',
      },
      five: {
        question: 'Was ist Barrierefreiheit im Netz?',
        answer:
          'Moderne Webseiten sollen so barrierefrei wie möglich designt und konstruiert werden. Sie sollen also auch für ältere, behinderte oder eingeschränkte Menschen leicht zugänglich und verständlich sein. Dies erweitert nicht nur die Anzahl der zufriedenen Nutzer ihrer Webseite, sondern Google erkennt dies und rankt so Ihre Webseite höher.',
      },
      six: {
        question: 'Welche Mitwirkungspflichten habe ich?',
        answer:
          'Ein optimales Ergebnis kann nur dann gewährleistet werden, wenn Kunde und Dienstleister zusammenarbeiten. Kommunikation und Feedback werden bei uns großgeschrieben. Ihre Wünsche und Ideen zur Webseite werden am Anfang besprochen und im Laufe der Zeit verfeinert und perfektioniert, so dass Sie am Ende das gewünschte Endprodukt erhalten.',
      },
    },
    aboutUs: {
      heading: 'Über uns',
      firstParagraph:
        'Schön, dass Sie uns gefunden haben! Wir bieten maßgeschneiderte Lösungen für kleine und mittelständische Unternehmen in der DACH-Region an. Mit einem erfahrenen und leidenschaftlichen Team legt das Unternehmen Wert auf Benutzerfreundlichkeit und Nutzererfahrung, um attraktive und funktionale Websites zu gestalten. Sie wollen mehr über uns erfahren?',
    },
    seo: {
      title: 'Agentur für Webdesign in der DACH-Region',
      description:
        'Unser Team ist spezialisiert auf die Gestaltung hochwertiger und ästhetischer Webseiten für Unternehmenaller Branchen. Wir arbeiten mit den neusten Methoden und Technologien und sind sehr erfahren im Bereich Webdesign.',
      keywords:
        'Webdesign, Web Development, Web Agency, Webdesign, Webentwicklung, Werbeagentur, Webdesign Berlin, Webentwicklung Berlin, Webagentur Berlin',
    },
    contactUs: {
      details:
        'Lassen Sie uns gerne ihr neues Webdesign-Projekt gemeinsam analysieren und besprechen',
    },
  },
  newsLetter: {
    title: 'Melden Sie sich jetzt für unseren Newsletter an!',
    description:
      'Ja, ich möchte einen Newsletter zu den Fachthemen Webdesign, Social-Media-Recruiting, Software-Entwicklung und SEO erhalten. Hinweise zum Widerruf und der Verarbeitung der Daten geben wir in unserer ',
    privacyText: 'Datenschutzerklärung.',
    cancelBooking: 'jederzeit abbestellbar',
    placeholder: {
      name: 'Name',
      email: 'E-Mail',
    },
    error: 'Beim Senden der Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    success: 'Bestätigt! Du bist auf der Newsletter-Liste.',
    nameError: 'Ihr Name muss mindestens 5 Zeichen lang sein.',
    emptyEmailErr: 'Geben Sie bitte Ihre E-Mail-Adresse ein.',
    InvalidEmailErr: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
  },
  webDevelopment: {
    navbar: {
      start: 'Start',
      why: 'Warum wir?',
      showcases: 'Showcases',
      services: 'Dienstleistungen',
      products: 'Produkte',
    },
    hero: {
      firstText: 'Entwicklung',
      secondText: ' professioneller Web-Applikationen',
      lastText: ' - mit modernsten Methoden und Technologien',
    },
    why: {
      heading: 'Warum wir?',
      firstText: 'Agile Entwicklungsprozesse mit engem Kundenkontakt',
      secondText: 'Nachhaltiger Ansatz bei der Entwicklung von Webprojekten',
      thirdText: 'Integration von automatisierten Tests für eine höhere Qualität',
      fourthText:
        'Maximaler Erfolg durch Schwerpunkt auf Benutzerfreundlichkeit & Nutzerzufriedenheit',
    },
    services: {
      heading: 'Dienstleistungen',
      firstCard: {
        heading: 'Webanwendungen',
        services: {
          first: 'React & TypeScript',
          second: 'JavaScript, PHP & HTML',
          third: 'Redux & andere Pattern',
        },
      },
      secondCard: {
        heading: 'Software-Migration',
        services: {
          first: 'Modernisierung alter Technologien',
          second: 'Alte Software in neue Umgebung transferieren',
        },
      },
      thirdCard: {
        heading: 'Desktop-Software',
        services: {
          first: 'Cross-Plattform-Desktop-Anwendungen',
          second: 'Electron mit HTML, CSS und JavaScript',
        },
      },
      fourthCard: {
        heading: 'Fehlersuche & Debugging',
        services: {
          first: 'Professionelle Fehlerbehebung',
          second: 'Instandsetzung fehlerhafter Software',
        },
      },
    },
    showCases: {
      card1: {
        heading: 'Höhere Conversion-Rate',
        text: 'Online-Marktplatz für Immobilien',
      },
      card2: {
        heading: 'Support für alte Browser',
        text: 'Internationales Unternehmen für Installationstechnik',
      },
      card3: {
        heading: 'Erstellung einer Single Page Application',
        text: 'Online-Marktplatz für Immobilien',
      },
    },
    products: {
      title: 'Produkte',
      card1: {
        title: 'Buchung ab Minutenbasis',
        desc: 'bereits ab 1,50 Euro pro Minute/ 90 Euro pro Stunde',
      },
      card2: {
        title: 'Erstellung eines Prototypen',
        desc: 'Preis auf Anfrage',
      },
      card3: {
        title: 'Schulungen/ Coaching ',
        desc: 'Preis auf Anfrage',
      },
    },
    callToAction: {
      heading1: 'Bereit Ihre individuelle Software entwickeln zu lassen?',
      heading2: 'Treten Sie gerne mit uns in Kontakt.',
    },
    faq: {
      one: {
        question: 'Was versteht man unter Softwareentwicklung?',
        answer:
          'Softwareentwicklung ist der Prozess der Erstellung von Computerprogrammen. Es beinhaltet Planung, Erstellung, Testen und Verbesserung der Software.',
      },
      two: {
        question: 'Auf welche Technologien haben wir uns spezialisiert?',
        answer:
          'Unser Team hat sich vorwiegend auf JavaScript, TypeScript, React, Redux spezialisiert. Jedoch sind wir auch trainiert in PHP, Java und Kotlin.',
      },
      three: {
        question: 'Wie wird eine Software entwickelt?',
        answer:
          'Eine Software wird entwickelt, indem man zunächst die Anforderungen sammelt und analysiert, dann einen Plan erstellt, wie die Software aussehen und funktionieren sollte. Daraufhin wird der Code geschrieben und getestet, um sicherzustellen, dass alles richtig funktioniert. Nachdem alle Probleme behoben sind, wird die Software veröffentlicht und gepflegt, um sicherzustellen, dass sie auch in Zukunft gut funktioniert.',
      },
      four: {
        question: 'Was ist die inkrementelle Entwicklung?',
        answer:
          'Inkrementelle Entwicklung ist eine Methode der Softwareentwicklung, bei der man eine Software in kleinen Schritten oder Inkrementen entwickelt, jeder Schritt ist eine kleine und abgeschlossene Einheit. Dadurch ist es möglich, schnell Feedback von Benutzern zu erhalten und die Software schnell zu verbessern. Es ermöglicht auch, dass die Software in kürzerer Zeit und mit geringem Risiko entwickelt werden kann.',
      },
      five: {
        question: 'Was ist Agile Software-Engineering?',
        answer:
          'Agile Software-Engineering ist eine Methode der Softwareentwicklung, die auf Agilität und Flexibilität setzt. Sie ermöglicht es, Anforderungen schnell zu ändern und schnell auf Probleme zu reagieren. Sie basiert auf kurzen Entwicklungszyklen und der ständigen Zusammenarbeit von Entwicklern, Kunden und anderen Beteiligten.',
      },
      six: {
        question: 'Was sind Single-Page Applications (SPA)?',
        answer:
          'Single-Page Applications (SPA) sind Webanwendungen, bei denen nur eine einzige HTML-Seite geladen wird, während der Inhalt dynamisch aktualisiert wird. Sie unterscheiden sich von traditionellen Webanwendungen, bei denen jede Seite einzeln geladen wird und der Benutzer durch das Laden neuer Seiten navigiert.',
      },
      seven: {
        question: 'Was bedeutet Test-Driven Development (TDD)?',
        answer:
          'Test-Driven Development (TDD) ist eine Methode des Software-Entwicklungsprozesses, bei der zuerst Tests für die zu entwickelnde Funktionalität geschrieben werden, bevor der tatsächliche Code entsteht. Der Code wird dann so geschrieben, dass er die Tests besteht und die gewünschte Funktionalität bereitstellt. Dies führt zu sauberen und gut strukturierten Codes, die leicht zu warten und zu erweitern sind.',
      },
      eight: {
        question: 'Wie wird Version Control Systems (VCS) verwendet?',
        answer:
          'VCS werden verwendet, um Änderungen an Dateien in einem Projekt nachverfolgen und verwalten zu können. Jede Änderung wird in einer „Version" gespeichert, die später wiederhergestellt werden kann. VCS ermöglicht es auch, Änderungen von mehreren Personen an einem Projekt gleichzeitig durchführen zu können und Konflikte automatisch zu lösen.',
      },
      nine: {
        question: 'Was sind die Grundprinzipien von gutem Softwaredesign?',
        answer:
          'Gutes Softwaredesign beruht auf mehreren Grundprinzipien. Das Wichtigste ist die Simplizität, eine gut designte Software sollte leicht zu verstehen, zu implementieren und zu warten sein. Zudem sollte sie in kleine Module unterteilt werden und wiederverwendbar sein.',
      },
      ten: {
        question: 'Warum erstellen wir Clean Code und was ist das?',
        answer:
          'Clean Code ist ein Konzept, bei dem der Code so geschrieben wird, dass er leicht gelesen und verstanden werden kann. Dies erreicht man durch die Verwendung  eines gut strukturierten Codes. Durch Clean Code erleichtert man die Zusammenarbeit und Effizienz im Team und verbessert die Qualität der Codes.',
      },
      eleven: {
        question: 'YAGNI, KISS, Rule of 3, DRY Principles - Für was stehen sie?',
        answer: `YAGNI steht für „You Ain't Gonna Need It" und ist ein Prinzip der agilen Softwareentwicklung, welches besagt, dass man nicht in Funktionalitäten investieren sollte, die man vielleicht in der Zukunft benötigen wird, sondern sich auf die aktuellen Anforderungen konzentriert.

KISS steht für „Keep It Simple, Stupid" und ist ein Designprinzip, das besagt, dass Systeme am besten funktionieren, wenn sie einfach und leicht verständlich sind.

Rule of 3 besagt, dass man nicht mehr als drei Schichten von Abstraktion in einem System verwenden sollte, um die Verständlichkeit und Wartbarkeit des Codes zu erhöhen.

DRY steht für „Don't Repeat Yourself" und sagt aus, dass man keinen überflüssigen Code verwenden sollte, sondern stattdessen Funktionalitäten wiederverwenden sollte, um die Wartbarkeit und Fehleranfälligkeit des Codes zu reduzieren`,
      },
      twelve: {
        question: 'Was bedeutet Red Green Refactor?',
        answer:
          ',,Red, Green, Refactor" ist ein Prozess der testgetriebenen Entwicklung (TDD). Es ist ein Prozess, in dem Entwickler zuerst einen automatisierten Test für eine Funktionalität schreiben, die sie hinzufügen möchten (Schritt ,,Red"). Dann schreiben sie den Code, um den Test erfolgreich zu machen (Schritt ,,Green"). Schließlich refaktorieren sie den Code, um ihn lesbarer zu machen, ohne dabei die Funktionalität zu ändern (Schritt ,,Refactor").',
      },
      thirteen: {
        question: 'Warum bauen wir Komponenten mit dem Storybook?',
        answer: `Das Bauen der Komponenten mithilfe des Storybooks bringt viele Vorteile mit sich.

Mithilfe des Storybooks können Designer und Entwickler die Komponenten gemeinsam entwickeln, was die Zusammenarbeit wesentlich erleichtert.Zudem macht es das Storybook möglich, einzelne Komponenten durch das individuelle Abspeichern wiederzuverwenden.Abgesehen davon können Komponenten unabhängig voneinander und automatisch getestet werden.`,
      },
    },
    contactUs: {
      details:
        'Lassen Sie uns gerne ihr neues Software-Projekt gemeinsam analysieren und besprechen',
    },
    seo: {
      title: 'Entwicklung professioneller Web-Applikationen',
      description:
        'Unser Team ist spezialisiert auf die Entwicklung vonhochprofessioneller Web-Applikationen und Software. Unser Team ist darauf geschult, mit den modernsten Methoden und Technologien zu arbeiten um das gewünschte Produkt unserer Kunden aushändigen zu können.',
      keywords:
        'Webdesign, Web Development, Web Agency, Webdesign, Webentwicklung, Werbeagentur, Webdesign Berlin, Webentwicklung Berlin, Webagentur Berlin',
    },
  },
  footer: {
    copyright: `Copyright © ${new Date().getFullYear()}`,
    follow: 'Folge uns',
    cookie: 'Cookie -Einstellungen',

    location: 'Standort',
    services: 'Dienstleistungen',
    products: 'Produkte',
    contact: 'Kontakt',

    email: 'E-Mail',
    phone: 'Telefon',

    webDesign: 'Webdesign',
    softwareDevelopment: 'Software-Entwicklung',
    socialMediaRecruiting: 'Social Media Recruting ',
    seo: 'SEO',

    websiteAnalysis: 'Webseiten-Analyse',
    webBoost: 'Web-Boost',
    gdprElimination: 'DSGVO-Beseitigung',
  },
  blog: {
    title: 'Willkommen auf unserem Blog',
    by: 'von ',
    moreFromUs: 'mehr von uns',
    seo: {
      title: 'Alle Blogeinträge von Nicolas WelitzkiIT-Consulting',
      description:
        'Finden Sie bei uns spannende und interessante Blogeinträge unseres Teams. Wir kreieren Blogs zu Allgemeinen Themen aus der IT, zudem Webdesign, Web-Applikationen und SEO. Regelmäßig posten wir neue Blogeinträge, verpassen Sie nichts!',
      keywords:
        'Webdesign, Web Development, Web Agency, Webdesign, Webentwicklung, Werbeagentur, Webdesign Berlin, Webentwicklung Berlin, Webagentur Berlin',
    },
  },
}
