import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import {
  ROOT,
  SEO,
  SOCIAL_MEDIA_RECRUITING,
  SOFTWARE_DEVELOPMENT,
  WEB_DESIGN,
  WEB_DEVELOPMENT,
  IMPRINT,
  PRIVACY,
  BLOG,
} from './domain/routes'
import Seo from './pages/Seo/Seo'
import WebDesign from './pages/WebDesign/WebDesign'
import SoftwareDevelopment from './pages/SoftwareDevelopment/SoftwareDevelopment'
import Company from './pages/Company/Company'
import Imprint from './pages/Imprint/Imprint'
import Privacy from './pages/Privacy/Privacy'
import { FUNCTIONAL, hasCookie } from './domain/cookieManager'
import CookieConsent from './components/common/display/CookieConsent/CookieConsent'
import SocialMediaRecruiting from './pages/SocialMediaRecruiting/SocialMediaRecruiting'
import NoMatch from './pages/NoMatch/NoMatch'
import WebDevelopment from './pages/WebDevelopment/WebDevelopment'
import { trackPageView } from './matomo'
import Blog from './pages/Blog/Blog'
import useMatomo from './useMatomo'
import PostDetail from './pages/PostDetail/PostDetail'

const AppRouter = () => {
  const [cookies, setCookies] = useState([])
  const [toggleVisibility, setToggleVisibility] = useState(false)
  const [bannerAccepted, setBannerAccepted] = useState(false)
  const location = useLocation()
  const showCookieConsent = () => {
    setToggleVisibility((prev) => !prev)
  }
  useMatomo()
  const functional = cookies.filter((item) => item.name === FUNCTIONAL)
  let hasAcceptedFunctionalCookie = false
  if (functional.length > 0) {
    hasAcceptedFunctionalCookie = functional[0].checked
  }
  if (hasCookie(FUNCTIONAL)) {
    hasAcceptedFunctionalCookie = true
  }

  useEffect(() => {
    trackPageView()
  }, [location])

  return (
    <>
      <Routes>
        <Route
          exact
          path={ROOT}
          element={
            <Company
              hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
              setShowCookieConsent={showCookieConsent}
              bannerAccepted={bannerAccepted}
            />
          }
        />
        <Route
          exact
          path={SOCIAL_MEDIA_RECRUITING}
          element={
            <SocialMediaRecruiting
              hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
              setShowCookieConsent={showCookieConsent}
              bannerAccepted={bannerAccepted}
            />
          }
        />
        <Route exact path={SOFTWARE_DEVELOPMENT} element={<SoftwareDevelopment />} />
        <Route
          exact
          path={WEB_DEVELOPMENT}
          element={
            <WebDevelopment
              setShowCookieConsent={showCookieConsent}
              hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
              bannerAccepted={bannerAccepted}
            />
          }
        />
        <Route
          exact
          path={WEB_DESIGN}
          element={
            <WebDesign
              setShowCookieConsent={showCookieConsent}
              hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
              bannerAccepted={bannerAccepted}
            />
          }
        />
        <Route exact path={SEO} element={<Seo />} />
        <Route
          exact
          path={IMPRINT}
          element={
            <Imprint setShowCookieConsent={showCookieConsent} bannerAccepted={bannerAccepted} />
          }
        />
        <Route
          exact
          path={PRIVACY}
          element={
            <Privacy setShowCookieConsent={showCookieConsent} bannerAccepted={bannerAccepted} />
          }
        />
        <Route
          exact
          path={BLOG}
          element={
            <Blog
              setShowCookieConsent={showCookieConsent}
              hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
              bannerAccepted={bannerAccepted}
            />
          }
        />
        <Route
          path='/post/:slug'
          element={
            <PostDetail
              setShowCookieConsent={showCookieConsent}
              hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
              bannerAccepted={bannerAccepted}
            />
          }
        />

        <Route
          path='*'
          element={
            <NoMatch
              setShowCookieConsent={showCookieConsent}
              hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
              bannerAccepted={bannerAccepted}
            />
          }
        />
      </Routes>
      <CookieConsent
        setBannerAccepted={setBannerAccepted}
        setCookies={setCookies}
        toggleVisibility={toggleVisibility}
      />
    </>
  )
}

export default AppRouter
