import React from 'react'
import PropTypes from 'prop-types'

import ModalPopUp from '../ModalPopUp/ModalPopUp'
import ContactUsForm from '../../../socialMediaRecruiting/section/ContactUsForm/ContactUsForm'

const ModalContactUsPayment = ({
  showModal,
  setShowModal,
  card,
  image,
  price,
  page,
  section,
  eventName,
}) => (
  <ModalPopUp
    showModal={showModal}
    setShowModal={setShowModal}
    extraClass='max-w-[927px] w-[90%] min-h-[474px] mt-auto sm:my-auto'
  >
    <ContactUsForm
      type='popUp'
      eventName={eventName}
      payment={true}
      card={card}
      image={image}
      price={price}
      page={page}
      section={section}
    />
  </ModalPopUp>
)

ModalContactUsPayment.propTypes = {
  eventName: PropTypes.string,
  card: PropTypes.string,
  page: PropTypes.string,
  section: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}

export default ModalContactUsPayment
