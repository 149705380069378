import React from 'react'
import { useNavigate } from 'react-router-dom'
import usePosts from '../../../customHooks/usePosts'
import RelatedPost from '../../SinglePost/RelatedPost'
import PostCard from '../../common/display/PostCard/PostCard'
import Button from '../../common/inputs/Button/Button'

const RecentArticles = () => {
  const { posts } = usePosts(3, 1)
  const navigate = useNavigate()
  return (
    <div className='section'>
      <h2>Blog</h2>
      <div className='grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[45px] hidden ms:grid'>
        {posts.map((post) => {
          return (
            <PostCard
              key={post.id}
              slug={post.slug}
              title={post.title.rendered}
              excerpt={post.excerpt.rendered}
              category={post._embedded.category[0].name}
              coverImageURL={post.featuredImageURL}
            />
          )
        })}
      </div>
      <div className='flex flex-col gap-[60px] ms:hidden'>
        {posts.map((post) => {
          return (
            <RelatedPost
              key={post?.id}
              slug={post?.slug}
              title={post?.title.rendered}
              category={post?._embedded.category[0].name}
              coverImageURL={post?.featuredImageURL}
            />
          )
        })}
      </div>
      <div className='flex justify-center py-10'>
        <Button text='viewAllBlog' withIcon={true} onClick={() => navigate('/blog')} />
      </div>
    </div>
  )
}

export default RecentArticles
