import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'

const arrowAvg = (
  <svg width='21' height='10' viewBox='0 0 21 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.3815 6L13.7995 8.59L15.2106 10L20.2146 5L15.2106 0L13.7995 1.41L16.3815 4L0.198647 4V6L16.3815 6Z'
      fill='#FFFFFF'
    />
  </svg>
)
const arrowAvg2 = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.17 13L15.59 15.59L17 17L22 12L17 7L15.59 8.41L18.17 11L2 11V13L18.17 13Z'
      fill='#2461FF'
    />
  </svg>
)
const Button = ({ withIcon, text, transparent, onClick, extraClasses }) => {
  return (
    <button
      onClick={onClick}
      className={`hover:opacity-50 px-4 py-3 text-sm x:text-base flex items-center ${extraClasses} ${
        transparent ? 'border-2 border-blue rounded-xl' : 'bg-blue text-white rounded-xl'
      }`}
    >
      <Msg id={`common.button.${text}`} />
      {transparent && (
        <span data-testid='icon' className='pl-2'>
          {arrowAvg2}
        </span>
      )}
      {withIcon && (
        <span data-testid='icon' className='pl-2'>
          {arrowAvg}
        </span>
      )}
    </button>
  )
}
Button.propTypes = {
  text: PropTypes.string,
  extraClasses: PropTypes.string,
  withIcon: PropTypes.bool,
  transparent: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default Button
