import React from 'react'
import PropTypes from 'prop-types'
import Carroussel from './Carroussel'
import Card from './card'

import showcase1 from '../../../../assets/images/main/showcase1.webp'
import showcase2 from '../../../../assets/images/main/showcase2.webp'
import showcase3 from '../../../../assets/images/main/showcase3.webp'

const Showcases = ({ page, categoryName }) => {
  let cards
  if (page === 'webDevelopment') {
    cards = [
      {
        key: 1,
        content: <Card image={showcase1} name='card1' page={page} />,
      },
      {
        key: 2,
        content: <Card image={showcase2} name='card2' page={page} />,
      },
      {
        key: 3,
        content: <Card image={showcase1} name='card3' page={page} />,
      },
    ]
  } else {
    cards = [
      {
        key: 1,
        content: <Card image={showcase3} name='card1' page={page} />,
      },
      {
        key: 2,
        content: <Card image={showcase2} name='card2' page={page} />,
      },
      {
        key: 3,
        content: <Card image={showcase1} name='card3' page={page} />,
      },
    ]
  }

  return (
    <div
      className={`${
        page === 'webDevelopment' ? '' : 'bg-gprimary1'
      } pb-[50px] x:pb-[100px] section`}
    >
      <h2 className='mb-0 x:mb-[50px]'>Showcases</h2>
      <div>
        <Carroussel
          cards={cards}
          height='h-[380px] md:h-[450px]'
          width='80%'
          offset={200}
          showArrows={false}
          categoryName={categoryName}
        />
      </div>
    </div>
  )
}

Showcases.propTypes = {
  page: PropTypes.string,
  categoryName: PropTypes.string,
}

export default Showcases
