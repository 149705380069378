import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
/*import { convertHtmlToReact as toHTML } from '@hedgedoc/html-to-react'*/

import ModalContactUsPayment from '../ModalContactUsPayment/ModalContactUsPayment'
import ModalOurProducts from '../ModalOurProducts/ModalOurProducts'

import check2 from '../../../../assets/images/check2.svg'
/*import infoIcon from '../../../../assets/images/main/info.svg'*/
import saleTag from '../../../../assets/images/main/saleTag.svg'
import Button from '../../inputs/Button/Button'
import { trackEvent } from '../../../../matomo'

const OurProductCard = ({
  card,
  thin,
  tag,
  eventName,
  image,
  showPrice,
  priceMedium,
  priceSmall,
  showDescription,
  /*showInformation,*/
  productCount,
  /*showIcon,*/
  smallOffer,
  bigOffer,
  price,
  offerPrice,
  showProducts,
  hint,
}) => {
  const HtmlToReactParser = require('html-to-react').Parser
  const htmlToReactParser = new HtmlToReactParser()
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const [showModalWebBoost, setShowModalWebBoost] = useState(false)
  const [showModalProducts, setshowModalProducts] = useState(false)

  return (
    <>
      <div
        className={`product__card w-[291px] ${
          thin ? ' ti:w-[292px]' : 'ti:w-[365px]'
        } h-auto shadow-lg rounded-2xl flex flex-col ${
          bigOffer || smallOffer ? 'mb-10 x:mb-0' : 'mb-0'
        }`}
      >
        {(bigOffer || smallOffer) && (
          <div
            className={`rounded-full ${
              bigOffer ? 'w-[88px] h-[88px] -top-11 left-[35%]' : 'w-14 h-14 -top-6 left-[91%]'
            } absolute  border-white text-white flex justify-center items-center`}
          >
            <div>
              <img
                src={saleTag}
                alt={bigOffer ? 'Big Offer' : 'Small Offer'}
                title={bigOffer ? 'Big Offer' : 'Small Offer'}
                loading='lazy'
                className='w-full h-full'
              />
            </div>
          </div>
        )}
        <div className='flex flex-col mb-3'>
          <div className='flex flex-col items-center'>
            <h3
              className={`text-textPrimary ${
                card.includes('webBoost')
                  ? 'text-[22px]'
                  : 'text-[20px] sm:text-[24px] lg:text-[32px]'
              } font-semibold leading-[48px] ${bigOffer || smallOffer ? 'pt-[51px]' : 'pt-[30px]'}`}
            >
              <Msg id={`company.ourProducts.${card}.title`} defaultMessage='title' />
            </h3>
            <div className={`items-center ${smallOffer || bigOffer ? 'pb-0' : 'pb-[30px]'}`}>
              {showPrice ? (
                <div className='flex flex-col'>
                  <div className='drop-shadow-lg pl-[120px] lg:pl-[220px]'>
                    <p
                      className={`font-semibold text-blueLight text-[16px] drop-shadow-xl mb-[-12px] ${
                        priceMedium && 'pr-[80px] mt-[5px]'
                      }`}
                    >
                      {tag && <Msg id={`company.ourProducts.${card}.tag`} defaultMessage='tag' />}
                    </p>
                  </div>
                  <div>
                    <p
                      className={`font-bold text-blue ${
                        priceSmall
                          ? 'text-[25px] leading-[30px] px-[10px] mb-[-15px]'
                          : priceMedium
                          ? 'text-[20px] lg:text-[30px] mb-[-10px] mt-[10px]' // eslint-disable-line
                          : 'text-[40px] lg:text-[54px] leading-[55px] xx' // eslint-disable-line
                      } text-center pt-1 `}
                    >
                      {price}
                    </p>
                  </div>
                </div>
              ) : (
                <div className='flex'>
                  <p className='font-bold text-blue pt-[35px] text-[40px] lg:text-[54px] text-center'>
                    <Msg id={`company.ourProducts.${card}.free`} defaultMessage='free' />
                  </p>
                </div>
              )}
              {(smallOffer || bigOffer) && (
                <p className='font-bold text-black text-[20px] lg:text-[24px] text-center line-through pt-6 pb-5'>
                  {offerPrice}
                </p>
              )}
            </div>

            <div>
              {showDescription && (
                <div className='flex px-[30px]'>
                  <p className='font-normal text-black text-[16px] leading-[24px] pb-[20px] hyphenateManual'>
                    {htmlToReactParser.parse(
                      intl.formatMessage({
                        id: `company.ourProducts.${card}.desc`,
                        defaultMessage: 'description',
                      })
                    )}
                  </p>
                </div>
              )}
              {/*showInformation && (
                <div className='flex px-[40px] pb-[25px]'>
                  <p className='font-bold text-black text-[16px] leading-[24px]'>
                    <Msg id={`company.ourProducts.${card}.info`} defaultMessage='info' />
                  </p>
                </div>
              )*/}
            </div>
          </div>
          <div
            className={`flex flex-col ${productCount >= 5 ? 'gap-[9px]' : 'gap-5'} px-[20px]  ${
              !thin && 'lg:max-h-[277px]'
            }`}
          >
            {Array.from({ length: productCount }).map((_, index /*, { length }*/) => (
              <div key={index} className='flex'>
                <div className='h-[30px] w-[30px] group relative inline-block text-blue-500 underline hover:text-red-500 duration-300'>
                  <img
                    src={check2}
                    alt='Our Services check'
                    title='Our Services check'
                    loading='lazy'
                    className={`h-[30px] w-[30px] ${thin && 'max-w-[20px]'}`}
                  />
                  {/*<span className="absolute hidden group-hover:flex -left-5 -top-2 -translate-y-full w-48 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700">
                    This is some extra useful information
                  </span>*/}
                </div>

                <div
                  className={`flex w-[200px] ${thin ? 'lg:max-w-[220px]' : ' lg:min-w-[250px]'}`}
                >
                  <p className='text-[16px] leading-[24px] place-self-center font-normal hyphenateManual pl-2'>
                    {htmlToReactParser.parse(
                      intl.formatMessage({
                        id: `company.ourProducts.${card}.check${index + 1}`,
                        defaultMessage: 'check',
                      })
                    )}
                  </p>
                  {/*showIcon && length - 1 === index && (
                    <div className='group cursor-pointer relative '>
                      <img
                        src={infoIcon}
                        alt='Our Services info'
                        loading='lazy'
                        className='h-[33px] w-[34px] ml-2'
                      />
                      <div className='whitespace-pre-line opacity-0 w-[160px] ms:w-[200px] bg-black text-white text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -ml-[70px] ms:-ml-[85px] mb-2 px-3 pointer-events-none'>
                        <Msg
                          id={`company.ourProducts.${card}.otherServices`}
                          defaultMessage='otherServices'
                        />
                        <svg
                          className='absolute text-black h-2 w-full left-0 top-full'
                          x='0px'
                          y='0px'
                          viewBox='0 0 255 255'
                          xmlSpace='preserve'
                        >
                          <polygon className='fill-current' points='0,0 127.5,127.5 255,0' />
                        </svg>
                      </div>
                    </div>
                  )*/}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-col items-center h-auto mt-auto pt-10 ti:pt-0'>
          {showProducts ? (
            <Button
              onClick={() => {
                trackEvent('Home Page', 'Click', eventName, offerPrice)
                setshowModalProducts(true)
              }}
              extraClasses='font-semibold text-blue'
              withIcon={true}
              transparent={false}
              text={`${card}`}
            />
          ) : thin ? (
            <Button
              onClick={() => {
                trackEvent('Home Page', 'Click', eventName, offerPrice)
                setShowModalWebBoost(true)
              }}
              extraClasses='font-semibold text-blue mb-8'
              withIcon={true}
              transparent={false}
              text={`${card}`}
            />
          ) : (
            <Button
              onClick={() => {
                trackEvent('Home Page', 'Click', eventName, offerPrice)
                setShowModal(true)
              }}
              extraClasses='font-semibold text-blue'
              withIcon={true}
              transparent={false}
              text={`${card}`}
            />
          )}
          {hint && (
            <div className='flex items-center'>
              <p className='font-semibold text-black text-sm leading-[24px] whitespace-pre-line text-center pt-2 pb-5'>
                <Msg id={`company.ourProducts.${card}.hint`} defaultMessage='hint' />
              </p>
            </div>
          )}
        </div>
      </div>
      {showModalProducts && (
        <ModalOurProducts showModal={showModalProducts} setShowModal={setshowModalProducts} />
      )}
      {showModal && (
        <ModalContactUsPayment
          showModal={showModal}
          setShowModal={setShowModal}
          eventName={eventName}
          card={card}
          image={image}
          price={price}
          page={'company'}
          section={'ourProducts'}
        />
      )}
      {showModalWebBoost && (
        <ModalContactUsPayment
          showModal={showModalWebBoost}
          setShowModal={setShowModalWebBoost}
          eventName={eventName}
          card={card}
          image={image}
          price={price}
          page={'company'}
          section={'ourProducts'}
        />
      )}
    </>
  )
}

OurProductCard.propTypes = {
  card: PropTypes.string,
  tag: PropTypes.bool,
  thin: PropTypes.bool,
  eventName: PropTypes.string,
  image: PropTypes.string,
  showPrice: PropTypes.bool,
  priceSmall: PropTypes.bool,
  priceMedium: PropTypes.bool,
  showDescription: PropTypes.bool,
  showInformation: PropTypes.bool,
  productCount: PropTypes.number,
  /*showIcon: PropTypes.bool,*/
  smallOffer: PropTypes.bool,
  bigOffer: PropTypes.bool,
  price: PropTypes.string,
  offerPrice: PropTypes.string,
  showProducts: PropTypes.bool,
  hint: PropTypes.bool,
}

export default OurProductCard
