// add matemo to the page
import React, { Suspense, useState } from 'react'
import PropTypes from 'prop-types'

import MetaData from '../MetaData/MetaData'
import Navbar from '../../components/common/sections/Navbar/Navbar'
import { navItemsWDevelopment } from '../navItems'
import Why from '../../components/common/sections/Why/Why'
import logo from '../../assets/images/welitzki-it-logo-short.svg'
import Hero from '../../components/common/sections/Hero/Hero'
import Footer from '../../components/common/sections/Footer/Footer'
import Showcases from '../../components/common/sections/Showcases/Showcases'
import Services from '../../components/WebDevelopment/Services/Services'
import CallToAction from '../../components/socialMediaRecruiting/section/CallToAction/CallToAction'
import NewsletterCTA from '../../components/common/sections/NewsletterCTA/NewsletterCTA'
import ContactUs from '../../components/socialMediaRecruiting/section/ContactUs/ContactUs'
import Products from '../../components/WebDevelopment/section/Products/Products'
import Faq from '../../components/WebDevelopment/section/Faq/Faq'
import ModalCalendly from '../../components/common/display/ModalPopUp/ModalCalendly'
import ModalContactUs from '../../components/common/display/ModalContactUs/ModalContactUs'

const WebDevelopment = ({ hasAcceptedFunctionalCookie, setShowCookieConsent, bannerAccepted }) => {
  const [showCalendlyContactForm, setShowCalendlyContactForm] = React.useState(false)
  const [showModal, setShowModal] = useState(false)
  return (
    <div className='page-wrapper'>
      <MetaData
        title='Entwicklung professioneller Web-Applikationen'
        description='Unser Team ist spezialisiert auf die Entwicklung vonhochprofessioneller Web-Applikationen und Software. Unser Team ist darauf geschult, mit den modernsten Methoden und Technologien zu arbeiten um das gewünschte Produkt unserer Kunden aushändigen zu können.'
        keywords='Webdesign, Web Development, Web Agency, Webdesign, Webentwicklung, Werbeagentur, Webdesign Berlin, Webentwicklung Berlin, Webagentur Berlin'
        link='/web-entwicklung'
      />
      <div className='wd__bg'>
        <Navbar
          navItems={navItemsWDevelopment}
          secondVarient={true}
          name='webDevelopment'
          logo={logo}
          categoryName='WebDevelopment Page'
        />
        <div id='start'>
          <Hero page='webDevelopment' categoryName='WebDevelopment Page' />
        </div>
        <div id='warum'>
          <Why name='webDevelopment' desc={false} />
        </div>
        <div id='showcases'>
          <Suspense>
            <Showcases page='webDevelopment' categoryName='WebDevelopment Page' />
          </Suspense>
        </div>
        <div id='services'>
          <Services name='webDevelopment' />
        </div>
        <CallToAction page='webDevelopment' categoryName='WebDevelopment Page' />
        <div>
          <Products />
        </div>
        <NewsletterCTA categoryName='WebDevelopment Page' bg='white' />
      </div>
      <div className='wd__bg_secondary'>
        <ContactUs
          hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
          name='webDevelopment'
        />
        <Faq />
        <Footer
          theme={'light'}
          setShowModal={setShowModal}
          setShowCookieConsent={setShowCookieConsent}
          categoryName='WebDevelopment Page'
          bannerAccepted={bannerAccepted}
        />
      </div>
      <ModalCalendly
        showModal={showCalendlyContactForm}
        setShowModal={setShowCalendlyContactForm}
        hasAcceptedFunctionalCookie={hasAcceptedFunctionalCookie}
      />
      <ModalContactUs showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

WebDevelopment.propTypes = {
  hasAcceptedFunctionalCookie: PropTypes.bool,
  setShowCookieConsent: PropTypes.func,
  bannerAccepted: PropTypes.bool,
}

export default WebDevelopment
