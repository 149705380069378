import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg, useIntl } from 'react-intl'

import Navbar from '../../components/common/sections/Navbar/Navbar'
import Hero from '../../components/Main/Hero/Hero'
import Footer from '../../components/common/sections/Footer/Footer'
import ModalContactUs from '../../components/common/display/ModalContactUs/ModalContactUs'
import { navItemsMain } from '../navItems'

import rootLogo from '../../assets/images/welitzki-it-logo-long.svg'
import ScrollToTop from '../../scrollToView'
import MetaData from '../MetaData/MetaData'

const Imprint = ({ setShowCookieConsent, bannerAccepted }) => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <ScrollToTop />
      <MetaData
        title='Impressum – Rechtliche Informationen & Kontaktabgaben'
        description='Entdecken Sie die wesentlichen rechtlichen Informationen, Kontaktangaben und verantwortlichen Personen für Nicolas Welitzki IT-Beratung. In Deutschland ansässig, sind wir der Transparenz und Einhaltung deutscher Vorschriften verpflichtet.'
        keywords='Impressum, rechtliche Informationen, Kontaktangaben, deutsche Vorschriften, verantwortliche Personen, Haftung, Website-Betreiber, Offenlegung'
        link='/impressum/'
      />

      <div className='page-wrapper'>
        <Navbar navItems={navItemsMain} secondVarient={false} logo={rootLogo} />
        <div role='main'>
          <Hero
            heroText={intl.formatMessage({
              id: 'socialMediaRecruiting.imprint.title',
              defaultMessage: 'Imprint',
            })}
          />
          <div className='layout grid bg-white justify-center item-center'>
            <div className='flex-col mi:mx-[0px] w-[292px] ti:w-auto my-[60px] bg-secondary rounded-xl justify-center item-center align-middle self-center  shadow-xl  mi:h-[980px] ms:h-[920px] mm:h-[900px] sm:h-[820px]'>
              <div className='px-[20.05px] ti:px-[59.66px] text-left my-[34.86px] ti:my-[50px]'>
                <h2 className='h3 w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue'>
                  <Msg
                    id='socialMediaRecruiting.imprint.heading1'
                    defaultMessage='According to § 5 TMG:'
                  />
                </h2>
                <p className='flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
                  <span>Nicolas Welitzki</span>
                  <span>Poschingerstr. 30</span>
                  <span>12157 Berlin</span>
                </p>

                <h3 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[28px] ti:pt-[42px]'>
                  <Msg
                    id='socialMediaRecruiting.imprint.heading2'
                    defaultMessage='Tax identification number:'
                  />
                </h3>
                <p className='flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
                  <span>
                    <Msg
                      id='socialMediaRecruiting.imprint.content1'
                      defaultMessage='Phone: +49 151 23246715'
                    />
                  </span>
                  <span>E-Mail: office@welitzki-it.de</span>
                </p>

                <h3 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[28px] ti:pt-[42px]'>
                  <Msg
                    id='socialMediaRecruiting.imprint.heading3'
                    defaultMessage='Content responsibility:'
                  />
                </h3>
                <p className='flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
                  <span>
                    <Msg
                      id='socialMediaRecruiting.imprint.content2'
                      defaultMessage='Sales tax identification number according to § 27 a sales tax law:'
                    />
                  </span>
                  <span>DE325618717</span>
                </p>
                <h3 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[28px] ti:pt-[42px]'>
                  <Msg
                    id='socialMediaRecruiting.imprint.heading4'
                    defaultMessage='Content responsibility:'
                  />
                </h3>
                <p className='flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
                  <span>Nicolas Welitzki</span>
                  <span>Poschingerstr. 30</span>
                  <span>12157 Berlin</span>
                </p>
                <h3 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[28px] ti:pt-[42px]'>
                  <Msg
                    id='socialMediaRecruiting.imprint.heading5'
                    defaultMessage='Consumer dispute resolution/universal arbitration board'
                  />
                </h3>
                <p className='flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
                  <span>
                    <Msg
                      id='socialMediaRecruiting.imprint.content3'
                      defaultMessage='We are not willing or obliged to participate in dispute settlement procedures before a consumer arbitration board.'
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer
          theme={'light'}
          setShowModal={setShowModal}
          setShowCookieConsent={setShowCookieConsent}
          categoryName='Imprint Page'
          bannerAccepted={bannerAccepted}
        />
      </div>
      <ModalContactUs showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}

Imprint.propTypes = {
  setShowCookieConsent: PropTypes.func.isRequired,
  bannerAccepted: PropTypes.bool,
}

export default Imprint
