import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import WhyCards from '../../../common/display/WhyCards/WhyCards'
import ResponsiveWebDesign from '../../../../assets/images/webDesign/ResponsiveWebDesign.svg'
import CMSSEO from '../../../../assets/images/webDesign/CMSSEO.svg'
import Advisory from '../../../../assets/images/webDesign/Advisory.svg'
import Support from '../../../../assets/images/webDesign/support.svg'

const WhyUs = () => {
  const data = [
    {
      id: 1,
      card: 'cardOne',
      image: ResponsiveWebDesign,
    },
    {
      id: 2,
      card: 'cardTwo',
      image: CMSSEO,
    },
    {
      id: 3,
      card: 'cardThree',
      image: Advisory,
    },
    {
      id: 4,
      card: 'cardFour',
      image: Support,
    },
  ]
  return (
    <div className='bg-gprimary1 pb-10 lg:pb-[70px] section-without-padding'>
      <h2>
        <Msg id='webDesign.whyUs.heading' />
      </h2>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-[50px] mx-[35px] x:mx-[100px] xl:mx-[214px]'>
        {data.map((item) => {
          return <WhyCards key={item.id} card={item.card} image={item.image} />
        })}
      </div>
    </div>
  )
}

export default WhyUs
