import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage as Msg } from 'react-intl'

import mail from '../../../../assets/images/main/mail.svg'
import { trackEvent } from '../../../../matomo'

const CardsAboutPerson = ({
  image,
  name,
  role,
  details,
  email,
  hasItalicDetails = false,
  categoryName,
}) => {
  const HtmlToReactParser = require('html-to-react').Parser
  const htmlToReactParser = new HtmlToReactParser()
  const [isMoreTextVisible, setIsMoreTextVisible] = useState(false)

  const toggleMoreText = () => {
    setIsMoreTextVisible(!isMoreTextVisible)
  }

  return (
    <div className='bg-secondary px-5 x:px-[52px] max-w-[291px] mi:max-w-[365px] rounded-[19px] shadow-xl pb-8 h-auto flex flex-col justify-between'>
      <div>
        <img
          src={image}
          alt={name}
          title={name}
          className='w-24 h-24 x:w-[137px] x:h-[137px] mx-auto mt-[30px] object-contain rounded-full'
          loading='lazy'
        />

        <div className='font-bold text-[22px] leading-[33px] text-center text-textPrimary pt-5 x:pt-3'>
          {name}
        </div>
        <div className='whitespace-pre-line font-medium text-lg x:text-xl leading-[27px] x:leading-[30px] text-center px-[26px] x:px-[10px] pt-4 x:pt-[10px]'>
          {role}
        </div>
        <div
          className={`font-medium text-base text-center leading-[27px] pt-[23px] x:pt-5 hyphenateManual ${
            hasItalicDetails && 'italic'
          }`}
        >
          {email ? (
            htmlToReactParser.parse(details)
          ) : isMoreTextVisible ? (
            <>
              {htmlToReactParser.parse(details)}
              <div className='mt-2'>
                <button onClick={toggleMoreText} className='text-blueLight focus:outline-none'>
                  <Msg id='company.reference.hide' defaultMessage='Hide' />
                </button>
              </div>
            </>
          ) : (
            <>
              {details && htmlToReactParser.parse(details).slice(0, 200)}
              {details.length > 200 && (
                <>
                  {'...'}
                  <div className='mt-2'>
                    <button
                      onClick={toggleMoreText}
                      className='text-blueLight focus:outline-none'
                      data-testid='read-more'
                    >
                      <Msg id='company.reference.read' defaultMessage='Read more' />
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {email && (
        <div className='pb-10'>
          <a
            onClick={() =>
              trackEvent(categoryName, 'Email Click', 'home_employee_email_navigation')
            }
            href={`mailto:${email}?subject=Hallo ${name}`}
            title='Write us!'
          >
            <img
              src={mail}
              alt={name}
              title={name}
              className='w-8 h-[24px] mx-auto mt-7 hidden x:block'
            />
          </a>
        </div>
      )}
    </div>
  )
}

CardsAboutPerson.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  email: PropTypes.string,
  hasItalicDetails: PropTypes.bool,
  categoryName: PropTypes.string,
}

export default CardsAboutPerson
