import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import Button from '../../../common/inputs/Button/Button'
import aboutUs from '../../../../assets/images/main/quoteNic.svg'
import { trackEvent } from '../../../../matomo'

const AboutUs = () => {
  const navigate = useNavigate()
  return (
    <div className='section'>
      <h2>
        <Msg id='webDesign.aboutUs.heading' defaultMessage='About Us' />
      </h2>
      <div className='flex flex-col x:flex-row gap-10 x:gap-[105px] items-center'>
        <img
          className='lg:w-[394px] lg:h-[343px] mx-auto x:mx-0 my-0 py-0 object-contain'
          src={aboutUs}
          alt='About Us'
          title='About Us'
          loading='lazy'
        />
        <div className='flex items-center'>
          <div className='flex flex-col leading-relaxed font-normal text-base'>
            <div>
              <Msg
                id='webDesign.aboutUs.firstParagraph'
                defaultMessage='Nice that you have found us! We offer tailor-made solutions for small and medium-sized companies in the DACH region. With an experienced and passionate team, the company prioritizes usability and user experience to design attractive and functional websites. Would you like to find out more about us?'
              />
            </div>
            <div>
              <Button
                onClick={() => {
                  trackEvent('WebDesign Page', 'Navigate', 'wdesign_aboutus_btn_navigate_to_home')
                  navigate('/')
                }}
                extraClasses='font-semibold text-white h-[42px] mt-[35px]'
                withIcon={true}
                transparent={false}
                text='ourServices'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
