import React, { useState, useEffect } from 'react'
import { FormattedDate, useIntl, FormattedMessage as Msg } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import MetaData from '../../pages/MetaData/MetaData'
import { ShareSocial } from 'react-share-social'
import api from '../../api'
import RelatedPost from './RelatedPost'
import usePosts from '../../customHooks/usePosts'
import Spinner from '../common/display/Spinner/Spinner'
import Button from '../common/inputs/Button/Button'

const style = {
  root: {
    padding: '0px',
  },
  copyContainer: {
    display: 'none',
  },
  title: {
    color: 'aquamarine',
    fontStyle: 'italic',
  },
}

const SinglePost = ({ slug }) => {
  const intl = useIntl()
  const [post, setPost] = useState(null)
  const { posts } = usePosts(4, 1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true)
      try {
        const response = await api.get(`/posts?slug=${slug}&_embed`)
        if (response.data.length > 0) {
          const postData = response.data[0]

          // Get author's display name from the _embedded property
          postData.authorName = postData._embedded.author[0].name

          // Fetch featured image URL
          if (postData.featured_media) {
            const mediaResponse = await api.get(`/media/${postData.featured_media}`)
            postData.featuredImageURL = mediaResponse.data.source_url
          } else {
            postData.featuredImageURL = null
          }

          setPost(postData)
        } else {
          setPost(null)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchPost()
  }, [slug])
  const location = useLocation()
  const navigate = useNavigate()

  const handleGoBack = () => {
    if (location.key !== 'default') {
      navigate(-1)
    } else {
      navigate('/blog')
    }
  }

  return (
    <>
      {loading ? (
        <div className='h-[700px] flex items-center'>
          <Spinner />
        </div>
      ) : (
        <div className='flex flex-col lg:flex-row gap-8 pt-[20px] layout__navbar'>
          <MetaData title={post?.title.rendered} description={post?.excerpt?.rendered} />
          <div>
            <Button text='goBack' className='' onClick={() => handleGoBack()} />
            <h1
              className='not-italic font-semibold text-4xl text-black leading-[54px]'
              dangerouslySetInnerHTML={{ __html: post?.title.rendered }}
            />
            <div className='not-italic font-light text-xl leading-7 text-black -mb-[10px] relative z-[4]'>
              <Msg id='blog.by' defaultMessage='by ' />
              {post?.authorName} <br />
              {!intl.locale.includes('en') ? (
                <FormattedDate
                  value={post?.date && new Date(post.date)}
                  day='numeric'
                  month='long'
                  year='numeric'
                />
              ) : (
                <FormattedDate
                  value={post?.date && new Date(post.date)}
                  weekday='short'
                  day='numeric'
                  month='long'
                  year='numeric'
                />
              )}
            </div>
            <div>
              <ShareSocial
                url={window.location.href}
                socialTypes={['facebook', 'twitter', 'linkedin', 'whatsapp']}
                style={style}
              />
            </div>
            <div className='h-[248px] lg:h-[564px] flex justify-center items-center'>
              <img
                className='max-w-full max-h-full object-contain'
                src={post?.featuredImageURL}
                alt='cover'
              />
            </div>

            <div
              className='blog-article not-italic font-normal text-[16px] leading-[170%] text-black mt-4 text-justify'
              dangerouslySetInnerHTML={{ __html: post?.content.rendered }}
            />
          </div>
          <div className='lg:w-[30%] h-auto'>
            <p className='bg-relatedPostBG not-italic font-light text-lg leading-7 text-center text-black py-5'>
              <Msg id='blog.moreFromUs' defaultMessage='More from us' />
            </p>

            <div className='flex flex-col gap-4 bg-relatedPostBG pb-10'>
              {posts?.map((post) => {
                if (post?.slug !== slug) {
                  return (
                    <RelatedPost
                      key={post?.id}
                      slug={post?.slug}
                      title={post?.title.rendered}
                      category={post?._embedded.category[0].name}
                      coverImageURL={post?.featuredImageURL}
                    />
                  )
                }
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

SinglePost.propTypes = {
  slug: PropTypes.string,
}

export default SinglePost
