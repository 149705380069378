import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'
// eslint-disable-next-line import/no-unassigned-import
import '@fontsource/poppins'
import '@fontsource/poppins/600.css'

const container = document.getElementById('root')
const root = createRoot(container)

const UnsecuredPage = () => (
  <div>
    <h1>
      If you see this page, your link you have clicked on is under Clickjacking security attack.
    </h1>
    <br />
    <h2>
      Please inform our security team with the reference of the application from where you clicked
      this link.
    </h2>
    <h2>
      Click{' '}
      <a href={window.self.location.href} title='Web Application' target='blank'>
        * here *
      </a>{' '}
      to access our page safely.
    </h2>
  </div>
)

// Won't render the application if WebApp is under Clickjacking attack
if (window.self === window.top) {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
} else {
  root.render(<UnsecuredPage />)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
