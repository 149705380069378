import React, { useState } from 'react'
import PostCard from '../../common/display/PostCard/PostCard'
import usePosts from '../../../customHooks/usePosts'
import PostCardSkeleton from '../../common/display/PostCardSkeleton/PostCardSkeleton'

const AllPosts = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { posts, totalPages, loading } = usePosts(6, currentPage)

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const handlePageClick = (page) => {
    setCurrentPage(page)
  }
  return (
    <>
      {loading ? (
        <div className='page-wrapper px-[35px] md:px-10 lg:px-32 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[46px]'>
          <PostCardSkeleton />
          <PostCardSkeleton />
          <PostCardSkeleton />
        </div>
      ) : (
        <>
          {posts.length === 0 ? (
            <div className='flex flex-col justify-center w-full min-h-[600px]'>
              <h2 className='text-2xl font-semibold mb-4 text-center'>No Posts Available</h2>
              <p className='text-base text-center'>
                We couldnot find any posts at the moment. Please check back later.
              </p>
            </div>
          ) : (
            <>
              <div className='page-wrapper px-[35px] md:px-10 lg:px-32 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[46px]'>
                {posts.map((post) => {
                  return (
                    <PostCard
                      key={post.id}
                      slug={post.slug}
                      title={post.title.rendered}
                      excerpt={post.excerpt.rendered}
                      category={post._embedded.category[0].name}
                      coverImageURL={post.featuredImageURL}
                    />
                  )
                })}
              </div>
              {/* {posts.length > 0 && ( */}
              <div className='pagination flex justify-center mt-[45px] md:mt-[102px]'>
                <button
                  className={`mx-2 py-2 px-4 border rounded-full text-base ${
                    currentPage === 1
                      ? 'border-gray-400 text-gray-400'
                      : 'border-blue text-blue hover:bg-blue hover:text-white'
                  }`}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    className={`w-10 flex flex-col justify-center items-center mx-1 py-1 px-4 border border-blue text-blue hover:bg-blue hover:text-white rounded-full text-lg ${
                      currentPage === index + 1 ? 'bg-blue text-white' : ''
                    }`}
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  className={`mx-2 py-2 px-4 border rounded-full text-base ${
                    currentPage === totalPages
                      ? 'border-gray-400 text-gray-400'
                      : 'border-blue text-blue hover:bg-blue hover:text-white'
                  }`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
              {/* )} */}
            </>
          )}
        </>
      )}
    </>
  )
}

export default AllPosts
