import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import OurServicesCard from '../../common/display/OurServicesCard/OurServicesCard'

import service1 from '../../../assets/images/service1.svg'
import service2 from '../../../assets/images/service2.svg'
import service3 from '../../../assets/images/service3.svg'
import service4 from '../../../assets/images/service4.svg'
import { SOCIAL_MEDIA_RECRUITING, WEB_DESIGN, WEB_DEVELOPMENT } from '../../../domain/routes'

const OurServices = () => {
  const data = [
    {
      id: 1,
      card: 'cardOne',
      button: true,
      image: service1,
      extraClass2: 'w-[39.44px]  h-[34.93px] sm:w-[55px] sm:h-[48px]',
      link: WEB_DESIGN,
    },
    {
      id: 2,
      card: 'cardTwo',
      button: false,
      image: service2,
      extraClass2: 'w-[50px] h-[35px] sm:w-[68px] sm:h-[52px]',
      link: WEB_DEVELOPMENT,
    },
    {
      id: 3,
      card: 'cardThree',
      button: false,
      image: service3,
      extraClass2: 'w-[48px] h-[29px] sm:w-[61px] sm:h-[37px]',
      link: SOCIAL_MEDIA_RECRUITING,
    },
    {
      id: 4,
      card: 'cardFour',
      button: false,
      image: service4,
      extraClass2: 'w-[39px] h-[40px] sm:w-[60px] sm:h-[64px]',
    },
  ]

  return (
    <div className='section-without-padding' id='services'>
      <h2>
        <Msg id='socialMediaRecruiting.ourServices.title' />
      </h2>
      <div className='grid grid-cols-1 x:grid-cols-2 gap-[42px] xl:gap-[82px] mx-auto px-[15px] ti:px-[34px] xl:px-[130px] '>
        {data.map((item) => (
          <OurServicesCard
            key={item.id}
            card={item.card}
            image={item.image}
            extraClass2={item.extraClass2}
            href={item.link}
          />
        ))}
      </div>
    </div>
  )
}

export default OurServices
