import React from 'react'
import { FormattedMessage as Msg, useIntl } from 'react-intl'
import PropTypes from 'prop-types'

const WhyCards = ({ card, image }) => {
  const intl = useIntl()

  return (
    <div className='why__we__card rounded-[29px] w-[291px] h-[478px] py-10 px-5 lg:px-10 lg:w-[480px] lg:h-[376px] mx-auto'>
      <img
        className='mx-auto h-[75px] w-[100px]'
        src={image}
        alt={intl.formatMessage({
          id: `webDesign.whyUs.${card}.heading`,
        })}
        title={intl.formatMessage({
          id: `webDesign.whyUs.${card}.heading`,
        })}
      />
      <h3 className='font-bold text-xl lg:text-2xl leading-[30px] lg:leading-9 text-textPrimary pt-10'>
        <Msg id={`webDesign.whyUs.${card}.heading`} />
      </h3>
      <p className='font-normal text-base leading-[27.2px] col-textSecondary pt-[10px]'>
        <Msg id={`webDesign.whyUs.${card}.paragraph`} />
      </p>
    </div>
  )
}

WhyCards.propTypes = {
  card: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}

export default WhyCards
