import React from 'react'

const Seo = () => (
  <div className='page-wrapper'>
    <h1>Search Engine Optimization</h1>
    <p>A new website will soon be created here.</p>
  </div>
)

export default Seo
