import React from 'react'
import { useIntl } from 'react-intl'

import DavidBieder from '../../../assets/images/main/DavidBieder.webp'
import Rene from '../../../assets/images/main/Rene.webp'
import defaultPerson from '../../../assets/images/main/defaultPerson.webp'
import CardsAboutPerson from '../../common/display/CardsAboutPerson/CardsAboutPerson'

const ReferencesSection = () => {
  const intl = useIntl()

  const data = [
    {
      id: 1,
      image: Rene,
      name: intl.formatMessage({
        id: 'company.reference.rene.name',
        defaultMessage: 'René Böttiger',
      }),
      role: intl.formatMessage({
        id: 'company.reference.rene.role',
        defaultMessage: 'Actor and director (Theatre)',
      }),
      details: intl.formatMessage({
        id: 'company.reference.rene.details',
        defaultMessage:
          'Nicolas is very competent, punctual and honest. He explains everything very well, even if you are not an expert in the subject.',
      }),
    },
    {
      id: 2,
      image: DavidBieder,
      name: intl.formatMessage({
        id: 'company.reference.david.name',
        defaultMessage: 'David Bieder',
      }),
      role: intl.formatMessage({
        id: 'company.reference.david.role',
        defaultMessage: 'Managing Director at cultivate GmbH',
      }),
      details: intl.formatMessage({
        id: 'company.reference.david.details',
        defaultMessage:
          'I enjoyed working with Nicolas in 2019 and 2020 on a B2C React Redux app for Immoscout24. He implemented several features that affected the entire app stack.',
      }),
    },
    {
      id: 3,
      image: defaultPerson,
      name: intl.formatMessage({
        id: 'company.reference.andreas.name',
        defaultMessage: 'Andreas Lerch',
      }),
      role: intl.formatMessage({
        id: 'company.reference.andreas.role',
        defaultMessage: 'Account Director at Syzygy',
      }),
      details: intl.formatMessage({
        id: 'company.reference.andreas.details',
        defaultMessage:
          'Nicolas works independently and reliably in his field of activity. Our cooperation was very pleasant',
      }),
    },
  ]

  return (
    <div className='section' id='referenzen'>
      <h2>
        {intl.formatMessage({
          id: 'company.reference.title',
          defaultMessage: 'References',
        })}
      </h2>
      <div className='section grid justify-center x:grid-flow-col x:auto-cols-max gap-[47px]'>
        {data.map((card) => (
          <CardsAboutPerson
            key={card.id}
            image={card.image}
            name={card.name}
            role={card.role}
            details={card.details}
            hasItalicDetails={true}
          />
        ))}
      </div>
    </div>
  )
}

export default ReferencesSection
