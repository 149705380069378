import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../inputs/Button/Button'
import ModalContactUsPayment from '../ModalContactUsPayment/ModalContactUsPayment'
import { trackEvent } from '../../../../matomo'

const WdProductCard = ({ image, name, desc, card, eventName }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div className='bg-secondary px-5 x:px-[16px] w-[291px] h-[427px] ti:w-[365px] ti:h-[437px] rounded-[19px] shadow-xl pb-8 x:pb-[0px] flex flex-col justify-between'>
        <div>
          <img
            src={image}
            alt={name}
            title={name}
            className='w-[203px] h-[175px] mx-auto mt-[30px]'
          />
          <div className='font-semibold text-[18px] x:text-[24px] leading-[27px] x:leading-[36px] text-center text-textPrimary pt-[30px] md:pt-[17px]'>
            {name}
          </div>
          <div className='font-bold text-blue text-[16px] x:text-[20px]  leading-[24px] x:leading-[30px] text-center px-[0px] x:px-[0px] pt-[20px] md:py-[21px] mb-auto h-[100px]'>
            {desc}
          </div>
        </div>
        <div className='flex flex-col items-center h-auto ti:h-[124px] pb-10'>
          <Button
            onClick={() => {
              trackEvent('WebDevelopment Page', 'Click', eventName)
              setShowModal(true)
            }}
            extraClasses='font-semibold text-blue'
            withIcon={true}
            transparent={false}
            text='inquiry'
          />
        </div>
      </div>
      {showModal && (
        <ModalContactUsPayment
          showModal={showModal}
          setShowModal={setShowModal}
          eventName={eventName}
          card={card}
          image={image}
          page={'webDevelopment'}
          section={'products'}
        />
      )}
    </>
  )
}

WdProductCard.propTypes = {
  card: PropTypes.string,
  eventName: PropTypes.string,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}
export default WdProductCard
