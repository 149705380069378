import React, { useEffect, useState } from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import PropTypes from 'prop-types'
import { scroller } from 'react-scroll'
import { useNavigate } from 'react-router-dom'

import { SOCIAL_MEDIA_RECRUITING, WEB_DESIGN, WEB_DEVELOPMENT } from '../../../../domain/routes'
import Button from '../../inputs/Button/Button'

import burgerMenu from '../../../../assets/images/burgerMenu.svg'
import cross from '../../../../assets/images/cross.svg'
import { trackEvent } from '../../../../matomo'

const Navbar = ({ navItems, secondVarient, name, categoryName, logo }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  let prevScrollPos = window.pageYOffset

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false)
      const currentScrollPos = window.pageYOffset
      if (prevScrollPos > currentScrollPos || currentScrollPos < 50) {
        document.getElementsByTagName('nav')[0].style.top = '0'
      } else {
        document.getElementsByTagName('nav')[0].style.top =
          window.innerWidth > 1023 ? '-119px' : '-60px'
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      prevScrollPos = currentScrollPos
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const getPath = (item) => {
    if (name === 'socialMediaRecruiting') {
      return `${SOCIAL_MEDIA_RECRUITING}#${item.href}`
    }
    if (name === 'webDesign') {
      return `${WEB_DESIGN}#${item.href}`
    }
    if (name === 'webDevelopment') {
      return `${WEB_DEVELOPMENT}#${item.href}`
    }
    return `/#${item.href}`
  }

  const trackEventNav = (item) => {
    let eventName
    if (categoryName === 'Home Page') {
      eventName = `home_navbar_item_click_${item}`
    } else if (categoryName === 'WebDesign Page') {
      eventName = `wdesign_navbar_item_click_${item}`
    } else if (categoryName === 'SocialMediaRecruiting Page') {
      eventName = `smr_navbar_item_click_${item}`
    } else if (categoryName === 'WebDevelopment Page') {
      eventName = `wdev_navbar_item_click_${item}`
    } else {
      eventName = 'other_navbar_btn_click'
    }
    trackEvent(categoryName, 'Click', eventName)
  }

  // home_navbar_button_click

  const trackEventNavButton = () => {
    let eventName
    if (categoryName === 'WebDesign Page') {
      eventName = 'wdesign_navbar_btn_click'
    }
    if (categoryName === 'SocialMediaRecruiting Page') {
      eventName = 'smr_navbar_btn_click'
    }
    if (categoryName === 'WebDevelopment Page') {
      eventName = 'wdev_navbar_btn_click'
    } else {
      eventName = 'other_navbar_btn_click'
    }
    trackEvent(categoryName, 'Click', eventName)
  }

  const className = `layout__navbar ${
    secondVarient ? 'bg-white lg:bg-primary lg:h-[119px]' : 'bg-white lg:h-[114px] '
  }  w-full max-w-screen-xl py-4 lg:py-6 block fixed z-10 top-0 `

  return (
    <header role='banner' className='lg:h-[119px] h-[60px] '>
      <nav role='navigation' className={className} style={{ transition: 'top 1s' }}>
        <div
          className={`${
            secondVarient
              ? 'flex items-center justify-between w-full mx-auto'
              : 'lgMax:flex lgMax:items-center lgMax:justify-between lgMax:w-full mt-[1%]'
          }`}
        >
          <div className='flex items-center justify-between'>
            <img
              id='start-image-scroll-to-top'
              src={logo}
              alt='Logo'
              title='Logo'
              className={`cursor-pointer ${
                secondVarient
                  ? 'w-9 h-7 lg:w-[88px] lg:h-[65px]'
                  : 'w-24 h-4 lg:w-[181px] lg:h-[31px]'
              }`}
              onClick={() => {
                navigate('/')
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            />
            {!isOpen && (
              <div className={`hidden lg:block ${secondVarient ? 'ml-9' : ''}`}>
                <div
                  className={`mx-auto flex items-center justify-between w-full text-center ${
                    secondVarient ? 'x:space-x-3 xl:space-x-9 space-x-1' : 'gap-[37px]'
                  }`}
                >
                  {navItems.map((item) => (
                    <a
                      key={item.id}
                      href={getPath(item)}
                      className='hover:bg-gray p-2 text-center block py-2 rounded-md text-base not-italic font-medium text-textPrimary'
                      title={item.messageId}
                      onClick={() => {
                        trackEventNav(item.messageId)
                        scroller.scrollTo(item.href, {
                          duration: 1000,
                          smooth: true,
                        })
                      }}
                    >
                      <Msg
                        id={`${
                          secondVarient
                            ? `${name}.navbar.${item.messageId}`
                            : `company.navbar.${item.messageId}`
                        }`}
                        defaultMessage={item.messageId}
                        description={item.messageId}
                      />
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className='-mr-4 lg:hidden'>
            <button
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              type='button'
              className='flex items-center h-3 w-[23px]'
              aria-controls='mobile-menu'
              aria-expanded='false'
              data-testid='mobile-menu'
            >
              <span className='sr-only'>Open main menu</span>
              {!isOpen ? (
                <img
                  src={burgerMenu}
                  alt='burger menu icon'
                  title='burger menu icon'
                  className='h-3 w-[23px]'
                />
              ) : (
                <img src={cross} alt='cross icon' title='cross icon' className='h-3 w-[23px]' />
              )}
            </button>
          </div>
          {secondVarient && (
            <div className='-mr-2 hidden lg:block'>
              <Button
                onClick={() => {
                  trackEventNavButton()
                  scroller.scrollTo('contactus', {
                    duration: 150,
                    delay: 100,
                    smooth: true,
                  })
                }}
                extraClasses='font-semibold text-blue'
                transparent={true}
                border={true}
                text='consultation'
              />
            </div>
          )}
        </div>
        {isOpen && (
          <div className='block lg:hidden' id='mobile-menu' data-testid='nav-items'>
            <div className='p-3 space-y-1'>
              {navItems.map((item) => (
                <a
                  key={item.id}
                  href={getPath(item)}
                  className='hover:bg-gray hover:font-bold text-center block p-2 text-base border-b border-gray last:border-none text-textPrimary'
                  title={item.messageId}
                  onClick={() => {
                    trackEventNav(item.messageId)
                    scroller.scrollTo(item.href, {
                      duration: 1000,
                      smooth: true,
                    })
                  }}
                >
                  <Msg
                    id={`${
                      secondVarient
                        ? `${name}.navbar.${item.messageId}`
                        : `company.navbar.${item.messageId}`
                    }`}
                    defaultMessage={item.messageId}
                  />
                </a>
              ))}
            </div>
          </div>
        )}
      </nav>
    </header>
  )
}

Navbar.propTypes = {
  navItems: PropTypes.array.isRequired,
  secondVarient: PropTypes.bool.isRequired,
  name: PropTypes.string,
  logo: PropTypes.string.isRequired,
  categoryName: PropTypes.string,
}

export default Navbar
