import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalContactUs from '../ModalContactUs/ModalContactUs'

import rootEmail from '../../../../assets/images/main/mail.svg'
import rootPhone from '../../../../assets/images/main/phone.svg'
import rootInstagram from '../../../../assets/images/main/instagram.svg'
import rootXing from '../../../../assets/images/main/xing.svg'
import rootLinkedIn from '../../../../assets/images/main/linkedin.svg'
import rootEmailWhite from '../../../../assets/images/main/mailWhite.svg'
import rootPhoneWhite from '../../../../assets/images/main/phoneWhite.svg'
import rootInstagramWhite from '../../../../assets/images/main/instagramWhite.svg'
import rootXingWhite from '../../../../assets/images/main/xingWhite.svg'
import rootLinkedInWhite from '../../../../assets/images/main/linkedinWhite.svg'
import { trackEvent } from '../../../../matomo'

const SocialIcons = ({ categoryName, theme, eventName }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <ul className='flex items-center justify-center gap-5'>
        <li>
          <p title='Email'>
            <img
              src={theme == 'dark' ? rootEmailWhite : rootEmail}
              className='h-[21px] w-[28px] cursor-pointer'
              alt='Send Email'
              title='Send Email'
              loading='lazy'
              onClick={() => {
                setShowModal(true)
                trackEvent(
                  categoryName,
                  'Social Media Click',
                  `${eventName}_footer_company_email_navigation`
                )
              }}
            />
          </p>
        </li>
        <li>
          <button
            onClick={() => {
              trackEvent(
                categoryName,
                'Social Media Click',
                `${eventName}_footer_company_phone_navigation`
              )
              window.open('tel: +4915123246715', '_blank', 'noopener', 'noreferrer')
            }}
            title='Phone'
          >
            <img
              src={theme == 'dark' ? rootPhoneWhite : rootPhone}
              className='h-[21px] w-[21px] cursor-pointer fill-white'
              alt='Phone'
              title='Phone'
              loading='lazy'
            />
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              trackEvent(
                categoryName,
                'Social Media Click',
                `${eventName}_footer_company_linkedin_navigation`
              )
              window.open(
                'https://www.linkedin.com/company/nicolas-welitzki-it-consulting/',
                '_blank',
                'noopener',
                'noreferrer'
              )
            }}
            title='LinkedIn'
          >
            <img
              src={theme == 'dark' ? rootLinkedInWhite : rootLinkedIn}
              className='h-[21px] w-[21px] cursor-pointer'
              alt='LinkedIn'
              title='LinkedIn'
              loading='lazy'
            />
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              trackEvent(
                categoryName,
                'Social Media Click',
                `${eventName}_footer_company_xing_navigation`
              )
              window.open(
                'https://www.xing.com/profile/Nicolas_Welitzki/',
                '_blank',
                'noopener',
                'noreferrer'
              )
            }}
            title='Xing'
          >
            <img
              src={theme == 'dark' ? rootXingWhite : rootXing}
              className='h-[21px] w-[21px] cursor-pointer'
              alt='Xing'
              title='Xing'
              loading='lazy'
            />
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              trackEvent(
                categoryName,
                'Social Media Click',
                `${eventName}_footer_company_instagram_navigation`
              )
              window.open(
                'https://www.instagram.com/nicolaswelitzkiitconsulting/',
                '_blank',
                'noopener',
                'noreferrer'
              )
            }}
            title='Instagram'
          >
            <img
              src={theme == 'dark' ? rootInstagramWhite : rootInstagram}
              className='h-[21px] w-[21px] cursor-pointer'
              alt='Instagram'
              title='Instagram'
              loading='lazy'
            />
          </button>
        </li>
      </ul>
      <ModalContactUs showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}

SocialIcons.propTypes = {
  categoryName: PropTypes.string,
  theme: PropTypes.string,
  eventName: PropTypes.string,
}

export default SocialIcons
