import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Msg } from 'react-intl'
import HowWeWorkCard from '../../../common/display/HowWeWorkCard/HowWeWorkCard'

import makeanappointment from '../../../../assets/images/makeanappointment.svg'
import preliminarytalk from '../../../../assets/images/preliminarytalk.svg'
import consultation from '../../../../assets/images/consultation.svg'
import future from '../../../../assets/images/future.svg'

const HowWeWork = ({ bgColor, name, categoryName }) => {
  const data = [
    {
      id: 1,
      card: 'cardOne',
      button: true,
      image: makeanappointment,
      extraClass: 'w-[138px] h-[118px] md:w-[210px] md:h-[179.47px]',
    },
    {
      id: 2,
      card: 'cardTwo',
      button: false,
      image: preliminarytalk,
      extraClass: 'w-[120px] h-[121px] md:w-[150.3px] md:h-[151.51px]',
    },
    {
      id: 3,
      card: 'cardThree',
      button: false,
      image: consultation,
      extraClass: 'w-[131.99px] h-[118.25px] md:w-[178.87px] md:h-[160.25px]',
    },
    {
      id: 4,
      card: 'cardFour',
      button: false,
      image: future,
      extraClass: 'w-[142.57px] h-[119px] md:w-[180.35px] md:h-[150.53px]',
    },
  ]

  return (
    <div
      id='ablauf'
      className={bgColor ? 'section-without-padding ' + bgColor : 'section-without-padding'}
    >
      <h2>
        <Msg id='socialMediaRecruiting.howwework.title' />
      </h2>
      <div className='grid grid-cols-1 x:grid-cols-2 gap-[42px] xl:gap-[82px] mx-auto px-[15px] ti:px-[34px] xl:px-[130px]'>
        {data.map((item) => (
          <HowWeWorkCard
            key={item.id}
            number={item.id}
            card={item.card}
            image={item.image}
            button={item.button}
            extraClass={item.extraClass}
            name={name}
            categoryName={categoryName}
          />
        ))}
      </div>
    </div>
  )
}

HowWeWork.propTypes = {
  bgColor: PropTypes.string,
  name: PropTypes.string,
  categoryName: PropTypes.string,
}

export default HowWeWork
