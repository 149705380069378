import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'

const PrivacyData = () => {
  return (
    <div>
      <div>
        <h2 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue'>
          <Msg id={'socialMediaRecruiting.privacy.one.mainHeading'} />
        </h2>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.heading1'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para1'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.heading2'} />
        </h3>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.subHeading1'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para2'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.subHeading2'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para3'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para4'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.subHeading3'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para5'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.subHeading4'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para6'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para7'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.heading3'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para8'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.one.para9'} />
        </p>
      </div>

      <div>
        <h2 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[20px]'>
          <Msg id={'socialMediaRecruiting.privacy.two.mainHeading'} />
        </h2>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.two.para1'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.two.heading1'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.two.para2'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.two.para3'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.two.para4'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.two.para5'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <span>
            <Msg id={'socialMediaRecruiting.privacy.two.line1'} />
          </span>
          <span>
            <Msg id={'socialMediaRecruiting.privacy.two.line2'} />
          </span>
          <span>
            <Msg id={'socialMediaRecruiting.privacy.two.line3'} />
          </span>
          <span>
            <Msg id={'socialMediaRecruiting.privacy.two.line4'} />
          </span>
        </p>
      </div>

      <div>
        <h2 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[20px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.mainHeading'} />
        </h2>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading1'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para1'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para2'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para3'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading2'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para4'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <span>Nicolas Welitzki</span>
          <span>Poschingerstr. 30</span>
          <span>12157 Berlin</span>
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <span>
            <Msg
              id='socialMediaRecruiting.imprint.content1'
              defaultMessage='Phone: +49 151 23246715'
            />
          </span>
          <span>E-Mail: office@welitzki-it.de</span>
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para5'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading3'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para6'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading4'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para7'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading5'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para8'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading6'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para9'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading7'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para10'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para11'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading8'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para12'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading9'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para13'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading10'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para14'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading11'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para15'} />
        </p>
        <ul className='list-disc flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px] pl-[50px]'>
          <li>
            <Msg id={'socialMediaRecruiting.privacy.three.line1'} />
          </li>
          <li>
            <Msg id={'socialMediaRecruiting.privacy.three.line2'} />
          </li>
          <li>
            <Msg id={'socialMediaRecruiting.privacy.three.line3'} />
          </li>
          <li>
            <Msg id={'socialMediaRecruiting.privacy.three.line4'} />
          </li>
        </ul>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para16'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.heading12'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para17'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.three.para18'} />
        </p>
      </div>

      <div>
        <h2 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[20px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.mainHeading'} />
        </h2>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.heading1'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para1'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para2'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para3'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para4'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para5'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para6'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.heading2'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para7'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para8'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para9'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.heading3'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para10'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para11'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para12'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.heading4'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para13'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para14'} />
        </p>
        <a
          href='https://calendly.com/de/privacy'
          target='_blank'
          className='pl-[20px] text-blue visited:text-purple-600 underline'
          rel='noreferrer'
        >
          https://calendly.com/de/privacy
        </a>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para15'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para16'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para17'} />
        </p>
        <a
          href='https://calendly.com/dpa'
          target='_blank'
          className='pl-[20px] text-blue visited:text-purple-600 underline'
          rel='noreferrer'
        >
          https://calendly.com/dpa
        </a>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.heading5'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para18'} />
        </p>
        <a
          href='https://www.provenexpert.com'
          target='_blank'
          className='pl-[20px] text-blue visited:text-purple-600 underline'
          rel='noreferrer'
        >
          https://www.provenexpert.com
        </a>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para19'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.four.para20'} />
        </p>
      </div>

      <div>
        <h2 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[20px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.mainHeading'} />
        </h2>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.heading1'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.para1'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.para2'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.para3'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.subHeading1'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.para4'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.subHeading2'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.five.para5'} />
        </p>
      </div>

      <div>
        <h2 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[20px]'>
          <Msg id={'socialMediaRecruiting.privacy.six.mainHeading'} />
        </h2>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.six.heading1'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.six.para1'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.six.para2'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.six.para3'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.six.para4'} />
        </p>
        <p className='pl-[20px] w-auto leading-[28px] text-[16px] text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.six.para5'} />
          <b className='font-semibold text-darkGray'>
            <Msg id={'socialMediaRecruiting.privacy.six.line1'} />
          </b>
        </p>
      </div>

      <div>
        <h2 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[20px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.mainHeading'} />
        </h2>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.subHeading1'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para1'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para2'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para3'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para4'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.subHeading2'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para5'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.subHeading3'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para6'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para7'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.subHeading4'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para8'} />
        </p>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.heading1'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para9'} />
        </p>
        <a
          href='https://zoom.us/en-us/privacy.html'
          target='_blank'
          className='pl-[20px] text-blue visited:text-purple-600 underline'
          rel='noreferrer'
        >
          https://zoom.us/en-us/privacy.html
        </a>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.seven.para10'} />
        </p>
        <a
          href='https://zoom.us/de-de/privacy.html'
          target='_blank'
          className='pl-[20px] text-blue visited:text-purple-600 underline'
          rel='noreferrer'
        >
          https://zoom.us/de-de/privacy.html
        </a>
      </div>

      <div>
        <h2 className='w-auto leading-[30px] text-[18px] ti:text-[20px] font-semibold text-blue pt-[20px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.mainHeading'} />
        </h2>
        <h3 className='w-auto leading-[30px] text-[16px] ti:text-[18px] font-semibold text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.heading1'} />
        </h3>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.para1'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.subHeading1'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.para2'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.para3'} />
        </p>
        <h4 className='w-auto leading-[30px] text-[16px] font-semibold text-darkGray pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.subHeading2'} />
        </h4>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.para4'} />
        </p>
        <p className='pl-[20px] flex flex-col items-start w-auto leading-[28px] text-[16px] font-normal text-black pt-[18px] ti:pt-[14px] pb-[30px] ti-pb-[80px]'>
          <Msg id={'socialMediaRecruiting.privacy.eight.para5'} />
        </p>
      </div>
    </div>
  )
}

export default PrivacyData
