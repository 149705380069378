import React from 'react'
import { FormattedMessage as Msg } from 'react-intl'
import WDServiceCard from '../../common/display/WDServiceCard/WDServiceCard'
import Fehlersuche from '../../../assets/images/webDevelopment/Fehlersuche.svg'
import SoftwareMigration from '../../../assets/images/webDevelopment/SoftwareMigration.svg'
import Webanwendungen from '../../../assets/images/webDevelopment/Webanwendungen.svg'
import DesktopSoftware from '../../../assets/images/webDevelopment/DesktopSoftware.svg'

const Services = () => {
  const servicesData = [
    {
      id: 1,
      card: 'firstCard',
      image: Webanwendungen,
    },
    {
      id: 2,
      card: 'secondCard',
      image: SoftwareMigration,
    },
    {
      id: 3,
      card: 'thirdCard',
      image: DesktopSoftware,
    },
    {
      id: 4,
      card: 'fourthCard',
      image: Fehlersuche,
    },
  ]
  return (
    <div className='section-without-padding w-auto x:w-[1190px] mx-[35px] ti:mx-10 sm:mx-16 x:mx-auto  mb-10'>
      <h2>
        <Msg id='webDevelopment.services.heading' />
      </h2>
      <div className='grid grid-cols-1 x:grid-cols-2 gap-[47px]'>
        {servicesData.map((item) => (
          <WDServiceCard key={item.id} id={item.id} card={item.card} image={item.image} />
        ))}
      </div>
    </div>
  )
}

export default Services
