import flatten from 'flat'
import { deDE, enUS } from './locales'
import { messages as deDE_messages } from './messages/de_DE'
import { messages as enUS_messages } from './messages/en_US'

const messages = {
  [enUS]: enUS_messages,
  [deDE]: deDE_messages,
}

export const getMessages = (locale) => flatten(messages[locale])

export const getLocale = () => (navigator.language.match(/^de/) ? deDE : enUS)
